// src/firebase.js

import { getDatabase } from "firebase/database";
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import {firebase} from 'firebase/app';
const firebaseConfig = {
    apiKey: "AIzaSyCm_9CYw9aVeWfsjnwd4V13jaZIUF2yUf8",
    authDomain: "justscroll-b7f87.firebaseapp.com",
    projectId: "justscroll-b7f87",
    storageBucket: "justscroll-b7f87.firebasestorage.app",
    messagingSenderId: "313524713191",
    appId: "1:313524713191:web:40400e69a650b16e019442",
    measurementId: "G-L0SL6NDLQL"
  };
  
// Initialize Firebase
const firebaseApp = initializeApp({firebaseConfig,
    projectId:'justscroll-b7f87',
    apiKey:'AIzaSyDgNOg3K_3sDJ86ROcJeODyYRdwk31pJNU',
    databaseURL: 'https://justscroll-b7f87-default-rtdb.asia-southeast1.firebasedatabase.app/',
    appId: "1:313524713191:web:40400e69a650b16e019442",
    messagingSenderId: "313524713191",
});
// Initialize Firestore
const token = localStorage.getItem('googleToken');
const database = getDatabase(firebaseApp);
const messaging = getMessaging(firebaseApp);
const auth = getAuth(firebaseApp);
 await signInWithCustomToken(auth, token)
  .then((userCredential) => {
    console.log("User signed in:", userCredential.user);
  })
  .catch((error) => {
    console.error("Error signing in with custom token:", error);
  });
const firestore = getFirestore(firebaseApp);
export { database,auth ,firestore,messaging};
