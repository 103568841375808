

export const setPostComment=(comment)=>(dispatch)=>{
    return dispatch({
        comment:comment,
        type:"SETCOMMENTPOST"
       })
}

export const updatePostComment=(comment,username,profilePic)=>(dispatch)=>{

    return dispatch({
      userdata:{
                comment:comment,
                username:username,
                profilePic:profilePic
        },
        type:"UPDATECOMMENTPOST"
       })
}
export const emptyPostComment=()=>(dispatch)=>{

    return dispatch({
        type:"EMPTYCOMMENTPOST"
       })
}