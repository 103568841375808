import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import Header from './Header'
import BottomTab from './BottomTab'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './Spinner'
import { completed, joinevent } from '../Redux/actions/Join'
import { fetchUrl } from '../Contsant'
import { joineeventupdate } from '../Redux/actions/Event'
import '../css/vote.css';
import LazyImage from './LazyImage'
import IMGLOGO from'../image/logo-trans.png'

const Participate = () => {
 
  const location=useLocation()
   const [hasJoined,sethasJoined]=useState(false)
   const [hasData,sethasdata]=useState(false)
   const [file,setFile]=useState(null)
   const isjoining=useSelector(state=>state.Join)
   const accessToken=useSelector(state=>state.Auth)
  const [eventIdAll,setEventId]=useState("")
   const hiddenFile = useRef(null);
  const dispatch=useDispatch()
  const now=new Date().getTime();
  const [eventStatus,setEventStatus]=useState("WINNER")
  const {eventId}=useParams()
  const userData=useSelector(state=>state.UserData)
 const [eventData,setEventdata]=useState(null)
 const [totalEntry,setTotalEntry]=useState(0)
 const [participentnum,setparticipentnum]=useState(0)
 const [hrs,setHrs]=useState(0)
 const [advertiserdata,setAdvertiserData]=useState([])
 const[advertiserrprofile,setAdvertiserProfile]=useState(null)
  
  useEffect(()=>{
    
     if(location.state==null)
      {
        const payload={eventId}
        fetch(`${fetchUrl}webfn/getEventDataById`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
        'authorization': accessToken.accessToken
    
          },
          body: JSON.stringify(payload),
          })
          .then(async(res)=>{
            const jsonRes = await res.json();
            const eventData=JSON.parse(jsonRes.data)[0]
           
            if(eventData.startdate>now)
              {
                setEventStatus("PARTICIPATE") 
              }
            else if(eventData.startdate<now && now<eventData.enddate)
              {
                setEventStatus( "VOTE")
              }
              else
              {
                setEventStatus( "WINNER")
              }
            if(eventData.hasJoined.length)
                {
                 sethasJoined(true)
                }
                setTotalEntry(eventData.totalEntry)
                setparticipentnum(eventData.participentnum)
                setAdvertiserProfile(eventData.sponsorData[0])
                setAdvertiserData(eventData.advertiserDetails)
                sethasdata(true)
          }).catch(err=>[

          ])
      }
      else
      {
       
        if(location.state.startdate>now)
          {
            let remainingTime=(location.state.startdate-now)/1000
            setHrs(Math.floor(remainingTime/3600) )
            setEventStatus("PARTICIPATE") 
          }
        else if(location.state.startdate<now && now<location.enddate)
          {
            setEventStatus( "VOTE")
          }
          else
          {
            setEventStatus( "WINNER")
          }
          if(location.state.hasJoined.length)
            {
             
             sethasJoined(true)
             setEventdata(location.state.hasJoined[0])
             
            
            }
            //console.log(location.state.totalEntry>=location.state.participentnum)
            setTotalEntry(location.state.totalEntry)
            setparticipentnum(location.state.participentnum)
            setAdvertiserData(location.state.advertiserDetails)
            setAdvertiserProfile(location.state.sponsorData[0])
          setEventId(location.state._id)
          sethasdata(true)
      }
  },[])

  const handleChange=(event)=>{
  
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
    
     setFile(img)
    }
  }
  const submitevent=async()=>{
    dispatch(joinevent())
    const formData=new FormData()
  
    formData.append("file",file,`${now}${userData.data[0].username}`)
    formData.append('eventId',eventId)
    fetch(`${fetchUrl}joinEvent/joinEvent`, {
      method: 'POST',
      headers: {
        Accept:'application/json',
        'authorization': accessToken.accessToken
      },
      body:formData,
      
      })
      .then(async res=>{
        const jsonRes = await res.json();
        dispatch(completed())
        if(jsonRes.statusCode==211)
        {
          const parsedData=JSON.parse(jsonRes.data)
          dispatch(joineeventupdate(eventId,parsedData))
          setEventdata(parsedData)
          sethasJoined(true)
        }
       
       

      })
      .catch(err=>{
        dispatch(completed())
       
      })
  }
 
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', zIndex: 100, backgroundColor: '#f7f7f7' }}>
    <Header />
    {hasData ? (
      advertiserdata.length ? (
        <div  style={{ display: 'flex',padding:20}}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginTop: '50px',marginBottom:60 }}>
          
          {/* Advertisement Section */}
          <a href={advertiserdata[0].actionLink} target='_blank' className="card" style={{
            marginBottom: '30px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            overflow: 'hidden',
            textDecoration: 'none',
            width: '100%',
            maxWidth: '500px',
          }}>
            <LazyImage
              src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/advertisement/${advertiserdata[0].banner}`}
              aspectRatio='16/9'
              style={{ width: '100%', borderRadius: '10px' }}
            />
          </a>
  
          {/* Advertiser Profile */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.05)',
            marginBottom: '20px',
            width: '100%',
            maxWidth: '500px',
          }}>
            <img
              style={{ width: '36px', height: '36px', borderRadius: '50%' }}
              src={advertiserrprofile.profilePic ? `https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${advertiserrprofile.profilePic}` : IMGLOGO}
            />
            <div style={{ marginLeft: '10px' }}>
              <span style={{ fontSize: '14px', fontWeight: 600 }}>Sponsored</span>
              <span style={{ fontSize: '14px', color: 'grey', marginLeft: '5px' }}>{advertiserrprofile.username}</span>
              <p style={{ fontSize: '14px', marginBottom: '5px' }}>{advertiserdata[0].title}</p>
              <p style={{ fontSize: '12px', color: 'grey' }}>{advertiserdata[0].discription}</p>
            </div>
          </div>
  
          {/* Event Status */}
          {eventStatus === "WINNER" ? (
            <NavLink to={`/vote/${eventId}`} style={styles.navLink}>
              <p>Winners For This Event Have Already Been Declared!</p>
              <button style={styles.buttonGreen}>View Winner</button>
            </NavLink>
          ) : eventStatus === "VOTE" ? (
            <div style={styles.centeredContainer}>
              <p>Can't Join. Voting Is Live Now.</p>
              <button style={styles.buttonVote}>
                <NavLink to={`/vote/${eventId}`} style={styles.navLinkText}>Vote Now</NavLink>
              </button>
            </div>
          ) : hasJoined ? (
            <div style={styles.centeredContainer}>
              <img style={styles.eventImage} src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${eventData.fileUrl}`} />
              <h3>You have already joined this event!</h3>
              <p>Voting Starts In: {hrs} Hour</p>
            </div>
          ) : totalEntry >= participentnum ? (
            <div style={styles.centeredContainer}>
              <h3>Maximum Entry For This Event Has Been Reached.</h3>
            </div>
          ) : isjoining.isJoining ? (
            <div style={styles.centeredContainer}>
              <Spinner width={60} />
              <p style={styles.spinnerText}>We are adding content to the event. Please wait...</p>
              <h3>Don't close the browser.</h3>
            </div>
          ) : (
            <div style={styles.centeredContainer}>
              <p className="form-title" style={styles.formTitle}>Submit Your Entry?</p>
              <p style={styles.helperText}>Make sure your entry is 1080px * 1080px</p>
              {eventId === "669011972ee30d2c021397d3" && <p style={styles.helperText}>This is a dummy event and won't go to voting.</p>}
              {file && (
                <div style={styles.previewContainer}>
                  <img src={URL.createObjectURL(file)} style={styles.previewImage} />
                  <button style={styles.submitButton} onClick={submitevent}>Submit</button>
                </div>
              )}
              <button style={styles.selectButton} onClick={() => hiddenFile.current.click()}>
                {!file ? "Select Entry" : "Change Entry"}
              </button>
              <input type="file" id="tb-file-upload" accept="image/*" ref={hiddenFile} style={{ display: 'none' }} onChange={handleChange} />
              <span style={styles.termsText}>By submitting your entry to Justscroll, you agree to the following:</span>
              <ul style={styles.termsList}>
                <li>You are an Indian citizen.</li>
                <li>The content belongs to you, and you have all the rights for its use.</li>
                <li>You provide Justscroll the right to use your content for marketing purposes.</li>
                <li>Justscroll holds the right to remove your content during any phase if it doesn't fit the competition.</li>
              </ul>
            </div>
          )}
        </div>
        </div>
      ) : <Spinner width={80} />
    ) : <Spinner width={80} />}
    <BottomTab />
  </div>
 
  )
}
const styles = {
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '60px',
    width: '100%',
    maxWidth: '500px',
  },
  buttonGreen: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    maxWidth:'200px',
    width: '50%',
    height: '30px',
    padding: '15px',
    backgroundColor: 'green',
    border: 'none',
    color: 'white',
    borderRadius: '5px',
  },
  buttonVote: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    maxWidth:'200px',
    width: '50%',
    height: '30px',
    padding: '15px',
    backgroundColor: '#66003a',
    border: 'none',
    color: 'white',
    borderRadius: '5px',
  },
  eventImage: {
    width: '90%',
    aspectRatio: 1,
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  formTitle: {
    color: '#333333',
    fontFamily: 'Josefin Sans',
    fontSize: '30px',
    fontWeight: 'bold',
    marginBottom: '20px',
    padding: '10px',
  },
  helperText: {
    padding: '10px',
    fontSize: '14px',
  },
  previewContainer: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  previewImage: {
    marginBottom: '10px',
    maxWidth: '400px',
    width: '100%',
    aspectRatio: 1,
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  submitButton: {
    width: '50%',
    height: '40px',
    padding: '10px',
    backgroundColor: '#8bdef5',
    border: 'none',
    color: 'black',
    borderRadius: '5px',
    outline:'none',
    marginBottom:20,

  },
  selectButton: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width: '50%',
    height: '40px',
    padding: '10px 20px',
    backgroundColor: '#fe4040',
    color: 'white',
    borderRadius: '5px',
    border:'none',
    outline:'none'
  },
  termsText: {
    marginTop: '20px',
    textAlign: 'left',
    fontWeight: 600,
    padding: '10px',
  },
  termsList: {
    padding: '5px',
    textAlign: 'left',
  },
  spinnerText: {
    padding: '10px',
  },
  navLink: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navLinkText: {
    textDecoration: 'none',
    color: 'white',
  }
};
export default Participate