import { faClipboardUser, faFutbol, faHome, faStore, faUser, faUserCheck, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeForward } from '../Redux/actions/Forward';

function BottomTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notification=useSelector(state=>state.Ordercount)
  
  // Assuming you have storeNotificationCount from your Redux store or as a prop.
  const storeNotificationCount = 5; // Replace this with the actual count from your store

  return (
    <div style={{
      display: 'flex',
      alignSelf: 'center',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      maxWidth: '500px',
      height: 40,
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: "10px",
      backgroundColor: 'white',
      zIndex: 1100
    }}>
      
      <NavLink style={{
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
        color: 'black'
      }} to={'/'}>
        <FontAwesomeIcon icon={faHome} style={{ fontSize: 14, color: 'black' }} />
        <span style={{ fontWeight: 500 }}>Home</span>
      </NavLink>
      
      <NavLink style={{
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
        color: 'black'
      }} to={'/event'}>
        <FontAwesomeIcon icon={faUsers} style={{ fontSize: 14, color: 'black' }} />
        <span style={{ fontWeight: 500 }}>Event</span>
      </NavLink>
      
      <NavLink style={{
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
        color: 'black',
        position: 'relative' // Make this relative to position the badge correctly
      }} to={'/store'}>
        <FontAwesomeIcon icon={faStore} style={{ fontSize: 14, color: 'black' }} />
        <span style={{ fontWeight: 500 }}>Store</span>

        {notification.notificationCount > 0 && (
          <span style={{
            position: 'absolute',
            top:5,
            right:0,
            backgroundColor: 'red',
            color: 'white',
            borderRadius: '50%',
            padding: '2px 6px',
            fontSize: '10px',
            fontWeight: 'bold'
          }}>
            {notification.notificationCount}
          </span>
        )}
      </NavLink>
      
      <div style={{
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
        color: 'black'
      }} onClick={() => {
        dispatch(removeForward());
        navigate('/profile');
      }}>
        <FontAwesomeIcon icon={faUser} style={{ fontSize: 14, color: 'black' }} />
        <span style={{ fontWeight: 500 }}>Profile</span>
      </div>
      
    </div>
  );
}

export default BottomTab;
