import React, { useEffect, useState } from 'react'
import Header from './Header'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { fetchUrl } from '../Contsant'
import { useDispatch } from 'react-redux'
import { setUserData } from '../Redux/actions/UserData'
import { userLogin } from '../Redux/actions/Auth'
import { faCheck, faCheckSquare, faL } from '@fortawesome/free-solid-svg-icons'
import Spinner from './Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const VerifyCode = () => {
  const [email,setEmail]=useState("")
    const location=useLocation()
    const [username,setusername]=useState(null)
    const navigate=useNavigate()
   const dispatch=useDispatch()
   const [isClickable,setIsClickable]=useState(true)
   const [haserror,setHasError]=useState({error:false,errormsg:''})

  useEffect(()=>{
    if(location.state==null)
     {
       navigate("/profile")
     }
     else
     {
       
       setusername(location.state?.username)
       setEmail(location.state?.email)

     }
 },[])
  const [code,setCode]=useState("")
  const verifycode=()=>{
    setHasError({error:false,errormsg:''})

    if(code==""){
      return;
    }
    setIsClickable(false)
    const payload = {
      email,
      username,
      code
      
  };
  fetch(`${fetchUrl}webfn/verifyCode`, {
   method: 'POST',
   headers: {
       'Content-Type': 'application/json',
   },
   body: JSON.stringify(payload),
})
.then(async res => { 

    
     const jsonRes = await res.json();
     if(jsonRes.statusCode==211)
      {
        dispatch(setUserData([jsonRes.doesExist]))
        dispatch(userLogin(jsonRes.accessToken))

      }
      else if(jsonRes.statusCode==400)
        {
          setIsClickable(true)
          setHasError({error:true,errormsg:'Code did not match try again!'})
        }
        else
        {
          setIsClickable(true)
        setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
        }
}
     )
     .catch((err)=>{
      setIsClickable(true)
        setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
       
    })
  }

  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
 <div style={{display:'flex',maxWidth:'500px',alignSelf:'center',flexDirection:'column',padding:10,marginTop:60}}>
    <span style={{color:'green',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <FontAwesomeIcon icon={faCheckSquare}  fontSize={30}/>Your Account Was Sucessfully created!</span>
     
      <p>Enter 6 Digit Code Sent To Email For Verification!</p>
      {haserror?.error?<p style={{display:'flex',alignSelf:'center',
        color:'white',padding:10,fontSize:14,backgroundColor:'#6e0c71',width:'80%',justifyContent:'center'}}>{haserror.errormsg}</p>:""}
      <div style={{display:'flex',marginBottom:10,border:'1px solid #333333',borderRadius:12,padding:8}}>
      <input type='number' placeholder='Enter code ' style={{
            width: '100%', border: 'none', outline: 'none', color: '#333', 
            padding: 12, fontSize: 16, height: 20 // Adjusted height
          }}
         onChange={(e)=>{setCode(e.target.value)}} maxLength={6}/>
        </div>
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <button onClick={()=>{verifycode()}}
           style={{
            padding: 12, backgroundColor: '#4db6ac', color: 'white', borderRadius: 10,
            border: 'none', width: '50%', margin: '20px auto', cursor: 'pointer',
            maxWidth: 200, textAlign: 'center', fontSize: 16
          }}disabled={!isClickable}>
            {isClickable?'Verify Code':<Spinner />}
</button>
         
       </div>
      </div>
      </div>
  )
}

export default VerifyCode