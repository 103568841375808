import React,{useEffect, useRef, useState}from 'react'

import { NavLink } from 'react-router-dom';
import BottomTab from './BottomTab'
import Header from './Header'
import { useSelector } from 'react-redux'
import Spinner from './Spinner'
import IMG from'../image/justscrollmain.jpg'
import IMG1 from'../image/events.jpg'
import IMG2 from'../image/digicoin.jpg'
import IMG3 from'../image/creator_1.png'
import IMG5 from'../image/superchat.jpg'
import IMG4 from'../image/commerce.jpg'
import IMG6 from'../image/playbadge.png'
import '../css/parllax.css';
import VideoFeed from './VideoFeed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';








function Home() {
  const userData=useSelector(state=>state.UserData)
  const [animationText,setAnimationText]=useState(["CREATE","SHARE","EARN"])
  const [currentText,setCurrentext]=useState("")
  const [currentIndex,setCurrentIndex]=useState(0)
  const accessToken=useSelector(state=>state.Auth)
  const timerRef = useRef(null);
  useEffect(()=>{
    setCurrentext(animationText[currentIndex])
  timerRef.current= setTimeout(()=>{
        setCurrentIndex(currentIndex+1)
       setCurrentext(animationText[currentIndex])
       if(currentIndex==2)
       {
        setCurrentIndex(0)
        setCurrentext(animationText[currentIndex])
       }
       
    },1500) 
    return () => { 
        if (timerRef.current) {
           
            clearTimeout(timerRef.current);
          }
    };  
  },[currentIndex])
  const imgRefs = useRef([]);

  const handleScroll = () => {
    imgRefs.current.forEach((img) => {
      if (img) {
        const rect = img.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          img.classList.add('image-visible');
        } else {
          img.classList.remove('image-visible'); // Remove class if out of view
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on mount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      
    
      <div style={{display:'flex',flex:1,flexDirection:'column',}}>
        <Header />

      <div style={{ fontFamily: 'Poppins', overflow: 'hidden',marginBottom:70 }}>
      <section style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20, maxWidth: '500px', padding: '20px',
        textAlign: 'center', backgroundColor: '#f9f9f9', borderRadius: '12px',margin:'50px auto',
      }}>
        <h1 style={{
          fontFamily: 'Quicksand', fontSize: '36px', lineHeight: '1.2', color: '#66003a', marginBottom: '10px'
        }}>
          Redesigning<br /> Internet For <br /> Content Creators
        </h1>
        <p style={{
          fontSize: '18px', fontWeight: '400', color: '#555', marginBottom: '20px',fontFamily:'Nunito'
        }}>
          We build products that help creators easily monetize their content & community.
        </p>

        <img ref={(el) => (imgRefs.current[0] = el)} className="image-fade-in" src={IMG} alt="Content Creators" style={{ width: '100%',
           marginTop: 10, borderRadius: '12px' }} />

        <div style={{
          marginTop: 40, backgroundColor: '#00897b', color: '#fff', borderRadius: '8px', padding: '10px',
          fontSize: '18px', fontWeight: '600',minWidth:180
        }}>
          {currentText}
        </div>

        <img ref={(el) => (imgRefs.current[1] = el)} className="image-fade-in" src={IMG1} alt="Event Image" style={{ width: '100%', 
          marginTop: 40 }} />
        <NavLink to="/eventdetail" style={{
          color: '#66003a', fontWeight: '600', marginTop: '10px'
        }}>
          Exclusive, influencer-hosted competitions where creators showcase their creativity.
        </NavLink>

        <img ref={(el) => (imgRefs.current[2] = el)} className="image-fade-in" src={IMG2} alt="Digicoins" style={{ width: '100%', marginTop: 40 }} />
        <p style={{ color: '#333', marginTop: 10 }}>
          Collect Free Digicoins by adding your content and redeem them for Rs.100 for every 3000 coins collected.
        </p>

        <div style={{
          width: '100%', padding: '20px', backgroundColor: '#fff', borderRadius: '12px', marginTop: 30, textAlign: 'center',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        }}>
          <h3 style={{ color: '#66003a', fontWeight: '600' }}>Want to Earn?</h3>
          <img ref={(el) => (imgRefs.current[3] = el)} className="image-fade-in" src={IMG3} alt="Verified Badge" style={{ width: '100%', marginTop: 10 }} />
          <p style={{ marginTop: 10 }}>Get your free verified creator badge today!</p>
          <button style={{
            backgroundColor: '#fe4040', color: '#fff', border: 'none', padding: '10px 20px',
            borderRadius: '8px', marginTop: '15px', cursor: 'pointer'
          }}>
            <NavLink to="/verification" style={{ textDecoration: 'none', color: 'white' }}>Learn More</NavLink>
          </button>
        </div>

        <img ref={(el) => (imgRefs.current[4] = el)} className="image-fade-in" src={IMG4} alt="E-store" style={{ width: '100%', marginTop: 40 }} />
        <p style={{ color: '#333', marginTop: 10 }}>
          Create your e-store with Justscroll and start selling your own or affiliate products from brands.
        </p>

        <img ref={(el) => (imgRefs.current[5] = el)} className="image-fade-in" src={IMG5} alt="Messaging" style={{ width: '100%', marginTop: 40 }} />
        <p style={{ color: '#333', marginTop: 10 }}>
          Only users you follow can send you messages. Others need to send superchats through your content.
        </p>
      </section>

      
        <h2 style={{
          fontSize: '28px', marginBottom: '10px'
        }}>
          Download Justscroll now and start earning!
        </h2>
        <a href='https://play.google.com/store/apps/details?id=com.justscroll'>
          <img src={IMG6} alt="Download App" style={{ width: '200px' }} />
        </a>
    
    </div>
    </div>
    <BottomTab />
    </div>
  )
}

export default Home