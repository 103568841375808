import React, { useState } from 'react';
import Spinner from './Spinner'
import { fetchUrl } from '../Contsant';
import { useSelector } from 'react-redux';

const BookProductForm = ({ onSubmit }) => {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [genre, setGenre] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const accessToken=useSelector(state=>state.Auth)
  // Handle image selection
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages((prevImages) => [...prevImages, ...files]);
  };
  const [isAddingProduct,setIsAddingProduct]=useState(false)
  // Remove selected image
  const handleRemoveImage = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsAddingProduct(true)
    const formData = new FormData();
    formData.append('title', title);
    formData.append('author', author);
    formData.append('genre', genre);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('discount', discount);

    selectedImages.forEach((image) => {
      formData.append('images', image);
    });
    fetch(`${fetchUrl}webfn/addProduct`, {
      method: 'POST',
      headers: {
        Accept:'application/json',
        'authorization': accessToken.accessToken
      },
      body:formData,
      
      }).then(async res=>{
        setIsAddingProduct(false)
        console.log(res)
      }).catch(err=>{
        setIsAddingProduct(false)
console.log(err)
      })

  
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Add New Book</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data" style={styles.form}>
        <label style={styles.label}>Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          style={styles.input}
        />

        <label style={styles.label}>Author:</label>
        <input
          type="text"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          required
          style={styles.input}
        />

        <label style={styles.label}>Genre:</label>
        <input
          type="text"
          value={genre}
          onChange={(e) => setGenre(e.target.value)}
          style={styles.input}
        />

        <label style={styles.label}>Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={styles.textarea}
          rows="4"
          required
        />

        {/* Price Input */}
        <label style={styles.label}>Price:</label>
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          style={styles.input}
          required
        />

        {/* Discount Input */}
        <label style={styles.label}>Discount (%):</label>
        <input
          type="number"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          style={styles.input}
        />

        {/* Image Upload Section */}
        <label style={{ display: 'flex', alignSelf: 'center' }}>Upload Images:</label>
        <label htmlFor="imageUpload" style={styles.uploadLabel}>
          <input
            id="imageUpload"
            type="file"
            accept="image/*"
            multiple
            style={styles.hiddenInput}
            onChange={handleImageChange}
          />
          <div style={styles.plusButton}>+</div>
        </label>

        {/* Display selected images */}
        <div style={{ display: 'flex', alignSelf: 'center' }}>
          {selectedImages.map((image, index) => (
            <div key={index} style={styles.imagePreviewWrapper}>
              <img
                src={URL.createObjectURL(image)}
                alt={`preview-${index}`}
                style={styles.imagePreview}
              />
              <p
                onClick={() => handleRemoveImage(index)}
                style={styles.removeButton}
              >
                &#10005;
              </p>
            </div>
          ))}
        </div>

        <button type="submit" style={styles.button} disabled={isAddingProduct}>{isAddingProduct?<Spinner />:'Add Book'}</button>
      </form>
    </div>
  );
};



const styles = {
  comboSection: {
    marginTop: '20px',
    backgroundColor: '#f9f9f9',
    padding: '10px',
    borderRadius: '8px',
  },
  container: {
    display:'flex',
    alignSlef:'center',
    flexDirection:'column',
    margin:'auto',
    width:'90%',
    padding:10,
    marginTop:60,

  
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    fontSize: '1.5rem',
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width:'80%s'
  },
  label: {
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  input: {
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '1rem',
  },
  textarea: {
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '1rem',
    resize: 'vertical',
  },
  fileInput: {
    marginBottom: '15px',
  },
  button: {
    padding: '12px',
    backgroundColor: '#fe4040',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    fontSize: '1rem',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  uploadLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf:'center',
    border: '2px dashed #ccc',
   
    cursor: 'pointer',
    marginBottom: '10px',
    borderRadius: '5px',
    width: '100%',
    maxWidth: '300px',
  },
  hiddenInput: {
    display: 'none',
  },
  plusButton: {
    fontSize: '30px',
    color: '#fe4040',
    fontWeight: 'bold',
  },
  imagePreviewContainer: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    marginTop: '10px',
  },
  imagePreviewWrapper: {
    display: 'flex',  // Allow flex behavior
    flexDirection: 'column', // Stack images vertically in small container
    alignItems: 'center', // Center images in wrapper
    justifyContent: 'center', // Center images in wrapper
    width: '60px', // Keep width consistent
    height: '60px', // Keep height consistent
    overflow: 'hidden', // Prevent overflow of images
    borderRadius: '4px',
    position:'relative',marginTop:20,marginBottom:20
  },
  imagePreview: {
    width: '60px',
    height: '60px',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  removeButton: {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    background: 'none',
    border: 'none',
    color: '#fe4040',
    fontSize: '20px',
    cursor: 'pointer',
  },
};

export default BookProductForm;
