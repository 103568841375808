import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getDatabase, ref, onValue, update, get } from 'firebase/database';
import { database } from '../firebase';
import ChatHeader from './ChatHeader';
import { faBiking, faClock, faRotate, faTimesCircle, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setUnseenMessagesCount } from '../Redux/actions/Notification';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [productData, setProductData] = useState({});
  const location = useLocation();
  const params = useParams();
  const userData = useSelector(state => state.UserData);
  const bottomRef = useRef(null);  // Reference to the bottom of the chat
  const [orderStatus,setOrderStatus]=useState(location.state.createOrder.orderStatus)
  const unseenMessage=useSelector(state=>state.Notification)
  const dispatch=useDispatch();
  // Real-time listener for messages
  useEffect(() => {
    const db = getDatabase();
    const messagesRef = ref(db, `orders/${location.state.createOrder.orderId}`);

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      if (snapshot.exists()) {
        const orderData = snapshot.val();
        setMessages(orderData.message || []);
        setOrderStatus(orderData.orderStatus?orderData.orderStatus:orderStatus); 
      } else {
        setMessages([]);
      }
    });

    return () => unsubscribe();
  }, [location.state.createOrder.orderId]);

  // Scroll to the bottom when messages update

  useEffect(() => {
    // When component mounts or when location changes (indicating entering a new chat)
    markMessagesAsSeen();
  }, [userData?.data[0]?._id, location,messages]);

  const markMessagesAsSeen = async () => {
    try {
      const db = getDatabase();
      const messagesRef = ref(db, `orders/${location.state.createOrder.orderId}/message`);
      
      // Fetch all messages for this order and update unseen messages to 'seen'
      const updates = {};
      
      const snapshot = await get(messagesRef);

      snapshot.forEach((child) => {
        const message = child.val();
        
        // Check if the message is sent to the current user and is not seen
        if (message.to === userData?.data[0]?._id && !message.isSeen) {
          updates[`${child.key}/isSeen`] = true; // Mark as seen
        }
      });
      
      // Perform a bulk update on all unseen messages at once
      await update(messagesRef, updates);
     // dispatch(setUnseenMessagesCount(unseenMessage.notificationCount-1))
      console.log("Messages marked as seen.");
    } catch (error) {
      console.error("Error marking messages as seen:", error);
    }
  };
  // Send a new message
  const sendMessage = async (text) => {
    const newMessage = {
      from: userData.data[0]?._id,
      to: params.id,
      type: "MESSAGE",
      text: text,
      sentOn: Date.now(),
      isSeen:false
    };

    try {
      const db = getDatabase();
      const updatedMessages = [...messages, newMessage];

      await update(ref(db, `orders/${location.state.createOrder.orderId}`), {
        message: updatedMessages,
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  useEffect(() => {
    if (bottomRef.current && (window.innerHeight + window.scrollY) >= document.body.offsetHeight - 100) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);
  useEffect(() => {
    if (location.state.createOrder) {
      setMessages(location.state.createOrder.message);
      setProductData(location.state.createOrder.message[0].productDetail);
    }
  }, [location.state]);
 const  updateOrderStatusFn=async(e,trackingUrl,trackingId)=>{
  let statusMessage;

  if(e=="FULLFILLED" && userData.data[0]?._id==location.state.createOrder.orderdFrom.userId)
  {
    statusMessage = {
      from: userData.data[0]?._id,
      to: params.id, // Adjust to target the correct user if needed
      type: "MESSAGE",
      text: `Business Marked Order Status: FULLFILLED`,
      sentOn: Date.now(),
      isSeen:false
    };
  
    try {
      const db = getDatabase();
  
      // Add the new status message to the message array
      const updatedMessages = [...messages, statusMessage];
  
      // Update both the message array and the order status in the database
      await update(ref(db, `orders/${location.state.createOrder.orderId}`), {
        message: updatedMessages,
        
        fullfilledFromBusiness: true

      });
  
      console.log("Order status and message updated successfully.");
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  }
  else if(e=="DISPATCHED")
  {
    statusMessage = {
      from: userData.data[0]?._id,
      to: params.id, // Adjust to target the correct user if needed
      type: "TRACKER",
      text: `Order status updated to: ${e}`,
      sentOn: Date.now(),
      isSeen:false,
      trackingId:trackingId,
      trackingUrl:trackingUrl
    };
    try {
      const db = getDatabase();
  
      // Add the new status message to the message array
      const updatedMessages = [...messages, statusMessage];
  
      // Update both the message array and the order status in the database
      await update(ref(db, `orders/${location.state.createOrder.orderId}`), {
        message: updatedMessages,
        orderStatus: e,
      });
  
      console.log("Order status and message updated successfully.");
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  }
  else
  {
    statusMessage = {
      from: userData.data[0]?._id,
      to: params.id, // Adjust to target the correct user if needed
      type: "MESSAGE",
      text: `Order status updated to: ${e}`,
      sentOn: Date.now(),
      isSeen:false
    };
  
    try {
      const db = getDatabase();
  
      // Add the new status message to the message array
      const updatedMessages = [...messages, statusMessage];
  
      // Update both the message array and the order status in the database
      await update(ref(db, `orders/${location.state.createOrder.orderId}`), {
        message: updatedMessages,
        orderStatus: e,
      });
  
      console.log("Order status and message updated successfully.");
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  }
   
 }
  return (
    <div style={styles.chatContainer}>
      <ChatHeader broughtFrom={location.state.createOrder} orderStatus={orderStatus} 
      updateOrderStatus={(e,trackingUrl,trackingId)=>{updateOrderStatusFn(e,trackingUrl,trackingId)}} fullfilledFromBusiness={location.state.createOrder.fullfilledFromBusiness}/>
      <div style={styles.messagesContainer}>
        {messages.map((msg, index) =>
          msg.type === 'PRODUCT' ? (
            <ProductCard key={index} product={msg.productDetail} isUser={msg.from} userData={userData.data[0]} orderStatus={orderStatus} />
          ) :(
            <Message key={index} content={msg} isUser={msg.from} userData={userData.data[0]} />
          )
        )}
        <div ref={bottomRef} />  {/* Bottom reference for auto-scroll */}

      </div>
      
      {!(orderStatus=="CANCELED" || orderStatus=="FULLFILLED")?
      <ChatInput onSend={sendMessage} />:
    ""}
    </div>
  );
};
const STATUS_STYLES = {
  PLACED: { icon: <FontAwesomeIcon icon={faClock}  />, color: '#007bff', text: 'Placed' },
  ACCEPTED: { icon: <FontAwesomeIcon icon={faClock}  />, color: '#28a745', text: 'Accepted' },
  FULLFILLED: { icon: <FontAwesomeIcon icon={faTruck}  />, color: '#ff9800', text: 'Fulfilled' },
  CANCELED: { icon: <FontAwesomeIcon icon={faTimesCircle}  />, color: '#dc3545', text: 'Canceled' },
  DISPATCHED: { icon: <FontAwesomeIcon icon={faBiking}  />, color: '#007bff', text: 'Dispached' },
  RETURN: { icon: <FontAwesomeIcon icon={faRotate}  />, color: 'orange', text: 'Return' },

};
const StatusBadge = ({ orderStatus }) => {
  const statusInfo = STATUS_STYLES[orderStatus] || {};

  return (
    <div style={{ ...styles.statusBadge, backgroundColor: statusInfo.color }}>
      {statusInfo.icon}
      <span style={styles.statusText}>{statusInfo.text}</span>
    </div>
  );
};

const ProductCard = ({ product, isUser, userData,orderStatus }) => (
  <div style={{ ...styles.cardContainer, ...(isUser === userData?._id ? styles.userProduct : styles.otherProduct) }}>
    <img src={product.image} alt={product.title} style={styles.image} />
    <div style={styles.detailsContainer}>
      <h3 style={styles.title}>{product.title}</h3>
      <span style={styles.price}>Price: Rs.{product.price}</span>
      <StatusBadge orderStatus={orderStatus} />
    </div>
  </div>
);

const Message = ({ content, isUser, userData }) => (
  <div style={{ ...styles.message, ...(isUser === userData?._id ? styles.userMessage : styles.otherMessage) }}>
    {content.text}
    {content?.trackingId?
     <div >
     <span>Tracking ID: {content.trackingId}</span>
     <br></br>
     <a href={content.trackingUrl} target="_blank" rel="noopener noreferrer" style={{color:'black'}}>
       Track Order
     </a>
   </div>
   :""}
   
  </div>
);

const ChatInput = ({ onSend }) => {
  const [input, setInput] = useState("");

  const handleSend = () => {
    if (input.trim()) {
      onSend(input);
      setInput("");
    }
  };

  return (
    <div style={styles.inputContainer}>
      <input
        style={styles.input}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type a message..."
      />
      <button style={styles.sendButton} onClick={handleSend}>Send</button>
    </div>
  );
};

const styles = {
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxWidth: '500px',
    width: '100%',
    margin: '0px auto',
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    
  },
  messagesContainer: {
    flex: 1,
    padding: '10px',
     marginBottom:'60px',
    overflowY: 'auto',
    marginTop:'60px',
    scrollBehavior: 'smooth',  // Adds smooth scrolling support

  },
  cardContainer: {
    display: 'flex',
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
    maxWidth: '300px',
    width: '70%',
  },
  image: {
    width: '80px',
    height: '120px',
    borderRadius: '4px',
    objectFit: 'cover',
    marginRight: '10px',
  },
  statusBadge: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    borderRadius: '8px',
    color: '#fff',
    fontWeight: 600,
    marginTop: '5px',
  },
  statusText: {
    marginLeft: '5px',
    fontSize: '0.9rem',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    color: 'black',
    fontSize: '12px',
    marginTop: '5px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'left',
  },
  price: {
    fontSize: '0.8rem',
    color: '#666',
  },
  message: {
    maxWidth: '70%',
    padding: '8px 12px',
    borderRadius: '8px',
    marginBottom: '10px',
    fontSize: '0.9rem',
    clear: 'both',
  },
  userMessage: {
    backgroundColor: '#fe4040',
    color: '#fff',
    float: 'right',
    borderTopRightRadius: '0',
    borderBottomLeftRadius: '8px',
    textAlign: 'left',
  },
  otherMessage: {
    backgroundColor: '#f0f0f0',
    color: '#333',
    float: 'left',
    borderTopLeftRadius: '0',
    borderBottomRightRadius: '8px',
    textAlign: 'left',
  },
  inputContainer: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #ddd',
    position:'fixed',
    bottom:'0px',
    width:'100%',
    maxWidth:'500px',
    backgroundColor:'white'
  },
  input: {
    flex: 2,
    padding: '8px',
    borderRadius: '8px',
    border: '1px solid #ddd',
    marginRight: '5px',
  },
  sendButton: {
    padding: '8px 12px',
    borderRadius: '8px',
    backgroundColor: '#fe4040',
    color: '#fff',
    border: 'none',
  },
};

export default Chat;
