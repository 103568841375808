import React from 'react';
import { NavLink } from 'react-router-dom';
import BottomTab from './BottomTab';
import Header from './Header';
import IMG from'../image/logo-trans.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faMessage } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const StoreBetaNotice = () => {
  const accessToken=useSelector(state=>state.Auth);
  const userDataredux=useSelector(state=>state.UserData)
  const notificationCount=useSelector(state=>state.Ordercount)

  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
   <Header />
    <div style={{
      maxWidth: '500px',
      margin: '60px auto',
      textAlign: 'center',
      padding:20,
      backgroundColor: '#f9f9f9',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px'
    }}>
      <h2 style={{
        fontSize: '24px',
        color: '#333',
        marginBottom: '10px'
      }}>Store Beta</h2>
      
      <p style={{
        fontSize: '16px',
        color: '#555',
        marginBottom: '20px'
      }}>
        Welcome to our experimental online store! We’re helping small businesses go online at zero cost. 
        This beta version is an ongoing trial.
       
       
        
      </p>
      <p style={{
        fontSize: '16px',
        color: '#555',
        marginBottom: '20px'
      }}>
If you'd like to set up a store  please click on contact us button below and fill in the form we will reach out to you shortly.
</p>
<div style={{display:'flex',flexDirection:'column'}}>
<NavLink to="/contactus" style={{
        display: 'inline-block',
        padding: '10px 20px',
        fontSize: '14px',
        color: 'white',
        backgroundColor: '#019875',
        textDecoration: 'none',
        borderRadius: '5px',
        transition: 'background-color 0.3s ease',
        marginBottom:20
      }}
      onMouseEnter={(e) => e.target.style.backgroundColor = '#01765c'}
      onMouseLeave={(e) => e.target.style.backgroundColor = '#019875'}
      >
        Contact Us
      </NavLink>
      <NavLink to="/store/collection_wisdom" style={{
        display: 'inline-block',
        padding: '10px 20px',
        fontSize: '14px',
        color: 'white',
        backgroundColor: '#019875',
        textDecoration: 'none',
        borderRadius: '5px',
        transition: 'background-color 0.3s ease'
      }}
      onMouseEnter={(e) => e.target.style.backgroundColor = '#01765c'}
      onMouseLeave={(e) => e.target.style.backgroundColor = '#019875'}
      >
        View Demo Store
      </NavLink>
</div>
  
    </div>
    {userDataredux?.data[0]?.isVerifiedBusiness?
   <NavLink
   to="/order"
   style={{
     display: 'flex',
     margin: '0px auto',
     padding: '10px 20px',
     fontSize: '14px',
     color: 'white',
     backgroundColor: '#fe4040',
     textDecoration: 'none',
     borderRadius: '5px',
     transition: 'background-color 0.3s ease',
     marginBottom: 20,
     maxWidth: '500px',
     position: 'relative', // to position the badge
   }}
   onMouseEnter={(e) => e.target.style.backgroundColor = '#01765c'}
   onMouseLeave={(e) => e.target.style.backgroundColor = '#019875'}
 >
   {/* View Orders text */}
   View Orders
 
   {/* Notification Badge */}
   {notificationCount.notificationCount > 0 && (
     <span
       style={{
         position: 'absolute',
         top: -5,
         right: -5,
         backgroundColor: 'red',
         color: 'white',
         borderRadius: '50%',
         padding: '2px 6px',
         fontSize: '10px',
         fontWeight: 'bold',
       }}
     >
       {notificationCount.notificationCount}
     </span>
   )}
 </NavLink>:''}

    <BottomTab />

    </div>
  );
}

export default StoreBetaNotice;
