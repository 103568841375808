export const updateEventPost=(eventPost,isVoted,eventId,isLoggedIn,eventData)=>(dispatch)=>{
    return dispatch({
     eventPost:eventPost,
     isVoted:isVoted,
     eventId:eventId,
     isLoggedIn:isLoggedIn,
     eventData:eventData,
     type:"UPDATEEVENTPOST"
    })
}

export const updateSelection=(eventPost)=>(dispatch)=>{
    return dispatch({
     eventId:eventPost._id,
     eventPost:eventPost,
     type:"UPDATESELECTIONPOST"
    })
}
export const disselectPost=(eventPost)=>(dispatch)=>{
    return dispatch({
     eventId:eventPost._id,
     eventPost:eventPost,
     type:"DISSELECTPOST"
    })
}
export const votePost=()=>(dispatch)=>{
    return dispatch({
     type:"VOTE"
    })
}