import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { equalTo, get, getDatabase, orderByChild, query, ref } from 'firebase/database';
import { auth } from '../firebase';
import BottomTab from './BottomTab';
import { Link } from 'react-router-dom';
import { createChat } from '../Redux/actions/BusinessChat';
import IMG from '../image/logo-trans.png';

const BusinessChat = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userdata = useSelector(state => state.UserData);
  const dispatch = useDispatch();
  const businessId = userdata.data[0]?._id ? userdata.data[0]?._id : null; // The business ID
  const userId = userdata.data[0]?._id; // Current user ID
  const token = localStorage.getItem('googleToken'); // Firebase auth token
  const businessChat = useSelector(state => state.BusinessChat);
  
  useEffect(() => {
    setOrders(businessChat.orders);
  }, [businessChat]);

  useEffect(() => {
    setError(null);  
    if (!token && businessId) {
      setError('User is not authenticated');
      setLoading(false);
      return;
    } else {
      if (auth.currentUser) {
        auth.currentUser
          .getIdTokenResult(token)
          .then(idTokenResult => {
            if (idTokenResult) {
              fetchOrders();
            } else {
              setError('Authentication failed');
              setLoading(false);
            }
          })
          .catch(err => {
            console.log(err);
            setError('Error verifying token');
            setLoading(false);
          });
      } else {
        setError('User is not authenticated');
        setLoading(false);
      }
    }
  }, [userdata]);

  // Fetch orders from Firebase Realtime Database
  const fetchOrders = async () => {
    setError(null);
    if (businessChat.orders.length) {
      setLoading(false);
      setOrders(businessChat.orders);
    } else {
      setLoading(true);
      try {
        const db = getDatabase();
        const ordersRef = ref(db, 'orders');
        const ordersQuery = query(ordersRef, orderByChild('orderrdBy/userId'), equalTo(businessId));
        const snapshot = await get(ordersQuery);

        if (snapshot.exists()) {
          const fetchedOrders = Object.values(snapshot.val());
          setOrders(fetchedOrders);
          dispatch(createChat(fetchedOrders));
        } else {
          console.log('No orders found');
        }
      } catch (err) {
        console.log(err);
        setError('Failed to fetch orders');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', maxWidth: '500px', margin: '0px auto', width: '100%' }}>
      {loading ? (
        <p>Loading orders...</p>
      ) : error ? (
        <p>{error}</p>
      ) : orders.length === 0 ? (
        <p>No orders found.</p>
      ) : (
        <div style={{ marginBottom: '60px' }}>
          {orders.map((order, index) => {
            const lastMessage = order.message[order.message.length - 1];
            const isUnseen = lastMessage?.to === userId && !lastMessage?.isSeen;

            return (
              <Link
                key={index}
                style={{
                  ...styles.orderContainer,
                  backgroundColor: isUnseen ? '#f9f9ff' : '#fff', // Highlight background for unseen messages
                }}
                to={`/chat/${order.orderdFrom.userId}`}
                state={{ createOrder: order }}
              >
                <div style={styles.orderHeader}>
                  {order.orderrdBy.profile_pic === "" ? (
                    <img
                      src={IMG}
                      alt={order.orderrdBy.username}
                      style={styles.profileImage}
                    />
                  ) : (
                    <img
                      src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${order.orderrdBy.profile_pic}`}
                      alt={order.orderrdBy.username}
                      style={styles.profileImage}
                    />
                  )}
                  <div style={styles.usernameContainer}>
                    <span style={styles.username}>{order.orderdFrom.username}</span>
                    <span style={{
                      ...styles.lastMessage,
                      fontWeight: isUnseen ? 'bold' : 'normal', // Bold text for unseen messages
                      color: isUnseen ? '#333' : '#555'          // Darker color for unseen messages
                    }}>
                      {lastMessage?.text}
                    </span>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}
      <BottomTab />
    </div>
  );
};

const styles = {
  orderContainer: {
    borderBottom: '1px solid #ddd',
    padding: '10px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    width: '100%',
    boxSizing: 'border-box',
    textDecoration: 'none',
    color: 'black',
  },
  orderHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  profileImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: '10px',
  },
  usernameContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  username: {
    fontWeight: '500',
    fontSize: '1rem',
  },
  lastMessage: {
    fontSize: '0.8rem',
    width: '80%',
    maxWidth: '300px',
    color: '#555',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default BusinessChat;
