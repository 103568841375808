import React from 'react'
import '../css/header.css';
import '../css/globalstyle.css';
import IMG from'../image/logo-trans.png'


import { useState, useTransition } from 'react';
import { NavLink } from "react-router-dom";
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const SideNavigation = ({ categories, activeCategory, onCategorySelect }) => {
  const [isOpne,setIsOpen]=useState("none")
  const [modalVisible, setModalVisible] = useState(false);
  const [showList,setshowList]=useState("none")
  const transitions = useTransition(modalVisible, null, {
    from: { opacity: 0, transform: "translateY(-40px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(-40px)" }
  });
  const showListCon=()=>{
    if(showList=="none")
    {
      setshowList("flex")
    }
    else
    {
      setshowList("none")
    }
  }
return (
 

  <div className='appHeader1' style={{paddingTop:'10px'}}>
    <NavLink to="/" style={{ textDecoration: 'none', color: '#fe4040' }}>
      <img src={IMG} className='applogoother' style={{ height: 40 }} />
    </NavLink>
    
    {
      isOpne === "none" ?
        <FontAwesomeIcon icon={faBars} style={{
          fontSize: 26, color: '#333', cursor: 'pointer',marginRight:10,
        }} onClick={() => {
          setIsOpen("block");
          setModalVisible(true);
        }} /> :
        <FontAwesomeIcon icon={faClose} style={{
          fontSize: 26, color: '#333', cursor: 'pointer',marginRight:10,
        }} onClick={() => {
          setIsOpen("none");
          setModalVisible(false);
        }} />
    }
  
    <div className="sideNavigation" style={{
      display: isOpne, position: 'absolute', top: '64px', left: 0, width: '100%', backgroundColor: 'white',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)', zIndex: 999, transition: '0.4s ease', padding: '20px 0px',height:'100vh',
    }}>
      <ul style={styles.categoryList}>
            {categories.map((category) => (
              <li
                key={category}
                onClick={() => {onCategorySelect(category);setIsOpen('none');setModalVisible(false)}}
                style={{
                  ...styles.categoryItem,
                  ...(activeCategory === category ? styles.activeCategory : {}),
                }}
              >
                {category}
              </li>
            ))}
          </ul>
    </div>
  </div>
  
       
         
        
    
  


);
}

const styles = {
    sidebar: {
      position: 'fixed',
      left: 0,
      top: '0',
      width: '200px',
      height: '100vh',
      backgroundColor: '#f5f5f5',
      padding: '20px',
      borderRight: '1px solid #ddd',
      transition: 'all 0.3s ease',
      zIndex: 1000,
    },
    toggleButton: {
      cursor: 'pointer',
      color: '#fe4040',
      fontWeight: 'bold',
      marginBottom: '10px',
      fontSize: '1rem',
      padding: '10px',
      textAlign: 'center',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '15px',
    },
    heading: {
      fontSize: '1.2rem',
      margin: 0,
      color: '#333',
    },
    closeButton: {
      fontSize: '20px',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: '#fe4040',
    },
    categoryList: {
      listStyleType: 'none',
      padding: 0,
    },
    categoryItem: {
      padding: '10px',
      cursor: 'pointer',
      borderRadius: '4px',
      color: '#333',
      fontSize: '1rem',
      transition: 'background-color 0.3s',
    },
    activeCategory: {
      backgroundColor: '#fe4040',
      color: '#fff',
    },
  };

export default SideNavigation;
