import React, { useEffect, useState } from 'react'
import Header from './Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { fetchUrl } from '../Contsant'
import Spinner from './Spinner'

const Password = () => {
    const [email,setEmail]=useState("")
    const [password,setpassword]=useState("")
    const [showpassword,setShowPassword]=useState(false)
    const location=useLocation()
    const [username,setusername]=useState(null)
    const navigate=useNavigate()
    const [haserror,setHasError]=useState({error:false,errormsg:''})
    const [isClickable,setIsClickable]=useState(true)

    function ValidateEmail(input) {

      
      var validRegex =  /^[a-zA-Z0-9._%+-]+@[^\s@]+\.[a-zA-Z]{2,}$/;
      if(input.match(validRegex))
        {
         return true
        }
        else
        {
          return false
        }
    }
    useEffect(()=>{

       if(location.state==null)
        {
          navigate("/profile")
        }
        else
        {
          
          setusername(location.state.username)
        }
    },[])
    const verifyemailid=()=>{
      setHasError({error:false,errormsg:''})

     if(email==="")
      {
        setHasError({error:true,errormsg:'Please enter a valid email!'})

      }
      else
      {
        if(email==="" || password=="")
          {
            setHasError({error:true,errormsg:'Email and password must be entered'})
          }
          else
          {
           
            if (email.toLowerCase().split('@')[1].split('.')[0] !== 'gmail') {
            setHasError({error:true,errormsg:'Only Gmail Account Are Allowed!'}) 
                return;
              }
           
            else
            {
              setIsClickable(false)
              const payload = {
                email,
                username,
                password
            };
            fetch(`${fetchUrl}webfn/createaccount`, {
             method: 'POST',
             headers: {
                 'Content-Type': 'application/json',
             },
             body: JSON.stringify(payload),
          })
          .then(async res => { 
          
              
               const jsonRes = await res.json();
              setIsClickable(true)
  
               if (jsonRes.statusCode === 409) {
                //dispatch(openpopupError(true,"LOGIN_CONTAINER","flex","EMAILERROR",jsonRes))
             //show Errors
             setHasError({error:true,errormsg:`This Email:${email}  is verified with another account`}) 
            }
                else if(jsonRes.statusCode === 211)
                 {
                   navigate('/verifycode',{state:{email,username}})
                }
                else if(jsonRes.statusCode === 309)
                  {
                    setHasError({error:true,errormsg:jsonRes.message}) 
   
                 }
                else
                {
              setHasError({error:true,errormsg:`Something went Wrong Please try again later`}) 
  
                }
                }
               )
               .catch((err)=>{
              setIsClickable(true)
  
                setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
               
            })
            }
           
          }
        
      }
     
  }

  return (
    <div style={{display:'flex',flex:1,flexDirection:'column'}}>
    <Header />
    <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 30, 
      maxWidth: 500, width: '100%', margin: '50px auto', boxSizing: 'border-box', 
      backgroundColor: '#f7f7f7', borderRadius: 10, boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      minHeight: '100vh' // Ensures the container has a minimum height
    }}>
      <p style={{
        color: '#333', fontFamily: 'Poppins', fontSize: 32, fontWeight: 'bold',
        textAlign: 'center', margin: '20px 0',
      }}>Add Email & Password</p>
  
      {haserror?.error && (
        <p style={{
          color: 'white', backgroundColor: '#d9534f', padding: 10, margin: '10px 0',
          textAlign: 'center', borderRadius: 10
        }}>{haserror.errormsg}</p>
      )}
  
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <div style={{
          display: 'flex', border: '1px solid #ccc', borderRadius: 10, padding: 10, 
          alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Box shadow added
        }}>
          <input type='email' placeholder='Add Your Email' style={{
            width: '100%', border: 'none', outline: 'none', color: '#333', 
            padding: 12, fontSize: 16, height: 20 
          }} onChange={(e) => {
            if (ValidateEmail(e.target.value)) setEmail(e.target.value);
            else setEmail("");
          }} />
        </div>
  
        <div style={{
          display: 'flex', border: '1px solid #ccc', borderRadius: 10, padding: 10, 
          alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Box shadow added
        }}>
          <input type={showpassword ? 'text' : 'password'} placeholder='Password' style={{
            width: '100%', border: 'none', outline: 'none', color: '#333', 
            padding: 12, fontSize: 16, height: 20 
          }} onChange={(e) => setpassword(e.target.value)} />
          <FontAwesomeIcon 
            icon={showpassword ? faEyeSlash : faEye} 
            style={{ cursor: 'pointer', marginLeft: 10, color: '#4db6ac' }} 
            onClick={() => setShowPassword(!showpassword)} 
          />
        </div>
      </div>
  
      <button onClick={() => verifyemailid()} style={{
        padding: 12, backgroundColor: '#4db6ac', color: 'white', borderRadius: 10,
        border: 'none', width: '50%', margin: '20px auto', cursor: 'pointer',
        maxWidth: 200, textAlign: 'center', fontSize: 16, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.3s ease-in-out' // Transition for hover effect
      }} disabled={!isClickable}>
        {isClickable ? 'Signup' : <Spinner />}
      </button>
  
      <div style={{ 
        height: 1, backgroundColor: '#ddd', width: '80%', margin: '20px auto' 
      }}></div>
  
      <p style={{ textAlign: 'center', color: '#555', fontSize: 14 }}>
        Already have an account? <br />
        <NavLink to="/login" style={{ color: '#fe4040', fontSize: 16,textDecoration:'none' }}>
          Login
        </NavLink>
      </p>
    </div>
  </div>

  )
}

export default Password