import React, { useEffect, useState } from 'react'
import Header from './Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import {fetchUrl} from '../Contsant/index'
import { useDispatch } from 'react-redux'
import { userLogin } from '../Redux/actions/Auth'
import { setUserData } from '../Redux/actions/UserData'
import Spinner from './Spinner'
export const Login = () => {
  const location=useLocation()
    const [username,setUsername]=useState("")
    const [password,setpassword]=useState("")
    const [showpassword,setShowPassword]=useState(false)
    const [isLogging,setisLogging]=useState(false)
    const [haserror,setHasError]=useState({error:false,errormsg:''})
    const dispatch=useDispatch()
    const navigate=useNavigate()
   
    const loginFn=async()=>{
      setHasError({error:false,errormsg:''})
      if(username=='' || password=='')
        {
         
        }
        else
        {
          const payload={username,password}
          setisLogging(true)
          fetch(`${fetchUrl}webauth/login`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            }).then(async res => { 
              try {
              const jsonRes = await res.json();
             switch (jsonRes.statusCode) {
              case 401:
                setHasError({error:true,errormsg:'Either Your Username Or Password Is Incorrect!'})
                setisLogging(false)

                break;
                case 404:
                setHasError({error:true,errormsg:'No User Found!'})
                setisLogging(false)

                break;
              case 200:
                
                  localStorage.setItem("accessToken",jsonRes.accessToken)
                  localStorage.setItem("googleToken",jsonRes.googleToken)

                 
                  dispatch(userLogin(jsonRes.accessToken,jsonRes.googleToken))
                  dispatch(setUserData([jsonRes.userData]))
                 
                
            
                setisLogging(false)

                 break;
               default:
                setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
                setisLogging(false)

                break;
             }
              }catch (err) {

              }
            }).catch((err)=>{
                setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
                setisLogging(false)
            })
        }
    
    }
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
      <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', 
      padding: 30, maxWidth: 500, width: '100%', margin: '50px auto', boxSizing: 'border-box', 
      backgroundColor: '#f7f7f7', borderRadius: 10, boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      minHeight: '100vh' // Ensures the container has a minimum height
    }}>
      <p style={{
        color: '#333', fontFamily: 'Poppins', fontSize: 32, fontWeight: 'bold',
        textAlign: 'center', margin: '20px 0',
      }}>Welcome back</p>
      <p style={{
  fontFamily: 'Poppins, sans-serif', textAlign: 'center', color: '#333', fontSize: 18, 
  fontWeight: '500', margin: '10px 0'
}}>Login to Justscroll</p>

      {haserror?.error && (
        <p style={{
          color: 'white', backgroundColor: '#d9534f', padding: 10, margin: '10px 0',
          textAlign: 'center', borderRadius: 10
        }}>{haserror.errormsg}</p>
      )}

      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <div style={{
          display: 'flex', border: '1px solid #ccc', borderRadius: 10, padding: 10, 
          alignItems: 'center' // Center align text inside the input
        }}>
          <input type="text" placeholder="Username Or Email" style={{
            width: '100%', border: 'none', outline: 'none', color: '#333', 
            padding: 12, fontSize: 16, height: 20 // Adjusted height
          }} onChange={(e) => setUsername(e.target.value)} />
        </div>

        <div style={{
          display: 'flex', border: '1px solid #ccc', borderRadius: 10, padding: 10, 
          alignItems: 'center'
        }}>
          <input type={showpassword ? 'text' : 'password'} placeholder="Password" style={{
            width: '100%', border: 'none', outline: 'none', color: '#333', 
            padding: 12, fontSize: 16, height: 20 // Adjusted height
          }} onChange={(e) => setpassword(e.target.value)} />
          <FontAwesomeIcon 
            icon={showpassword ? faEyeSlash : faEye} 
            style={{ cursor: 'pointer', marginLeft: 10, color: '#4db6ac' }} 
            onClick={() => setShowPassword(!showpassword)} 
          />
        </div>
      </div>

      <button onClick={loginFn} disabled={isLogging} style={{
        padding: 12, backgroundColor: '#4db6ac', color: 'white', borderRadius: 10,
        border: 'none', width: '50%', margin: '20px auto', cursor: 'pointer',
        maxWidth: 200, textAlign: 'center', fontSize: 16
      }}>
        {isLogging ? <Spinner /> : 'LOGIN'}
      </button>

      <div style={{ 
        height: 1, backgroundColor: '#ddd', width: '80%', margin: '20px auto' 
      }}></div>

      <p style={{ textAlign: 'center', color: '#555', fontSize: 14 }}>
        Having trouble logging in?<br />
        <NavLink to="/forgetpassword" style={{ color: '#fe4040', fontSize: 16,textDecoration:'none' }}>
          Forgot password?
        </NavLink>
      </p>

      <div style={{display:'flex',
      alignSelf:'center',position:'fixed',bottom:0,width:'100%',
      maxWidth:'500px',height:50,boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',zIndex:999,textAlign:'center',
      flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'white'}}>
        <NavLink to="/chooseUsername" style={{ color: '#4db6ac',textDecoration:'none' }}>
          Don't have an account? <span style={{ color: '#fe4040' }}>Sign Up</span>
        </NavLink>
      </div>
    </div>
     
    </div>
  )
}
