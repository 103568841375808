// components/AddProductPage.js
import React, { useState } from 'react';

import BookProductForm from './BookProductForm';
import SideNavigation from './SideNavProduct';
import { width } from '@fortawesome/free-solid-svg-icons/faAdd';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';


const AddProductPage = () => {
  const [activeCategory, setActiveCategory] = useState('Books');
  const userdata=useSelector(state=>state.UserData)
  

  const categories = ['Books', 'Clothing']; // Extendable categories

  const handleCategorySelect = (category) => {
    setActiveCategory(category);
  };

  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',marginBottom:50,}}>
      <SideNavigation categories={categories} activeCategory={activeCategory} onCategorySelect={handleCategorySelect} />
      {
        !userdata.data[0].isVerifiedBusiness?
        <div style={{
          maxWidth: '500px',
          margin: '60px auto',
          textAlign: 'center',
          padding:20,
          backgroundColor: '#f9f9f9',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px'
        }}>
          <h2 style={{
            fontSize: '24px',
            color: '#333',
            marginBottom: '10px'
          }}>Store Beta</h2>
          
          <p style={{
            fontSize: '16px',
            color: '#555',
            marginBottom: '20px'
          }}>
            Welcome to our experimental online store! We’re helping small businesses go online at zero cost. 
            This beta version is an ongoing trial.
           
           
            
          </p>
          <p style={{
            fontSize: '16px',
            color: '#555',
            marginBottom: '20px'
          }}>
    If you'd like to set up a store  please click on contact us button below and fill in the form we will reach out to you shortly.
    </p>
    <div style={{display:'flex',flexDirection:'column'}}>
    <NavLink to="/contactus" style={{
            display: 'inline-block',
            padding: '10px 20px',
            fontSize: '14px',
            color: 'white',
            backgroundColor: '#019875',
            textDecoration: 'none',
            borderRadius: '5px',
            transition: 'background-color 0.3s ease',
            marginBottom:20
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = '#01765c'}
          onMouseLeave={(e) => e.target.style.backgroundColor = '#019875'}
          >
            Contact Us
          </NavLink>
          <NavLink to="/store/collection_wisdom" style={{
            display: 'inline-block',
            padding: '10px 20px',
            fontSize: '14px',
            color: 'white',
            backgroundColor: '#019875',
            textDecoration: 'none',
            borderRadius: '5px',
            transition: 'background-color 0.3s ease'
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = '#01765c'}
          onMouseLeave={(e) => e.target.style.backgroundColor = '#019875'}
          >
            View Live
          </NavLink>
    </div>
    
        </div>: <div style={styles.formContainer}>
        {activeCategory === 'Books' && <BookProductForm onSubmit={(formData) => console.log(formData)} />}
      </div>
      }
     
    </div>
  );
};

const styles = {

  formContainer: {
    display:'flex',
    flex: 1,
  
    maxWidth:'500px',
    width:'100%',
    alignSelf:'center',
  },
};

export default AddProductPage;
