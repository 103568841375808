import React, { useRef } from 'react';

const InvoiceGenerator = () => {
  const invoiceRef = useRef();

  const orderDetails = {
    items: [
      { name: 'Book 1', quantity: 2, price: 15.99 },
      { name: 'Book 2', quantity: 1, price: 20.00 },
      { name: 'Book 3', quantity: 3, price: 9.99 },
    ],
    totalAmount: 15.99 * 2 + 20.00 + 9.99 * 3, // Sum of all item totals
  };

  const businessDetails = {
    name: 'Amazing Books Store',
    address: '123 Book St, Reading City, ABC 12345',
    contact: '(555) 123-4567',
    email: 'contact@amazingbooks.com',
    logo: 'https://via.placeholder.com/100', // Replace with actual logo URL
  };

  const userData = {
    name: 'John Doe',
    address: '456 Fiction Ave, Imaginary Town, XYZ 67890',
    contact: '(555) 987-6543',
    email: 'johndoe@example.com',
  };

  const printInvoice = () => {
    const printContent = invoiceRef.current.innerHTML;
    const windowPrint = window.open('', '', 'width=800,height=900');
    windowPrint.document.write(`
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
              background-color: #f5f5f5;
            }
            .invoice-container {
              background: white;
              padding: 20px;
              border-radius: 10px;
              box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
              max-width: 800px;
              margin: auto;
            }
            .invoice-header {
              text-align: center;
              margin-bottom: 20px;
            }
            .invoice-header img {
              max-width: 100px;
            }
            .invoice-header h1 {
              margin: 0;
              font-size: 30px;
              color: #333;
            }
            .invoice-details {
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
            }
            .invoice-details .address {
              width: 45%;
              padding: 10px;
              border: 1px solid #ddd;
              border-radius: 8px;
              background: #f9f9f9;
            }
            .invoice-details .billing {
              width: 45%;
              padding: 10px;
              border: 1px solid #ddd;
              border-radius: 8px;
              background: #f9f9f9;
            }
            .table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            .table th, .table td {
              padding: 10px;
              border: 1px solid #ddd;
              text-align: left;
            }
            .table th {
              background-color: #f4f4f4;
            }
            .total {
              font-size: 18px;
              font-weight: bold;
              text-align: right;
              margin-top: 20px;
            }
            @media print {
              body {
                margin: 0;
                padding: 0;
              }
              .invoice-container {
                box-shadow: none;
                margin: 0;
                padding: 0;
              }
            }
          </style>
        </head>
        <body>
          <div class="invoice-container">
            <div class="invoice-header">
             
              <h1>Invoice</h1>
            </div>
            <div class="invoice-details">
              <div class="address">
                <strong>Business Information:</strong>
                <p>${businessDetails.name}</p>
                <p>${businessDetails.address}</p>
                <p>${businessDetails.contact}</p>
                <p>${businessDetails.email}</p>
              </div>
              <div class="billing">
                <strong>Customer Information:</strong>
                <p>${userData.name}</p>
                <p>${userData.address}</p>
                <p>${userData.contact}</p>
                <p>${userData.email}</p>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                ${orderDetails.items
                  .map(
                    (item) => `
                      <tr>
                        <td>${item.name}</td>
                        <td>${item.quantity}</td>
                        <td>$${item.price.toFixed(2)}</td>
                        <td>$${(item.quantity * item.price).toFixed(2)}</td>
                      </tr>`
                  )
                  .join('')}
              </tbody>
            </table>
            <div class="total">
              <strong>Total: $${orderDetails.totalAmount.toFixed(2)}</strong>
            </div>
          </div>
        </body>
      </html>
    `);
    windowPrint.document.close();
    windowPrint.print();
  };

  return (
    <div>
      <div ref={invoiceRef}>
        {/* Invoice Content */}
        <div className="invoice-container">
          <div className="invoice-header">
            <img src={businessDetails.logo} alt="Logo" style={{ maxWidth: '100px' }} />
            <h1>Invoice</h1>
          </div>
          <div className="invoice-details">
            <div className="address">
              <strong>Business Information:</strong>
              <p>{businessDetails.name}</p>
              <p>{businessDetails.address}</p>
              <p>{businessDetails.contact}</p>
              <p>{businessDetails.email}</p>
            </div>
            <div className="billing">
              <strong>Customer Information:</strong>
              <p>{userData.name}</p>
              <p>{userData.address}</p>
              <p>{userData.contact}</p>
              <p>{userData.email}</p>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>${item.price.toFixed(2)}</td>
                  <td>${(item.quantity * item.price).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="total">
            <strong>Total: ${orderDetails.totalAmount.toFixed(2)}</strong>
          </div>
        </div>
      </div>
      <button onClick={printInvoice}>Download as PDF</button>
    </div>
  );
};

export default InvoiceGenerator;
