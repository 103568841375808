const initialState = {
    notificationCount:0,
    
   
   }
   
   export const Notification = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATENOTIFICATION":
          return {
            ...state,
            notificationCount: action.notificationCount,
          };
        default:
          return state;
      }
   }
  
  