import React from 'react'
import Header from './Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLink, faPhone } from '@fortawesome/free-solid-svg-icons'

const Contactus = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh',margin:'0px auto',width:'100%',maxWidth:'500px'}}>
      <Header/>
    <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      maxWidth: 500, width: '100%', backgroundColor: '#f7f7f7', borderRadius: 10,
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',marginTop:60
    }}>
      <h2 style={{
        color: '#333', fontFamily: 'Poppins', fontSize: 32, fontWeight: 'bold', textAlign: 'center', marginBottom: 20,padding:10,
      }}>Contact Us</h2>
  
      <p style={{
        fontFamily: 'Poppins', textAlign: 'center', color: '#333', fontSize: 18, fontWeight: 500, marginBottom: 20,padding:10,
      }}>Let us know how we can help!</p>
  
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15, width: '90%',alignItems:'center',}}>
        <label style={{ fontFamily: 'Poppins', fontSize: 16 }}>Please select your query category.</label>
        <select style={{
          padding: 12, border: '1px solid #ccc', borderRadius: 10, width: '90%', fontSize: 16,
          fontFamily: 'Poppins', outline: 'none'
        }}>
          <option value="creator">Creator</option>
          <option value="brands">Brands</option>
          <option value="influencer">Influencer</option>
        </select>
  

  
        <label style={{ fontFamily: 'Poppins', fontSize: 16 }}>Reason for Contact</label>
        <textarea placeholder="Write your reason here..." style={{
          padding: 12, border: '1px solid #ccc', borderRadius: 10, width: '90%', fontSize: 16,
          fontFamily: 'Poppins', outline: 'none', minHeight: 120 ,
        }}></textarea>
  
        <button style={{
          padding: 12, backgroundColor: '#4db6ac', color: 'white', borderRadius: 10, border: 'none',
          width: '140px', fontSize: 16, fontFamily: 'Poppins', cursor: 'pointer',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)', marginTop: 20
        }}>Submit</button>
      </div>
  
      {/* Contact Details Section */}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'left', marginTop: 40 }}>
        <h2 style={{ padding: 10, textAlign: 'left' }}>Get in Touch</h2>
  
        <div style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 10, fontSize: 18, color: '#4db6ac' }} />
          <a href="mailto:nikhil.justscroll@gmail.com" style={{ textDecoration: 'none', color: '#333', fontWeight: 500 }}>Email Us</a>
        </div>
  
        <div style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faPhone} style={{ marginRight: 10, fontSize: 18, color: '#4db6ac' }} />
          <a href="tel:+917488331696" target="_blank" style={{ textDecoration: 'none', color: '#333', fontWeight: 500 }}>Call Us</a>
        </div>
  
        <div style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faLink} style={{ marginRight: 10, fontSize: 18, color: '#4db6ac' }} />
          <a href="https://instagram.com/justscroll_customer_support" target="_blank" style={{ textDecoration: 'none', color: '#333', fontWeight: 500 }}>Connect on Instagram</a>
        </div>
        <div style={{ padding: 10, display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faLink} style={{ marginRight: 10, fontSize: 18, color: '#4db6ac' }} />
          <a href=" https://in.linkedin.com/company/contetor?trk=public_profile_topcard-current-company" target="_blank"
           style={{ textDecoration: 'none', color: '#333', fontWeight: 500 }}>Connect on Linkedin</a>
        </div>
       
        <div style={{ padding: 10, textAlign: 'left', fontWeight: 500 }}>
          <p>Memesta Services PVT LTD,</p>
          <p>New Ashok Nagar, New Delhi</p>
        </div>
      </div>
    </div>
  </div>
  

    
  )
}

export default Contactus