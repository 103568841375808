import React from 'react';
import IMG from'../image/logo-trans.png'

const Comment = (props) => {
    const { userData, comment: text, } = props.comment;
    const user = userData[0];
  
    // Function to render text with clickable mentions
    const renderCommentText = (text) => {
      const mentionRegex = /@(\S+)/g; // Matches text starting with @ until a space
  
      const parts = text.split(mentionRegex);
      return parts.map((part, index) => {
        // If it's a mention part (odd index), wrap it in a clickable span
        if (index % 2 === 1) {
          return (
            <span 
              key={index} 
              onClick={() => handleMentionClick(part)} // Handle mention click
              style={{ color: 'blue', cursor: 'pointer' }} // Style for clickable mention
            >
              @{part}
            </span>
          );
        }
        return part; // Otherwise, just render the text part
      });
    };
  
    const handleMentionClick = (mention) => {
      // Handle click event for mention, e.g., navigate to the user's profile
     
      props.updateTap(mention)
    };
  
    return (
      <div style={styles.container}>
        <div style={styles.commentheader}>
          {user.profilePic? <img
            src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${user.profilePic}`}
            alt="Profile"
            style={styles.usericonInside}
          />:
          <img
          src={IMG}
          alt="Profile"
          style={styles.usericonInside}
        />}
         
        </div>
        <div style={styles.commentContent}>
          <div style={styles.userInfo}>
            <span style={styles.username}>{user.username}</span>
          </div>
          <p style={styles.commentText}>{renderCommentText(text)}</p>
        </div>
      </div>
    );
};

const styles = { 
  container: {
    marginBottom: '10px',
    paddingVertical: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  commentheader: {
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
  },
  usericonInside: {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    border: '1px solid white',
    marginRight: '10px',
  },
  commentContent: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    alignSelf: 'flex-start',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  username: {
    fontSize: '12px',
    fontWeight: '600',
    color: 'black',
  },
  commentText: {
    color: 'black',
    fontSize: '12px',
    marginTop: '5px',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textAlign:'left',
  },
};

export default Comment;
