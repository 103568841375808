import { faArrowAltCircleLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from './Spinner';
import { fetchUrl } from '../Contsant';
import { setUserData } from '../Redux/actions/UserData';
import { userLogin } from '../Redux/actions/Auth';
import { updateForward } from '../Redux/actions/Forward';
import { useNavigate } from 'react-router-dom';

const LoginUI = (props) => {
    const [email, setEmail] = useState("");
    const forward = useSelector(state => state.Forward);
    const [hasCode, setHasCode] = useState(false);
    const [isGeneratingCode, setIsGeneratingCode] = useState(false);
    const [userCode, setUserCode] = useState("");
    const dispatch = useDispatch();
    const [statusCode, setStatusCode] = useState(null);
    const [errorCheckemail, seterrorCheckemail] = useState({ status: false, message: '' });
    const [errorChecklogin, seterrorChecklogin] = useState({ status: false, message: '' });
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const navigate = useNavigate();

    const getVeriifcationCode = async () => {
        if (email.toLowerCase().split('@')[1].split('.')[0] !== 'gmail') {
            seterrorCheckemail({ status: true, message: 'Only Gmail accounts are allowed!' });
            return;
        }
        if (email !== "") {
            seterrorCheckemail({ status: false, message: '' });
            setIsGeneratingCode(true);
            fetch(`${fetchUrl}webAuth/emailLogin/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email })
            })
            .then(async res => {
                const jsonres = await res.json();
                if (jsonres.statusCode === 211 || jsonres.statusCode === 212) {
                    setHasCode(true);
                    setStatusCode(jsonres.statusCode);
                }
                else if(jsonres.statusCode === 309)
                {
                    seterrorCheckemail({ status: true, message: 'Only Gmail Account Are Allowed!' });

                }
                 else {
                    seterrorCheckemail({ status: true, message: 'Something went wrong, try again!' });
                }
                setIsGeneratingCode(false);
            })
            .catch(() => {
                seterrorCheckemail({ status: true, message: 'Something went wrong, try again!' });
                setIsGeneratingCode(false);
            });
        }
    };

    const Login = async () => {
        if (userCode !== "") {
            setIsLoggingIn(true);
            seterrorChecklogin({ status: false, message: '' });

            fetch(`${fetchUrl}webAuth/checkCode/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, code: userCode, statusCode })
            })
            .then(async res => {
                const jsonres = await res.json();
                if (jsonres.statusCode === 211) {
                    dispatch(userLogin(jsonres.accessToken));
                    dispatch(setUserData([jsonres.user]));
                    localStorage.setItem("accessToken", jsonres.accessToken);
                    window.location.reload();
                } else if (jsonres.statusCode === 403) {
                    seterrorChecklogin({ status: true, message: 'Incorrect code!' });
                } else {
                    seterrorChecklogin({ status: true, message: 'Something went wrong, try again!' });
                }
                setIsLoggingIn(false);
            })
            .catch(() => {
                seterrorChecklogin({ status: true, message: 'Something went wrong, try again!' });
                setIsLoggingIn(false);
            });
        }
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
            maxWidth: '500px',
            height: 'auto',
            position: 'fixed',
            bottom: 0,
            zIndex: 1100,
            backgroundColor: '#fff',
            borderRadius: '8px 8px 0 0',
            borderTop: '1px solid #ddd',
            padding: '20px',
            boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
            overflowY: 'auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px',
                backgroundColor: '#f8f8f8',
                borderRadius: '8px'
            }}>
                {hasCode ? (
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{ fontSize: 26, color: '#333' }} onClick={() => setHasCode(false)} />
                ) : (
                    <FontAwesomeIcon icon={faClose} style={{ display:'flex',fontSize:26,color:'#333',justifySelf:'flex-end' }} onClick={props.updateLoginUi} />
                )}
            </div>

            <div style={{
                marginTop: '20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                {hasCode ? (
                    <>
                        {errorChecklogin.status && <p style={{ color: 'red' }}>{errorChecklogin.message}</p>}
                        <h4 style={{ color: '#66003a', fontFamily: 'Quicksand' }}>Enter the 6-digit code sent to your email</h4>
                        <input
                            type='number'
                            placeholder='Enter Code'
                            style={{
                                width: '80%',
                                maxWidth: '300px',
                                padding: '10px',
                                margin: '20px 0',
                                borderRadius: '8px',
                                border: '1px solid #ddd',
                                outline: 'none',
                                color: '#333'
                            }}
                            onChange={(e) => setUserCode(e.target.value)}
                        />
                        <button
                            style={{
                                width: '80%',
                                maxWidth: '200px',
                                padding: '10px',
                                backgroundColor: '#66003a',
                                borderRadius: '8px',
                                border: 'none',
                                color: '#fff',
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                            disabled={isLoggingIn}
                            onClick={Login}
                        >
                            {isLoggingIn ? <Spinner /> : 'Login'}
                        </button>
                    </>
                ) : (
                    <>
                        <h3 style={{ color: '#66003a', fontFamily: 'Quicksand' }}>Please login to vote</h3>
                        {errorCheckemail.status && <p style={{ color: 'red' }}>{errorCheckemail.message}</p>}
                        <input
                            type='email'
                            placeholder='Enter Your Gmail Address!'
                            style={{
                                width: '80%',
                                maxWidth: '300px',
                                padding: '10px',
                                margin: '20px 0',
                                borderRadius: '8px',
                                border: '1px solid #ddd',
                                outline: 'none',
                                color: '#333'
                            }}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                            style={{
                                width: '80%',
                                maxWidth: '200px',
                                padding: '10px',
                                backgroundColor: '#66003a',
                                borderRadius: '8px',
                                border: 'none',
                                color: '#fff',
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                            disabled={isGeneratingCode}
                            onClick={getVeriifcationCode}
                        >
                            {isGeneratingCode ? <Spinner /> : 'Get Verification Code'}
                        </button>
                        <p style={{ margin: '20px 0', color: '#555' }}>OR</p>
                        <button
                            style={{
                                width: '80%',
                                maxWidth: '200px',
                                padding: '10px',
                                backgroundColor: '#fe4040',
                                borderRadius: '8px',
                                border: 'none',
                                color: '#fff',
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                if (props.serach) {
                                    dispatch(updateForward('vote', props.eventId.eventId, `postId=${props.serach}`));
                                } else {
                                    dispatch(updateForward('vote', props.eventId.eventId));
                                }
                                navigate(`/login`);
                            }}
                        >
                            Login Using Username
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}

export default LoginUI;
