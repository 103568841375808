const initialState = {
    username:'',
    storeData:[],
    userData:null


   
   }
   
   export const Store = (state = initialState, action) => {
       switch (action.type) {
           case "UPDATE":
               return {
                   ...state,
                   username:action.username,
                   storeData:action.storeData,
                   userData:action.userData
               }
           default:
               return state;
       }
   }
  
  