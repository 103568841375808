// actions.js


// Action to add a new order
export const createOrder = (order) => {
 
    return {
      type: 'CREATE_ORDER',
      payload: order,
    };
  };
export const addOrder = (order) => {
  return {
    type: 'ADD_ORDER',
    payload: order,
  };
};

// Action to update an existing order by orderId
export const updateOrder = (orderId, updatedData) => {
  return {
    type: 'UPDATE_ORDER',
    payload: { orderId, updatedData },
  };
};
