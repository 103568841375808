import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchUrl } from '../Contsant';
import Spinner from './Spinner';
import BottomTab from './BottomTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { RWebShare } from 'react-web-share';

const Product = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [book, setBook] = useState(location.state?.book); 
  const [mainImage, setMainImage] = useState(book?.images[0]); // Set initial main image
  const [hasState, setHasState] = useState(true); 
  const [hasError,sethasError]=useState(false)
  const [bussinessData,setBusinessdata]=useState(location.state?.bussinessData)
  const getBooks=async()=>{
    setHasState(false);  // Update state after mount
    sethasError(false)
    fetch(`${fetchUrl}webfn/getproductById/${params.bookId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (res) => {
        const jsonRes = await res.json();
        if(jsonRes.statusCode==211)
        {
          setBook(jsonRes.product);
          sethasError(false)
        }
        else
        {
          sethasError(true)
        }
       

      })
      .catch((err) => {
        console.error('Error fetching product:', err);
       sethasError(true)

      });
  }
  useEffect(() => {
    if (!book) {
      setHasState(false);
      sethasError(false);
      fetch(`${fetchUrl}webfn/getproductById/${params.bookId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then(async (res) => {
          const jsonRes = await res.json();
          if (jsonRes.statusCode == 211) {
            setBook(jsonRes.product);
            setMainImage(jsonRes.product.images[0]); // Update main image on fetch
            sethasError(false);
            setBusinessdata(jsonRes.getBusiness)
          } else {
            sethasError(true);
          }
        })
        .catch((err) => {
          console.error('Error fetching product:', err);
          sethasError(true);
        });
    }
  }, [book, params.bookId]);

  const handleThumbnailClick = (image) => {
    setMainImage(image);
  };

  return (
    <div style={styles.container}>
      {hasError ? (
        <div style={styles.errorContainer}>
          <h3>Something went wrong. Please try again.</h3>
          <button style={styles.retryButton} onClick={() => getBooks()}>Retry</button>
        </div>
      ) : !book ? (
        <Spinner />
      ) : (
        <div style={{ marginBottom: 60 }}>
          {hasState && (
            <div style={styles.header}>
              <button style={styles.backButton} onClick={() => navigate(-1)}>
                &larr; Back to Store
              </button>
            </div>
          )}
          <div style={styles.bookContainer}>
            <img src={mainImage} alt={book.title} style={styles.mainImage} />
            <div style={styles.thumbnailContainer}>
              {book.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  style={styles.thumbnail}
                  onClick={() => handleThumbnailClick(image)}
                />
              ))}
            </div>
            <div style={styles.bookDetails}>
              <h2 style={styles.bookTitle}>{book.title}</h2>
              <p style={styles.bookAuthor}>by {book.author}</p>
              <p style={styles.bookGenre}>Genre: {book.genre}</p>
              <p style={styles.bookDescription}>{book.description}</p>
              <p style={styles.bookPrice}>Price: Rs.{book.price}</p>
              <Link to={`/purchase/${book._id}`} state={{ book: book,bussinessData:bussinessData }}>
                <button style={styles.buyButton}>Buy Now</button>
              </Link>
              <RWebShare
                data={{ url: `https://justscroll.in/product/${params.bookId}`, title: "Justscroll Product Link" }}
                onClick={() => console.log("Shared successfully!")}
              >
                <FontAwesomeIcon icon={faShare} style={styles.shareIcon} />
              </RWebShare>
            </div>
          </div>
        </div>
      )}
      <BottomTab />
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '500px',
    width: '100%',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    position: 'fixed',
    top: 0,
    width: '100%',
    padding: 10,
  },
  backButton: {
    padding: '8px 12px',
    backgroundColor: '#fe4040',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.9rem',
    marginBottom: '20px',
  },
  bookContainer: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '15px',
    textAlign: 'center',
    marginTop: '60px',
  },
  mainImage: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    marginBottom: '15px',
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '15px',
  },
  thumbnail: {
    width: '60px',
    height: '60px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
  },
  bookDetails: {
    textAlign: 'left',
  },
  bookTitle: {
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '5px',
  },
  bookAuthor: {
    fontSize: '1rem',
    color: '#666',
    marginBottom: '10px',
  },
  bookGenre: {
    fontSize: '0.9rem',
    color: '#777',
    marginBottom: '10px',
    fontStyle: 'italic',
  },
  bookDescription: {
    fontSize: '1rem',
    color: '#444',
    marginBottom: '10px',
  },
  bookPrice: {
    fontSize: '1.2rem',
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  buyButton: {
    padding: '12px 20px',
    backgroundColor: '#fe4040',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    transition: 'background-color 0.3s',
  },
  shareIcon: {
    marginLeft: '20px',
    cursor: 'pointer',
    color: '#fe4040',
    fontSize: '1.2rem',
  },
};

export default Product;
