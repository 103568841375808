import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Spinner from './Spinner'
import { fetchUrl } from '../Contsant'

const SendEmailToInfluencer = () => {
  const userdata=useSelector(state=>state.UserData)
    const[isAdmin,setIsAdmin]=useState(false)
    const [isLogging,setisLogging]=useState(false)
    const [email,setEmail]=useState("")
    const [subject,setsubject]=useState("")
    const [body,setBody]=useState("")
    const [haserror,setHasError]=useState({error:false,errormsg:'',status:'FAILURE'})



    useEffect(()=>{
     // console.log(userdata?.data[0]?.username)

        if( userdata?.data[0]?.username=="justscroll")
        {
            setIsAdmin(true)
        }
        else
        {
            setIsAdmin(false)
        }
      
    },[userdata])
    const sendEmailToInfluencerfn=async()=>{
      setisLogging(true)
      fetch(`${fetchUrl}adminAll/sendNotificationInfluencer`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({email,subject,body}),
        }).then(async res => { 
          try {
          const jsonRes = await res.json();
          switch (jsonRes.statusCode) {
            case 414:
          setisLogging(false)
          setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
             
  
              break;
              case 404:
        setisLogging(false)
        setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
            
  
              break;
            case 211:
        setisLogging(false)
        setHasError({error:true,errormsg:'Email Was Sent Sucessfully!'})
              
            
               break;
             default:
        setisLogging(true)
        setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
             
  
              break;
           }
            }catch (err) {
              setisLogging(true)
              setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
  
            }
          }).catch((err)=>{
        setisLogging(false)
        setHasError({error:true,errormsg:'Something Went Wrong Try Again Later!'})
              
          })
    }
    
  return (
    <div style={{display:'flex',flex:1,flexDirection:'column',marginBottom:50}}>{!isAdmin?"Only Admin Can Access This Page."
      :<div style={{display:'flex',flexDirection:'column',maxWidth:'500px',alignSelf:'center'}}>
        <h3>Welcome To Justscroll Influencer Email.</h3>
        {haserror?.error?<p style={{display:'flex',alignSelf:'center',
          color:'white',padding:10,fontSize:14,backgroundColor:'#6e0c71',width:'80%',justifyContent:'center'}}>{haserror.errormsg}</p>:""}
        <input placeholder='Influencer Email' 
         type='email'style={{width:'100%',height:20,border:'1px solid black',outline:'none',color:'#333333',marginBottom:10}} 
         onChange={(e)=>{setEmail(e.target.value)}}/>
  
        <input placeholder='Subject'  type='text'  onChange={(e)=>{setsubject(e.target.value)}}
          style={{width:'100%',height:20,border:'1px solid black',outline:'none',color:'#333333',marginBottom:10}} />
  
        <textarea placeholder='Email Body'  onChange={(e)=>{setBody(e.target.value)}}
         style={{width:'100%',height:100,border:'1px solid black',outline:'none',color:'#333333',marginBottom:10}} />
        <div style={{display:'flex',padding:10,
          flexDirection:'column',alignItems:'center',marginBottom:20}}>
         <button onClick={()=>{sendEmailToInfluencerfn()}}
             style={{width:'50%',maxWidth:300,padding:10,backgroundColor:"#66003a",border:'1px solid white',
              borderRadius:20,color:'white',textAlign:'center',}}
             disabled={isLogging}>{isLogging?<Spinner />:'SEND MAIL'}</button>
           
         </div>
        </div>}</div>
  )
}
export default SendEmailToInfluencer