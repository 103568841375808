import React from 'react'
import IMG1 from'../image/inf.jpg'
import Header from './Header'
import { NavLink } from 'react-router-dom'

const EventInfluence = () => {
  return (
<div style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
  <Header />
  <div style={{
    display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40, maxWidth: '600px', 
    margin: '60px auto', padding: '20px' , boxShadow: '0 4px 12px rgba(0,0,0,0.1)', borderRadius: '10px', backgroundColor: '#fff'
  }}>
    <img src={IMG1} alt="Avatar" style={{ width: '100%', borderRadius: '10px' }} />

    <h3 style={{
      padding: '10px', fontSize: '24px', fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: '#333', textAlign: 'center'
    }}>
      Host exclusive JustScroll events and connect with a passionate community!
    </h3>

    <p style={{
      padding: '10px', textAlign: 'left', fontWeight: 500, fontFamily: 'Poppins, sans-serif', color: '#555'
    }}>
      Hosting events on JustScroll offers influencers a unique opportunity to boost earnings through a lucrative revenue-sharing model,
      enhance their credibility by curating top-tier competitions, and engage deeply with their audience. With exclusive hosting rights 
      and significant voting influence, influencers can shape the outcomes and spotlight their expertise, all while building their brand 
      and connecting with new followers.
    </p>

    <NavLink to="/eventdetail" style={{
      fontSize: '20px', fontWeight: 700, color: '#fe4040', textAlign: 'center', marginTop: '15px', marginBottom: '20px'
    }}>
      What is an Event?
    </NavLink>

    <h3 style={{ padding: '10px', fontSize: '22px', fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: '#333' }}>
      Why Should Influencers Host Their Own Events with JustScroll?
    </h3>

    <p style={{ padding: '10px', fontSize: '18px', fontWeight: '500', color: '#333' }}>
      1. Lucrative Revenue Sharing:
    </p>
    <ul style={{ padding: '0 30px', margin: 0, color: '#555' }}>
      <li>Significant Earnings: Influencers will receive 60% of the revenue generated from the event, providing a substantial income opportunity.</li>
      <li>Monetize Your Influence: Engage your existing follower base in exclusive, high-profile events.</li>
    </ul>

    <p style={{ padding: '10px', fontSize: '18px', fontWeight: '500', color: '#333' }}>
      2. Enhanced Credibility:
    </p>
    <ul style={{ padding: '0 30px', margin: 0, color: '#555' }}>
      <li>Position as an Industry Leader: Hosting top-tier competitions elevates your status and credibility.</li>
      <li>Expert Authority: Your 30% voting rights demonstrate your expertise in determining winners.</li>
    </ul>

    <p style={{ padding: '10px', fontSize: '18px', fontWeight: '500', color: '#333' }}>
      3. Active Participation and Engagement:
    </p>
    <ul style={{ padding: '0 30px', margin: 0, color: '#555' }}>
      <li>Direct Interaction: Engage deeply with creators and audiences, building stronger relationships with followers.</li>
      <li>Community Building: Create a vibrant community around your niche.</li>
    </ul>

    <p style={{ padding: '10px', fontSize: '18px', fontWeight: '500', color: '#333' }}>
      4. Exclusive Opportunities:
    </p>
    <ul style={{ padding: '0 30px', margin: 0, color: '#555' }}>
      <li>Customizable Events: Tailor events to reflect your personal brand.</li>
      <li>Sponsored Content: Partner with brands for additional revenue and promotional opportunities.</li>
    </ul>

    <p style={{ padding: '10px', fontSize: '18px', color: '#555' }}>
      Join JustScroll and host your own events to unlock new revenue streams, build your brand, and connect with a passionate community.
    </p>

    <h2 style={{ padding: '10px', fontSize: '22px', color: '#333', fontFamily: 'Poppins, sans-serif', fontWeight: 'bold' }}>
      Ready to Host Your Own Event?
    </h2>

    <NavLink to="/contactus" style={{
      fontSize: '20px', fontWeight: 'bold', color: 'white', backgroundColor: '#fe4040', padding: '12px 24px',
      borderRadius: '10px', textDecoration: 'none', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)'
    }}>
      Contact Us!
    </NavLink>
  </div>
</div>

  )
}

export default EventInfluence