import React from 'react'
import '../css/header.css';
import '../css/globalstyle.css';
import IMG from'../image/logo-trans.png'


import { useState, useTransition } from 'react';
import { NavLink } from "react-router-dom";
import { faBars, faCaretSquareDown, faClose, faHome, faMessage, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Headerstore = (param) => {
  const [isOpne,setIsOpen]=useState("none")
  const [modalVisible, setModalVisible] = useState(false);
  const [showList,setshowList]=useState("none")
  const transitions = useTransition(modalVisible, null, {
    from: { opacity: 0, transform: "translateY(-40px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(-40px)" }
  });
  const showListCon=()=>{
    if(showList=="none")
    {
      setshowList("flex")
    }
    else
    {
      setshowList("none")
    }
  }
return (
 

  <div className='appHeader1' style={{paddingTop:'10px'}}>
    <NavLink to={`/userprofile/${param.userData?.username}`} style={{ textDecoration: 'none',display:'flex',flexDirection:'row',
        justifyContent:'center',alignItems:'center' }}>
    {param.userData?.profilePic?
      <img 
      src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${param.userData?.profilePic}`}
      className='applogoother' style={{ height: 40, borderRadius:20,width:40 }}  />
    :  <img src={IMG} className='applogoother' style={{ height: 40 }} />}
     <p style={{fontSize:14,fontWeight:600,color:'#fe4040',width:200,whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{param.userData?.username}'s E-Store</p>
    </NavLink>
    
    {
      isOpne === "none" ?
        <FontAwesomeIcon icon={faBars} style={{
          fontSize: 26, color: '#333', cursor: 'pointer',marginRight:10,
        }} onClick={() => {
          setIsOpen("block");
          setModalVisible(true);
        }} /> :
        <FontAwesomeIcon icon={faClose} style={{
          fontSize: 26, color: '#333', cursor: 'pointer',marginRight:10,
        }} onClick={() => {
          setIsOpen("none");
          setModalVisible(false);
        }} />
    }
  
    <div className="sideNavigation" style={{
      display: isOpne, position: 'absolute', top: '56px', left: 0, width: '100%', background: '#2f2c33',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)', zIndex: 999, transition: '0.4s ease', padding: '20px 0px',height:'100vh',
    }}>
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {[
       
           { label: "Read Business Return Policy", link: "",iconname:faCaretSquareDown },
           { label: "Rate Bussiness", link: "",iconname:faStar },
          
         
         
         
         
        
        
        ].map((item, index) => (
          <NavLink key={index} to={item.link} style={{
            textDecoration: 'none', color: 'white', fontSize: 16,
            padding: '10px 10px', borderBottom: '1px solid #ddd',display:'flex',flexDirection:'row',alignItems:'center'
          }}>
            <FontAwesomeIcon icon={item.iconname} color='white' />
            <span style={{ marginLeft:15,}}>{item.label}</span>
          </NavLink>
        ))}
      </div>
    </div>
  </div>
  
       
         
        
    
  


);
}
export default Headerstore
