import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUrl } from '../Contsant'
import { setError, setUserData } from '../Redux/actions/UserData'
import badge from '../image/playbadge.png'
import BottomTab from './BottomTab'
import { NavLink, useNavigate } from 'react-router-dom'
import IMG from'../image/logo-trans.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import Spinner from './Spinner'
import Eventdata from './Eventdata'
import { width } from '@fortawesome/free-solid-svg-icons/faAdd'


export const Profile = () => {
  const userData=useSelector(state=>state.UserData)
  const accessToken=useSelector(state=>state.Auth)
  const dispatch=useDispatch()
  const [hasData,setHasData]=useState(false)
  const [isLoading,setIsLoading]=useState(false)
  const forward=useSelector(state=>state.Forward)
  const[eventData,setEventData]=useState([])
 

  const navigate=useNavigate(0)
  useEffect(()=>{
    if(forward.forwardTo)
      {
        if(forward.searchParms)
          {
          
           navigate(`/${forward.forwardTo}/${forward.data}?${forward.searchParms}`)
          }
          else
          {
           navigate(`/${forward.forwardTo}/${forward.data}`)
          }
      }
  },[forward])
  useEffect(()=>{
    if(userData?.data[0]?.isInfluencer)
    {
      fetch(`${fetchUrl}webfn/getAllEventForInfluencer`, {
        method: 'get',
        headers: {
        'Content-Type': 'application/json',
        'authorization': accessToken.accessToken
        },
      
        })
        .then(async(res)=>{
          const jsonRes = await res.json();
          switch (jsonRes.statusCode) {
            case 211:
              setEventData(JSON.parse(jsonRes.data))
            default:
              return
          }
        }).catch(err=>{
          
          dispatch(setError())
        })
    }
  },[userData])
  const fetchProfiledata=async()=>{
   
        setIsLoading(true)
        fetch(`${fetchUrl}webfn/getUserData`, {
          method: 'get',
          headers: {
          'Content-Type': 'application/json',
          'authorization': accessToken.accessToken
          },
        
          })
          .then(async(res)=>{
            const jsonRes = await res.json();
            switch (jsonRes.statusCode) {
              case 211:
                    console.log(JSON.parse(jsonRes.userData))
                    dispatch(setUserData(JSON.parse(jsonRes.userData)))
                    setIsLoading(false)
                    setHasData(true)
              default:
                setIsLoading(false)
              dispatch(setError())
            }
          }).catch(err=>{
            setIsLoading(false)
            dispatch(setError())
          })
     
  }
  useEffect(()=>{
    if(userData.data.length!==0 )

      {
        setHasData(true)
      }
    
  },[userData])
  return (
<div style={{display:'flex',flex:1,flexDirection:'column',marginBottom:50,}}>
  {/* Loading/Error States */}
  {userData.data.length === 0 && !hasData ? (
    userData.hasError ? (
      <div style={styles.errorContainer}>
        <h3>Something went wrong. Please try again.</h3>
        <button style={styles.retryButton} onClick={fetchProfiledata} disabled={isLoading}>
          Retry
        </button>
      </div>
    ) : (
      <Spinner width={40} />
    )
  ) : (
    <>
      {/* Profile Section */}
      <div style={styles.profileContainer}>
        {/* Cover Image */}
        <div style={styles.coverImageContainer}>
          {userData.data[0]?.coverPic ? (
            <img
              src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/coverImage/${userData.data[0].coverPic}`}
              style={styles.coverImage}
              alt="Cover"
            />
          ) : (
            <div style={styles.coverPlaceholder}></div>
          )}
        </div>

        {/* Profile Image */}
        <div style={styles.profileImageWrapper}>
          <img
            src={userData.data[0]?.profilePic
              ? `https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${userData.data[0].profilePic}`
              : IMG}
            style={styles.profileImage}
            alt="Profile"
          />
        </div>

        {/* Username & Edit Profile */}
        <div style={styles.userInfo}>
          <h2 style={styles.username}>
            @{userData.data[0].username}
            {userData.data[0]?.isInfluencer ? (
              <FontAwesomeIcon icon={faCheckSquare} style={styles.influencerIcon} />
            ) : userData.data[0]?.isCreator ? (
              <FontAwesomeIcon icon={faCheckSquare} style={styles.creatorIcon} />
            ) : null}
          </h2>
          <p style={styles.name}>{userData.data[0].name}</p>
          <NavLink to="/editprofile" style={styles.editProfileLink}>
            <button style={styles.editProfileButton}>Edit Profile</button>
          </NavLink><br></br>
          <NavLink to="/addProduct" style={styles.editProfileLink}>
            <button style={styles.editProfileButton}>Add Product</button>
          </NavLink>
        </div>

        {/* Bio Section */}
        <p style={styles.bio}>{userData.data[0].bio}</p>

        {/* Stats Section */}
        <div style={styles.statsContainer}>
          <div style={styles.statItem}>
            <span>{userData.data[0].scrollCount}</span><br></br>
            <span>Posts</span>
          </div>
          <div style={styles.statItem}>
            <span>{userData.data[0].followerCount}</span><br></br>
            <span>Followers</span>
          </div>
          <div style={styles.statItem}>
            <span>{userData.data[0].followingCount}</span><br></br>
            <span>Following</span>
          </div>
        </div>

        {/* Event Data */}
        <div style={styles.eventContainer}>
          {eventData.length > 0 ? (
            eventData.map((item) => (
              <div style={styles.eventItem} key={item._id}>
                <Eventdata data={item} />
              </div>
            ))
          ) : (
            <p style={styles.noEventsMessage}>
              You can add and access your content through the Justscroll App.
            </p>
          )}
          <a href="https://play.google.com/store/apps/details?id=com.justscroll" target="_blank" rel="noopener noreferrer">
            <img src={badge} alt="App Badge" style={styles.appBadge} />
          </a>
        </div>
      </div>

      {/* Bottom Tab */}
      <BottomTab />
    </>
  )}
</div>
  )
}
const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 50,
  },
  errorContainer: {
    display: 'flex',
    marginTop: 60,
    maxWidth: '500px',
    alignSelf: 'center',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginBottom: 60,
  },
  retryButton: {
    width: '30%',
    height: 30,
    padding: 15,
    backgroundColor: '#fe4040',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: '500px',
    textAlign: 'center',
   
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  coverImageContainer: {
    width: '100%',
    height: '150px',
  },
  coverImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px 10px 0 0',
  },
  coverPlaceholder: {
    width: '100%',
    height: '150px',
    backgroundColor: '#fe4040',
  },
  profileImageWrapper: {
    marginTop: '-40px',
    borderRadius: '50%',
    overflow: 'hidden',
    width: '80px',
    height: '80px',
    border: '3px solid #fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  profileImage: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  userInfo: {
    marginTop: '10px',
  },
  username: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  influencerIcon: {
    color: 'orange',
    marginLeft: '5px',
  },
  creatorIcon: {
    color: 'violet',
    marginLeft: '5px',
  },
  name: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
  editProfileLink: {
    marginTop: '10px',
     
  },
  editProfileButton: {
    backgroundColor: '#fe4040',
    color: 'white',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    marginTop:10,
  },
  bio: {
    marginTop: '15px',
    fontSize: '14px',
    color: '#666',
    fontFamily:'Nunito',
    fontWeight:700
  },
  statsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    padding:'10px 0',
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
  },
  statItem: {
    textAlign: 'center',
  },
  eventContainer: {
    display:'flex',
    marginTop: '30px',
    textAlign: 'center',
    width:'100%',
    flexDirection:'column'

  },
  eventItem: {
    display:'flex',
    marginBottom: '20px',
    alignSelf:'center',
    flexDirection:'column'

  },
  noEventsMessage: {
    color: '#fe4040',
    marginBottom: '10px',
  },
  appBadge: {
    width: '70%',
  },
};