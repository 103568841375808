export const userLogin=(accessToken,googleToken)=>(dispatch)=>{
    return dispatch({
     accessToken:accessToken,
     googleToken:googleToken,
     type:"LOGIN"
    })
}
export const Logout=()=>(dispatch)=>{
    return dispatch({
     type:"LOGOUT"
    })
}
