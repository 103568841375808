const initialState ={comment:[]}
   
   export const Comment = (state = initialState, action) => {
       switch (action.type) {
             case "SETCOMMENTPOST":
                return{
                    ...state,
                    comment:action.comment
                }
                case "UPDATECOMMENTPOST":
                    
                    return{
                        ...state,
                        comment: [action.userdata,...state.comment]
                    }
                    case "EMPTYCOMMENTPOST":
                return{
                    ...state,
                    comment:[]
                }
            default:
               return state;
       }
   }
  
   