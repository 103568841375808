import React, { useEffect, useState } from 'react'
import Header from './Header'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Spinner from './Spinner'
import { fetchUrl } from '../Contsant'

const Changepassword = () => {
  const [username,setusername]=useState(null)
  const location=useLocation()
  const navigate=useNavigate()
  const [newpassword,setNewPassword]=useState("")
  const [code,setCode]=useState("")

  const [hasError,setHasError]=useState(false)
  const [errorMessage,seterrorMessage]=useState("")
  const [shouldShowPassword,setshouldShowPassword]=useState(true)
  const [isChangingPassword,setisChangingpassword]=useState(false)

    useEffect(()=>{
      if(location.state==null)
       {
         navigate("/profile")
       }
       else
       {
         
         setusername(location.state?.username)
         setCode(location.state?.code)

         
  
       }
   },[])
   const changeUserPassword=()=>{
    setisChangingpassword(true)
    setHasError(false)
    const payload = {
      username:username,
      code:code,
      password:newpassword
  };
  fetch(`${fetchUrl}auth/changePassword`, {
   method: 'POST',
   headers: {
       'Content-Type': 'application/json',
   },
   body: JSON.stringify(payload),
})
.then(async res => { 
   try {
       const jsonRes = await res.json();
       if(jsonRes.statusCode==200)
       {
        setisChangingpassword(false)
        setHasError(true)
        seterrorMessage("Your password was sucessfully changed, please login to continue!")
       }
       else
       {
        setHasError(true)
        seterrorMessage(jsonRes.message)
        setisChangingpassword(false)

       }
   
   } catch (err) {
    setisChangingpassword(false)

    setHasError(true)
    seterrorMessage("Something went wrong, try again later!")

      
   };
})
.catch(err => {
  setisChangingpassword(false)

  setHasError(true)
  seterrorMessage("Something went wrong, try again later!")
 
});
 
  }
  return ( 
    <div style={{display:'flex',flex:1,flexDirection:'column',}}>
      <Header />
      <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 30, 
      maxWidth: 500, width: '100%', margin: '50px auto', boxSizing: 'border-box', 
      backgroundColor: '#f7f7f7', borderRadius: 10, boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      minHeight: '100vh'
    }}>
     
     <p style={{
        color: '#333', fontFamily: 'Poppins', fontSize: 32, fontWeight: 'bold',
        textAlign: 'center', margin: '20px 0',
      }}>Update Your Password!</p>
    
    { hasError&& (
        <p style={{
          color: 'white', backgroundColor: '#d9534f', padding: 10, margin: '10px 0',
          textAlign: 'center', borderRadius: 10
        }}>{errorMessage}</p>
      )}
     <div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:12,padding:8}}>
       <input type='password' placeholder='Enter new password 'style={{
            width: '100%', border: 'none', outline: 'none', color: '#333', 
            padding: 12, fontSize: 16, height: 20
          }}
          onChange={(e)=>{setNewPassword(e.target.value)}}/>
       </div>
       <div style={{display:'flex',padding:10,flexDirection:'column',alignItems:'center',marginBottom:20}}>
     <button onClick={()=>{changeUserPassword()}}
        style={{
          padding: 12, backgroundColor: '#4db6ac', color: 'white', borderRadius: 10,
          border: 'none', width: '80%', margin: '20px auto', cursor: 'pointer',
          maxWidth: 400, textAlign: 'center', fontSize: 16, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
          transition: 'all 0.3s ease-in-out'
        }}>
{isChangingPassword?<Spinner />:'Update Password'}</button>
        
      </div>
     </div></div>
  )
}

export default Changepassword