const initialState = {
    muted:true,
    
   
   }
   
   export const Mute = (state = initialState, action) => {
       switch (action.type) {
           case "UNMUTE":
               return {
                   ...state,
                  muted:false
               }
               case "MUTE":
                  
                  return {
                      ...initialState
                  }
            
           default:
               return state;
       }
   }
  
  