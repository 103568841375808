const initialState = {
    eventPost:[],
    isVoted:false,
    selectedPost:[],
    eventId:null,
    isLoggedIn:false,
    eventData:null

   
   }

   export const EventPost = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATEEVENTPOST":
            return {
                ...state,
                eventPost:action.eventPost,
               isVoted:action.isVoted,
               selectedPost:state.selectedPost,
               eventId:action.eventId,
               isLoggedIn:action.isLoggedIn,
               eventData:action.eventData
            }
         case "UPDATESELECTIONPOST":
            let eventdata=state.eventPost.map((item)=>{
                if(item._id==action.eventId){
                const newPropsObj = {
                  isScelected:true
                  
                  };
                   return Object.assign(item,newPropsObj);
                }
                else
                {
                    return  item
      
                }})
                return {
                    ...state,
                    eventPost:eventdata,
                   isVoted:state.isVoted,
                   selectedPost:[...state.selectedPost,action.eventPost],
                   eventId:state.eventId,
               eventData:state.eventData

                }
                case "DISSELECTPOST":
            let postData=state.eventPost.map((item)=>{
                if(item._id==action.eventId){
                const newPropsObj = {
                  isScelected:false
                  
                  };
                   return Object.assign(item,newPropsObj);
                }
                else
                {
                    return  item
      
                }})
                let selectedPostArray=state.selectedPost.filter((item)=> item._id!==action.eventId);
                return {
                    ...state,
                    eventPost:postData,
                   isVoted:state.isVoted,
                   selectedPost:selectedPostArray,
                   eventId:state.eventId,
                   eventData:state.eventData

                }
                case "VOTE":
                    let voteData=state.eventPost.map((item)=>{
                        if(item.isScelected){
                        const newPropsObj = {
                          hasVoted:[true]
                          
                          };
                           return Object.assign(item,newPropsObj);
                        }
                        else
                        {
                            return  item
              
                        }})
                        return {
                            ...state,
                            eventPost:voteData,
                           isVoted:true,
                           selectedPost:[],
                           eventId:state.eventId,
                         eventData:state.eventData

                        }
         
         
        default:
            return state;
    }
}

   