import { faBarChart, faEye, faVoteYea } from '@fortawesome/free-solid-svg-icons'
import { faAdd } from '@fortawesome/free-solid-svg-icons/faAdd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

function Eventdata(params) {
const [showanalytics,setshowanalytics]=useState(false)
return (
<div style={{display:'flex',width:'100%',maxWidth:'500px',textAlign:'center',flexDirection:'column',alignSelf:'center'}}>
{!showanalytics?
<div style={{display:'flex',alignSelf:'center',width:'100%',maxWidth:'500px',
minHeight:100,textAlign:'center',height:150}}>

<img  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/banner/${ params.data.banner}`}
style={{maxWidth:'400px',aspectRatio:'16/9',width:'100%',borderRadius:10,}}/>
</div>:<div style={{display:'flex',alignSelf:'center',width:'95%',maxWidth:'400px',
minHeight:100,textAlign:'center',height:150,justifyContent:'center',alignItems:'center'}}>
<div style={{display:'flex',flexDirection:'column',textDecoration:'none',color:'black',marginLeft:10}} >
<FontAwesomeIcon icon={faAdd}   style={{fontSize:20,color:'black'}}

/>
<span style={{fontSize:10,fontWeight:'700',marginTop:8}}>Total Entry</span>
<span style={{fontSize:12,fontWeight:'700',marginTop:8}}>{params.data.totalEntry}</span>

</div>
<div style={{display:'flex',flexDirection:'column',textDecoration:'none',color:'black',marginLeft:10}} >
<FontAwesomeIcon icon={faEye}   style={{fontSize:20,color:'black'}}

/>
<span style={{fontSize:10,fontWeight:'700',marginTop:8}}>Total View</span>
<span style={{fontSize:12,fontWeight:'700',marginTop:8}}>{params.data.totalUniqueView}</span>

</div>

<div style={{display:'flex',flexDirection:'column',textDecoration:'none',color:'black',marginLeft:10}} >
<FontAwesomeIcon icon={faVoteYea}   style={{fontSize:20,color:'black'}}

/>
<span style={{fontSize:10,fontWeight:'700',marginTop:8}}>Total Votes</span>
<span style={{fontSize:12,fontWeight:'700',marginTop:8}}>{params.data.totalvote}</span>

</div>

</div>}


<div style={{display:'flex',flexDirection:'row',
maxWidth:'400px',width:'90%',marginTop:10,alignSelf:'center'}}>
<div style={{display:'flex',flexDirection:'column',textDecoration:'none',color:'black'}} >
<FontAwesomeIcon icon={faEye}   style={{fontSize:20,color:'black'}}

/>
<span style={{fontSize:10,fontWeight:'700',marginTop:8}}>ENTRY</span>
</div>
<div style={{display:'flex',flexDirection:'column',textDecoration:'none',color:'black',marginLeft:10}} onClick={()=>{
setshowanalytics(!showanalytics)
}}>
<FontAwesomeIcon icon={faBarChart}   style={{fontSize:20,color:'black'}}

/>
<span style={{fontSize:10,fontWeight:'700',marginTop:8}}>ANALYTICS</span>
</div>
</div>
</div>
)
}

export default Eventdata