import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import Spinner from './Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { fetchUrl } from '../Contsant'
import '../css/vote.css';
import IMGLOGO from'../image/logo-trans.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faComment, faEye, faL, faQuestion, faShare, faTag, faVoteYea } from '@fortawesome/free-solid-svg-icons'
import { disselectPost, updateEventPost, updateSelection, votePost } from '../Redux/actions/EventPost'
import '../css/header.css';
import IMG from'../image/logo-trans.png'


import { RWebShare } from 'react-web-share'
import LazyImage from './LazyImage'
import VotingDetails from './VotingDetails'
import { updateForward } from '../Redux/actions/Forward'
import LoginUI from './LoginUI'
import CommentSection from './CommentSection'
import LazyImageEvent from './LazyImageEvent'
import HowToVote from './HowToVote'
import { setError } from '../Redux/actions/UserData'

function Vote() {
  const [showLoginUi,setLoginUi]=useState(false)
  const [isFetchingdata,setIsFetchingData]=useState(false)
  const [hasError,sethasError]=useState(false)
const eventId=useParams()
const isMounted = useRef(false)
const [eventData,setEventdata]=useState([])
const secretKey = 'justscrollsecreat1998bynikhil';
let accessToken=useSelector(state=>state.Auth)
let eventPost=useSelector(state=>state.EventPost)
const dispatch=useDispatch()
const navigate=useNavigate()
//const [isSelected,setIsSelected]=useState(false)
const [isSelected,_setisSelected]=useState(false)
const isSelectedRef=useRef(null)
const isEmailNotVerified=useSelector(state=>state.UserData)
const [emailVeriifcationError,setEmailVerificationerror]=useState(false)
const setIsSelected= data => {
  isSelectedRef.current = data;
  _setisSelected(data);
};
const [eventStatus,setEventStatus]=useState("VOTE")

const [mentiontap,setMentionTap]=useState(false)
const [shallVote,setShallVote]=useState(false)
const [searchParms,setSerchParams]=useSearchParams()
const [isVoting,setIsVoting]=useState(false)
const [showHowTo,setShowHowTo]=useState(false)
const [shouldretry,setShouldRetry]=useState(false)
//const[uuidhh,setuuidhh]=useState("")
//const[secreatData,setsecretData]=useState("")
const uuidhh=useRef()
const secreatData=useRef()
const [isLocalDataFetched, setIsLocalDataFetched] = useState(false);
const [showComment,setshowComment]=useState(false)
const [currentEventID,setcurrentEventId]=useState(null)
const totalVotes=3;
const [isPopup1visi,setisPopup1visi]=useState(false)
const [selectionError,setSelectionerror]=useState(false)
const currentPostId=useRef(null)
const [overflow,setOverflow]=useState('auto')
useEffect(()=>{
 
  fetchLoacaldata()
  

return () => { isMounted.current = false }

},[])
useEffect(()=>{
  
  const handleBackButton = (event) => {
    event.preventDefault();
   
    window.history.pushState(null, '', window.location.href);

  };

  // Push a state to prevent the default back action
  window.history.pushState(null, '', window.location.href);
  
  // Add an event listener to handle back button
  window.addEventListener('popstate', handleBackButton);

  return () => {
    // Cleanup event listener on component unmount
    window.removeEventListener('popstate', handleBackButton);
  };
},[])
const fetchLoacaldata=async()=>{
  isMounted.current = true;
  const isFirstTime=localStorage.getItem("isFirstTime")
  const uuid4=localStorage.getItem("uuid")
  const secretString=localStorage.getItem("secreatString")
  if(isFirstTime==null)
  {
  setShowHowTo(true)
  localStorage.setItem("isFirstTime",false)
  }
  if(uuid4==null)
  {
    const getHmac=await stringGen()
    const uuidh=getHmac.hmac
    const secreatString=getHmac.data
    uuidhh.current=uuidh
    secreatData.current=secreatString
    //setuuidhh(uuidh)
   
    localStorage.setItem("uuid",uuidh)
    localStorage.setItem("secreatString",secreatString)
  }
  else
  {
    uuidhh.current=uuid4
    secreatData.current=secretString
  
  }
  setIsLocalDataFetched(true)
}
async function stringGen(){
  var text = "";
  
  var possible = "NIKHL_SCROL_034_1SDR_KG";

  for (var i = 0; i < 10; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  const encodeString = (text) => new TextEncoder().encode(text);

  const key = await crypto.subtle.importKey(
    'raw',
    encodeString(secretKey),
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  );
  const signature = await crypto.subtle.sign('HMAC', key, encodeString(text));
 const hmac=Array.from(new Uint8Array(signature))
  .map(b => b.toString(16).padStart(2, '0'))
  .join('');

  return {hmac:hmac,data:text};
}
const checkIfVotingLive=async(data)=>{
  const now=new Date().getTime();
    
  if(data.startdate>now)
    {
      
      setEventStatus("PARTICIPATE") 
    }
  else if(data.startdate<now && now<data.enddate)
    {
      setEventStatus( "VOTE")
    }
    else
    {
      setEventStatus( "WINNER")
    }
}
useEffect(()=>{
  if (!isLocalDataFetched) return;
  sethasError(false)
let timeout;

if (accessToken.accessToken) {
    if (eventPost.eventId === eventId.eventId && eventPost.eventPost.length && eventPost.isLoggedIn) {
        setEventdata(eventPost.eventPost);
        checkIfVotingLive(eventPost.eventData)
        timeout = setTimeout(() => {
            if (isMounted.current) {
                fetch(`${fetchUrl}webfn/updateView/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: accessToken.accessToken
                    },
                    body: JSON.stringify({ eventId, uuidhh: uuidhh.current, secreatData: secreatData.current })
                }).catch(err => console.error(err));
            }
        }, 3000);
    } else {
        const param = searchParms.get('postId');
        fetch(`${fetchUrl}webfn/getAllPostForEvent/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: accessToken.accessToken
            },
            body: JSON.stringify({ eventId: eventId.eventId, searchParms: param, uuidhh: uuidhh.current, secreatData: secreatData.current })
        })
            .then(async res => {
             const jsonRes = await res.json();

              if (jsonRes.statusCode === 211) {
                dispatch(updateEventPost(JSON.parse(jsonRes.data), jsonRes.hasVoted, eventId.eventId, true,JSON.parse(jsonRes.eventData)));
               checkIfVotingLive(JSON.parse(jsonRes.eventData))
                
                timeout = setTimeout(() => {
                    if (isMounted.current) {
                        fetch(`${fetchUrl}webfn/updateView/`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                authorization: accessToken.accessToken
                            },
                            body: JSON.stringify({ eventId, uuidhh: uuidhh.current, secreatData: secreatData.current })
                        }).catch(err => console.error(err));
                    }
                }, 5000);
              } else {
                  sethasError(true);
              }
                
            })
            .catch(err => {console.error(err);setIsFetchingData(false);sethasError(true);});
    }
} else {
    if (eventPost.eventId === eventId.eventId && eventPost.eventPost.length) {
        setEventdata(eventPost.eventPost);
        sethasError(false);
        checkIfVotingLive(eventPost.eventData)

        timeout = setTimeout(() => {
            if (isMounted.current) {
                fetch(`${fetchUrl}webfn/updateViewLogout/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ eventId, uuidhh: uuidhh.current, secreatData: secreatData.current })
                }).catch(err => console.error(err));
            }
        }, 5000);
    } else {
        const param = searchParms.get('postId');
        fetch(`${fetchUrl}webfn/getAllPostForEventLoggedOut/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ eventId: eventId.eventId, uuidhh: uuidhh.current, secreatData: secreatData.current, searchParms: param })
        })
            .then(async res => {
                const jsonRes = await res.json();
                if (jsonRes.statusCode === 211) {
                    dispatch(updateEventPost(JSON.parse(jsonRes.data), jsonRes.hasVoted, eventId.eventId, false,JSON.parse(jsonRes.eventData)));
                    checkIfVotingLive(JSON.parse(jsonRes.eventData))

                    timeout = setTimeout(() => {
                        if (isMounted.current) {
                            fetch(`${fetchUrl}webfn/updateViewLogout/`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({ eventId, uuidhh: uuidhh.current, secreatData: secreatData.current })
                            }).catch(err => console.error(err));
                        }
                    }, 5000);
                } else {
                    sethasError(true);
                }
            })
            .catch(err => {console.error(err);setIsFetchingData(false);sethasError(true);});
    }
}

return () => {
    clearTimeout(timeout);
};
},[eventPost,shouldretry,isLocalDataFetched])
const castVote=async()=>{

  const isScelectedPost=eventPost.selectedPost.map((item,index)=>{
  return {_id:item._id,creatorId:item.creatorId}
  })
  if(isScelectedPost.length==3)
  {
  
  setIsVoting(true)
      if(accessToken.accessToken){
        fetch(`${fetchUrl}webfn/castVote/`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          'authorization':accessToken.accessToken
          },
          body:JSON.stringify({isScelectedPost,eventId,uuidhh:uuidhh.current,secreatData:secreatData.current})
          })
          .then(async(res)=>{
          const jsonRes=await res.json()
          //JSON.parse(jsonRes.data))
          setIsVoting(false)
          if(jsonRes.statusCode==211)
          {
            setShallVote(false)
          dispatch(votePost())
          
          }
          
          
          //dispatch(updateEventPost(JSON.parse(jsonRes.data),false,eventId.eventId)) 
          
          //setEventdata()
          })
          .catch(err=>{
          setIsVoting(false)
          
          })
      }
      else
      {
        fetch(`${fetchUrl}webfn/castVoteLogout/`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          'authorization':accessToken.accessToken
          },
          body:JSON.stringify({isScelectedPost,eventId,uuidhh:uuidhh.current,secreatData:secreatData.current})
          })
          .then(async(res)=>{
          const jsonRes=await res.json()
          //JSON.parse(jsonRes.data))
          setIsVoting(false)
          if(jsonRes.statusCode==211)
          {
            setShallVote(false)
          dispatch(votePost())
          
          }
          
          
          //dispatch(updateEventPost(JSON.parse(jsonRes.data),false,eventId.eventId)) 
          
          //setEventdata()
          })
          .catch(err=>{
          setIsVoting(false)
          
          })
      }
  }
  
  else
  {
  setShallVote(false)
  }
  
  }
  useEffect(()=>{
    if(!isSelectedRef.current)
    {
      const element = document.getElementById(`${currentPostId.current}`);
     
     if(element)
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset-300,
        behavior: "smooth"
    });
    }
      
  },[isSelectedRef.current])
  useEffect(()=>{
    if(mentiontap)
    {
     
      const element = document.getElementById(`${currentPostId.current}`);
      console.log(element)
     if(element)
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset-100,
        behavior: "smooth"
    });
    }
  return ()=>{setMentionTap(false)}
  },[overflow,mentiontap])
  const disableScroll=async()=>{
    if(overflow=='auto')
    {
      setOverflow('hidden')

    }
    else
    {
      setOverflow('auto')

    }
  }
  return (
    <div style={{display:'flex',flexDirection:'column',zIndex:100,width:'100%',maxWidth:'500px',margin:'0px auto'}}>
    <div className='appHeader1' style={{display:'flex',alignItems:'center'}}>
    <NavLink  style={{textDecoration:'none',color:'#ff4040'}} to="/"> 
    <img src={IMG}  className='applogoother' /></NavLink>
    <div style={{display:'flex',flexDirection:'row'}}>
    {isSelectedRef.current?<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
    flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{
      setIsSelected(false);
      
     
      }}>
    <FontAwesomeIcon icon={faVoteYea} style={{fontSize:16,color:'black',}}/>
    <span style={{fontWeight:500,fontSize:12}}>Vote</span>
    </div>:
    eventPost?.selectedPost.length?<div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
    flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{setIsSelected(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scroll effect
    });
    }}>
    <FontAwesomeIcon icon={faEye} style={{fontSize:16,color:'black',}}/>
    <span style={{fontWeight:500,fontSize:12}}>Selection</span>
    </div>
    :""
    }
    <div style={{display:'flex',flexDirection:'row'}}>
      {accessToken.accessToken?
      <NavLink style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
        flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{
          setshowComment(!showComment)
          disableScroll()
        }}>
        <FontAwesomeIcon icon={faComment} style={{fontSize:16,color:'black',}}/>
        <span style={{fontWeight:500,fontSize:12}}>Comment</span>
        </NavLink>
        :""}
    
    <NavLink style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
    flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{
       setShowHowTo(true)
      disableScroll()

    }}>
    <FontAwesomeIcon icon={faQuestion} style={{fontSize:16,color:'black',}}/>
    <span style={{fontWeight:500,fontSize:12}}>How?</span>
    </NavLink>
    </div>
  
    
    </div>
    </div>
    {isFetchingdata && !hasError?
     <div style={{
      display: 'flex', marginTop:60, alignItems: 'center', padding: 20, flexDirection: 'column', minHeight: '80vh'
    }}>
    <Spinner width={20}/>
    </div>:hasError? <div style={styles.errorContainer}>
        <h3>Something went wrong. Please try again.</h3>
        <button style={styles.retryButton}  disabled={isFetchingdata} onClick={()=>{setShouldRetry(!shouldretry)}}>
          Retry
        </button>
      </div>: 
      <div>
       { eventStatus === "WINNER" ? (
            <div style={{
              display: 'flex', marginTop:60, alignItems: 'center', padding: 20, flexDirection: 'column', minHeight: '80vh'
            }}>
            <NavLink to={`/winner/${eventId.eventId}`} style={styles.navLink}>
              <p>Winners For This Event Have Already Been Declared!</p>
              <button style={styles.buttonGreen}>View Winner</button>
            </NavLink>
            </div>
          ) : eventStatus === "PARTICIPATE" ? (
            <div style={{
              display: 'flex', marginTop:60, alignItems: 'center', padding: 20, flexDirection: 'column', minHeight: '80vh'
            }}>
            <div style={styles.centeredContainer}>
              <p>Can't vote, this event is still accepting entry!.</p>
              <button style={styles.buttonVote}>
                <NavLink to={`/participate/${eventId.eventId}`} style={styles.navLinkText}>Join Now</NavLink>
              </button>
            </div>
            </div>
          ):<div style={{
            display: 'flex', alignItems: 'center',backgroundColor: '#f9f9f9', flexDirection: 'column', height: '100vh',width:'100%',
          
          }}>
            {eventPost.isVoted?<div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
      flexDirection:'column',width:'100%',alignItems:'center',marginBottom:shallVote?50:10,}}>
      {eventData.length?<div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
      flexDirection:'column',width:'100%',alignItems:'center'}}>
      {eventData.map((item,index)=>{
      
      if(item?.contentType=="ADVERT")
      {
      return(
      <a  href={item.actionLink}  target='_blank' className="card" style={{
      marginBottom:30,borderRadius:10,textDecoration:'none'}} key={item._id}>
      
      <LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/advertisement/${ item.banner}`} aspectRatio={'16/9'}/>
      
      <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
      width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
      }}  >
      {
      item.sponsorData[0].profilePic===undefined || item.sponsorData[0].profilePic===""?<img
      style={{width:36,height:36,borderRadius:18}}
      src={IMGLOGO}
      />:<img
      style={{width:36,height:36,borderRadius:18}}
      src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.sponsorData[0].profilePic}`}
      />
        
      }
        <div style={{display:'flex',flexDirection:'column',
      justifyContent:'center',marginLeft:10,marginTop:20}}>
      <span style={{textAlign:'left',fontSize:14,fontWeight:600,marginBottom:5}}>Sponsored
      <span style={{textAlign:'left',fontSize:16,fontWeight:400,color:'grey',marginLeft:5}}>
      {item.sponsorData[0].username}</span></span>
      <span style={{textAlign:'left',fontSize:14,marginBottom:2}}>
      {item.title}
      </span>
      <span style={{textAlign:'left',color:'grey',fontSize:14}}>
      {item.discription}
      </span>
      </div>
      
      </div>
      
      </a>
      )
      }
      else
      {
      return(
      <div className="card" style={{marginBottom:30,borderRadius:10,}} key={item._id}>
        <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
      width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
      }}  >
      {
      item.creatorData[0].profilePic===undefined || item.creatorData[0].profilePic===""?<img
      style={{width:36,height:36,borderRadius:18}}
      src={IMGLOGO}
      />:<img
      style={{width:36,height:36,borderRadius:18}}
      src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.creatorData[0].profilePic}`}
      />
      
      }
      <span style={{textAlign:'left',fontSize:14,marginLeft:10}}>{item.creatorData[0].username}</span>
      </div>
      
      <LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${ item.fileUrl}`} aspectRatio={1}/>
      
      <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
      width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
      }}  >
      {item?.hasVoted?.length?<div style={{display:'flex',flexDirection:'row'}}>
      <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
      flexDirection:'column',textDecoration:'none',color:'black'}} >
      <FontAwesomeIcon icon={faVoteYea} style={{fontSize:20,color:'green',}}/>
      <span style={{fontWeight:500,fontSize:14,color:'green'}}>Voted</span>
      
      </div>
      <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
      flexDirection:'column',textDecoration:'none',color:'black'}} >
      <RWebShare 
      data={{
       url: `https://justscroll.in/vote/${eventId.eventId}?postId=${item._id}`,
      title: "Event Post Share Link",
      }}
      onClick={() => console.log("shared successfully!")}
      >
      <FontAwesomeIcon icon={faShare} style={{fontSize:20,color:'black',}}/>
      
      </RWebShare>
      <span style={{fontWeight:500,fontSize:14,color:'black'}}>Share</span>
      </div>
      <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'flex-end',
      flexDirection:'column',textDecoration:'none',color:'black',fontSize:16,fontWeight:800}} >
       <span style={{fontWeight:800,fontSize:14,color:'black'}}>{item.totalVote}</span>
        <span style={{fontWeight:500,fontSize:14,color:'black'}}> Votes</span>
        </div>
      </div>:<div style={{display:'flex',flexDirection:'row'}}>
      <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'flex-end',
      flexDirection:'column',textDecoration:'none',color:'black',fontSize:14,fontWeight:800}} >
        <span style={{fontWeight:800,fontSize:14,color:'black'}}>{item.totalVote}</span>
        <span style={{fontWeight:500,fontSize:14,color:'black'}}> Votes</span>
      
        </div>
      
        </div>}
      
      </div>
      </div>
      )
      }
      })}
      </div>:""}
      
      </div>
      :<div style={{display:'flex',marginTop:60,maxWidth:'500px',alignSelf:'center',
      flexDirection:'column',width:'100%',alignItems:'center',marginBottom:shallVote?50:10,}}>
      {eventData.length?
      isSelectedRef.current? <div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
      flexDirection:'column',width:'100%',alignItems:'center'}}>
      {eventPost.selectedPost.map((item,index)=>{
      
      return(
      <div className="card" style={{marginBottom:20,borderRadius:10,backgroundColor:'white'}} key={item._id}>
        <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
      width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
      }}  >
      {
      item.creatorData[0].profilePic===undefined || item.creatorData[0].profilePic===""?<img
      style={{width:36,height:36,borderRadius:18}}
      src={IMGLOGO}
      />:<img
      style={{width:36,height:36,borderRadius:18}}
      src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.creatorData[0].profilePic}`}
      />
      
      }
        <span style={{textAlign:'left',fontSize:14,marginLeft:10}}>{item.creatorData[0].username}</span>
      </div>
      
      <LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${ item.fileUrl}`} aspectRatio={1}/>
      
      <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
      width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
      }}  >
      {!item?.isScelected? <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
      flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{
        if(accessToken.accessToken)
        {
          
          if(isEmailNotVerified.data[0].isEmailVerified)
          {
            dispatch(updateSelection(item))
      
          }
          else
          {
            alert("Email not veriifed,pleas verify your email to vote!")
      
      
          }
      
        }
        else
        {
          setLoginUi(true)
        }
      }} >
      <FontAwesomeIcon icon={faVoteYea} style={{fontSize:20,color:'black',}}/>
      <span style={{fontWeight:500,fontSize:14}}>Select</span>
      
      </div>:
      <div style={{padding:10,display:'flex',alignItems:'center',justifyContent:'center',
      flexDirection:'column',textDecoration:'none',color:'black'}} onClick={()=>{
      if(eventPost.selectedPost.length==3)
      {
      setShallVote(false)
      
      }
      if(eventPost.selectedPost.length==1)
        {
      setIsSelected(false)
        }
      dispatch(disselectPost(item))
      }} >
      <FontAwesomeIcon icon={faCancel} style={{fontSize:20,color:'#fe4040',}}/>
      <span style={{fontWeight:500,fontSize:14,color:'#fe4040'}}>Disselect</span>
      
      </div>}
      
      
      
      </div>
      </div>
      )
      
      })}
      </div>
      : <div style={{display:'flex',maxWidth:'500px',alignSelf:'center',
      flexDirection:'column',width:'100%',alignItems:'center'}}>
      {eventData.map((item,index)=>{
      if(item?.contentType=="ADVERT")
      {
      
      return(
      <a  href={item.actionLink}  target='_blank' className="card" style={{
      marginBottom:30,borderRadius:10,textDecoration:'none'}} key={item._id}>
      <LazyImage  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/advertisement/${ item.banner}`} aspectRatio={'16/9'}/>
      
      
      <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'flex-start',width:'100%',
      width:'90%',maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',padding:10,alignItems:'center'
      }}  >
      {
      item.sponsorData[0].profilePic===undefined || item.sponsorData[0].profilePic===""?<img
      style={{width:36,height:36,borderRadius:18}}
      src={IMGLOGO}
      />:<img
      style={{width:36,height:36,borderRadius:18}}
      src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.sponsorData[0].profilePic}`}
      />
        
      }
        <div style={{display:'flex',flexDirection:'column',
      justifyContent:'center',marginLeft:10,marginTop:20}}>
      <span style={{textAlign:'left',fontSize:14,fontWeight:600,marginBottom:5}}>Sponsored
      <span style={{textAlign:'left',fontSize:16,fontWeight:400,color:'grey',marginLeft:5}}>
      {item.sponsorData[0].username}</span></span>
      <span style={{textAlign:'left',fontSize:14,marginBottom:2}}>
      {item.title}
      </span>
      <span style={{textAlign:'left',color:'grey',fontSize:14}}>
      {item.discription}               
      </span>
      </div>
      
      </div>
      
      </a>
      )
      }
      else
      {
       
      return(
      <div className="card" style={{marginBottom:20,borderRadius:10,backgroundColor:'white'}} key={item._id} id={item._id}>
      <div style={{display:'flex',flex:1,alignSelf:'flex-start',justifyContent:'space-between',width:'100%', maxWidth:'400px',marginBottom:10,
        textDecoration:'none',color:'black',alignItems:'center'}} >
          <div style={{display:'flex',padding:10,flexDirection:'row',alignItems:'center'}}>
        {
          item.creatorData[0].profilePic===undefined || item.creatorData[0].profilePic==="" ?
          <img style={{width:36,height:36,borderRadius:18}} src={IMGLOGO} />
          : <img style={{width:36,height:36,borderRadius:18}} src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${ item.creatorData[0].profilePic}`} />
        }
        <span style={{textAlign:'left',fontSize:14,marginLeft:10}}>{item.creatorData[0].username}</span>
        </div>
        {accessToken.accessToken?
          <div style={{display:'flex',padding:10,flexDirection:'column',alignItems:'center',justifyContent:'center'}} onClick={()=>{
            setcurrentEventId(item._id)
            setshowComment(true)
          }}>
          <FontAwesomeIcon icon={faTag} style={{fontSize:16,color:'black',}}/>
          
          <span style={{textAlign:'left',fontSize:14,}}>Tag</span>
          </div>
          :""}
      
      </div>
      
      <LazyImageEvent src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${ item.fileUrl}`} aspectRatio={1} postId={item._id} 
       setIntercetingId ={(postId)=>{currentPostId.current=postId;}}/>
      
      {/* Voting and Action Icons */}
      <div style={{display:'flex',flex:1,alignSelf:'flex-start',width:'100%',
         maxWidth:'400px',marginBottom:10,textDecoration:'none',color:'black',alignItems:'center',}} >
      
        {/* Select/Disselect Icon */}
        {!item?.isScelected ? 
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',padding:10}} onClick={()=>{
            if(accessToken.accessToken)
              {
                if(isEmailNotVerified.data[0].isEmailVerified)
                {
                  if(eventPost.selectedPost.length==3)
                    {
                      
                      
                      return;
                    }
                    else if(eventPost.selectedPost.length==0)
                    {
                    
                      dispatch(updateSelection(item))
                      setisPopup1visi(true)
                      setTimeout(()=>{
                        setisPopup1visi(false)
                      },1200)
                    
                    }
                    else
                    {
        
                      dispatch(updateSelection(item))
                      if(eventPost.selectedPost.length==1)
                      {
                        setisPopup1visi(true)
                        setTimeout(()=>{
                          setisPopup1visi(false)
                        },1200)
                      }
                      else
                      {
                        setShallVote(true) 
        
                      }
                    }
                }
                else
                {
                  console.log("heu")
      setEmailVerificationerror(true)
      setTimeout(()=>{
        setEmailVerificationerror(false)
      },1200)
                }
                
                    
              }
              else
              {
                setLoginUi(true)
                disableScroll()
              }
          }} >
            <FontAwesomeIcon icon={faVoteYea} style={{fontSize:16,color:'black',}}/>
            <span style={{fontWeight:500,fontSize:12}}>Select</span>
          </div> 
          : 
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',padding:10}} onClick={()=>{
             
            if(eventPost.selectedPost.length==3)
              {
                setShallVote(false)
              }
         
              dispatch(disselectPost(item))
            }}
            >
            <FontAwesomeIcon icon={faCancel} style={{fontSize:16,color:'#fe4040',}}/>
            <span style={{fontWeight:500,fontSize:12,color:'#fe4040'}}>Disselect</span>
          </div>
        }
      
       
        
      
        {/* Total Votes */}
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',padding:10,alignSelf:'flex-end'}}>
          <span style={{fontWeight:800,fontSize:14,color:'black'}}>{item.totalVote}</span>
          <span style={{fontWeight:500,fontSize:12,color:'black'}}> Votes</span>
        </div>
      
        {/* Winning Probability Progress Bar */}
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:10}}>
          <div style={{width: 24, height: 6, backgroundColor: '#eee', overflow: 'hidden'}}>
            <div style={{
              width: `${Math.ceil(item.winningChance*10) }%`,
              height: `100%`, // Adjust height dynamically based on the winning probability
              backgroundColor: Math.ceil(item.winningChance*10) > 75 ? 'green' : Math.ceil(item.winningChance*10) > 40 ? 'yellow' : 'red',
              // Make the bar grow upwards
            }} />
          </div>
          <span style={{fontWeight:500,fontSize:12,color:'black',marginTop:10}}>{`${Math.ceil(item.winningChance*10)}%`}</span>
        </div>
        {/* Share Icon */}
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',padding:10}}>
          <RWebShare data={{url: `https://justscroll.in/vote/${eventId.eventId}?postId=${item._id}`, title: "Event Post Share Link"}} onClick={() => console.log("shared successfully!")}>
            <FontAwesomeIcon icon={faShare} style={{fontSize:16,color:'black',}}/>
          </RWebShare>
          <span style={{fontWeight:500,fontSize:12,color:'black'}}>Share</span>
        </div>
      </div>
      </div>
      
      )
      }
      })}
      </div>:<Spinner />
      }
      
      </div>}
      </div>}
        

        </div>}
{shallVote?<button
   disabled={isVoting}
  style={{
    display: 'flex',
    alignSelf: 'center',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    maxWidth: '500px',
    height: 40,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#66003a',
    textAlign: 'center',
    borderRadius: '12px 12px 0 0', // Rounded corners for the top of the button area
  }}
  
  onClick={()=>{castVote()}}
>
  {isVoting ? (
    <Spinner />
  ) : (
    <p style={{color:'white',fontFamily:'Poppins'}}> Cast Vote</p>
      
   
  )}
</button>
:""}
{showHowTo ?<HowToVote closeHowTo={()=>{setShowHowTo(false);disableScroll()}}/>:""}
{ showLoginUi?<LoginUI  serach={searchParms.get('postId')?searchParms.get('postId'):null} eventId={eventId} 
updateLoginUi={() =>{setLoginUi(!showLoginUi);
  disableScroll()

} } />:""}
 {showComment?<CommentSection updateComment={() =>{setshowComment(!showComment);
 setcurrentEventId(null);
      disableScroll()

 } } event={currentEventID} eventId={eventId} closeAndScroll={(mention)=>{
  setOverflow('auto');

  currentPostId.current=mention;

  setMentionTap(true)
  setshowComment(!showComment);
 

  
 }}/>:""}
 
 
 {isPopup1visi?
 <div
 style={{
   position: 'fixed',
   bottom:  isPopup1visi?'20px' : '-60px', // Animation for pop-in
   left: '50%',
   transform: 'translateX(-50%)',
   backgroundColor: '#fe4040',
   color: 'white',
   borderRadius: '8px',
   fontSize: '16px',
   transition: 'bottom 0.5s ease',
   maxHeight: '50px',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   width:'90%',
   maxWidth:'400px'
 }}
>
 <p style={{padding:'10px 20px',fontSize:14}}>Please Select {3-eventPost.selectedPost.length} more content to submit your vote</p>
</div>:""
}
{emailVeriifcationError?
    <div
    style={{
      position: 'fixed',
      bottom:  emailVeriifcationError?'20px' : '-60px', // Animation for pop-in
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: 'red',
      color: 'white',
      borderRadius: '8px',
      fontSize: '16px',
      transition: 'bottom 0.5s ease',
      maxHeight: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width:'90%',
      maxWidth:'400px'
    }}
   >
    <p style={{padding:'10px 20px',fontSize:14}}>Your Email Is Not Verified</p>
   </div>:""
   }
 
    </div>
  )
  
}
const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 50,
  },
  errorContainer: {
    display: 'flex',
    marginTop: 60,
    maxWidth: '500px',
    alignSelf: 'center',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginBottom: 60,
  },
  retryButton: {
    disselectPost:'flex',
    justifyContent:'center',
    alignItems:'center',
    width: '30%',
    height: 40,
    padding: 10,
    backgroundColor: '#fe4040',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
  },
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '60px',
    width: '100%',
    maxWidth: '500px',
  },
  buttonGreen: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    maxWidth:'200px',
    width: '50%',
    height: '30px',
    padding: '15px',
    backgroundColor: 'green',
    border: 'none',
    color: 'white',
    borderRadius: '5px',
  },
  buttonVote: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    maxWidth:'200px',
    width: '50%',
    height: '30px',
    padding: '15px',
    backgroundColor: '#66003a',
    border: 'none',
    color: 'white',
    borderRadius: '5px',
  },
  eventImage: {
    width: '90%',
    aspectRatio: 1,
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  formTitle: {
    color: '#333333',
    fontFamily: 'Josefin Sans',
    fontSize: '30px',
    fontWeight: 'bold',
    marginBottom: '20px',
    padding: '10px',
  },
  helperText: {
    padding: '10px',
    fontSize: '14px',
  },
  previewContainer: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  previewImage: {
    marginBottom: '10px',
    maxWidth: '400px',
    width: '100%',
    aspectRatio: 1,
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  submitButton: {
    width: '50%',
    height: '40px',
    padding: '10px',
    backgroundColor: '#8bdef5',
    border: 'none',
    color: 'black',
    borderRadius: '5px',
    outline:'none',
    marginBottom:20,

  },
  selectButton: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width: '50%',
    height: '40px',
    padding: '10px 20px',
    backgroundColor: '#fe4040',
    color: 'white',
    borderRadius: '5px',
    border:'none',
    outline:'none'
  },
  termsText: {
    marginTop: '20px',
    textAlign: 'left',
    fontWeight: 600,
    padding: '10px',
  },
  termsList: {
    padding: '5px',
    textAlign: 'left',
  },
  spinnerText: {
    padding: '10px',
  },
  navLink: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navLinkText: {
    textDecoration: 'none',
    color: 'white',
  }
};
export default Vote