import React, { useEffect, useRef, useState } from 'react';
import { fetchUrl } from '../Contsant';
import { Link, useParams } from 'react-router-dom';
import Headerstore from './HeaderStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Spinner from './Spinner'
import { useDispatch, useSelector } from 'react-redux';
import { updateStore } from '../Redux/actions/Store';
import BottomTab from './BottomTab'

const Store = () => {
  const [books, setBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const { username } = useParams();
  const searchRef = useRef(null);
  const [userData, setUserData] = useState({});
  const [noResults, setNoResults] = useState(false);
  const debounceTimeout = useRef(null);
  const [isFetchingStoreData,setIsFetchingStoreData]=useState(true)
  const [hasError,sethasError]=useState(false)
  const storeData=useSelector(state=>state.Store)
  const dispatch=useDispatch()
  
  useEffect(() => {
   
    if(storeData.userData && storeData.username==username)
    {
      setBooks(storeData.storeData);
      setFilteredBooks(storeData.storeData); 
    setUserData(storeData.userData);
    setIsFetchingStoreData(false)
    sethasError(false);
   // dispatch(updateStore(username,books,jsonRes.userData))
    }
    else
    {
      fetchBooks();

    }
  }, [storeData]);
  const fetchBooks = async () => {
    try {
      sethasError(false)
      setIsFetchingStoreData(true)
       fetch(`${fetchUrl}webfn/getallProducts/${username}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }).then(async res=>{
        const jsonRes = await res.json(); 

        if(jsonRes.statusCode===211)
        {
          const books=JSON.parse(jsonRes.book)
          
          setBooks(books);
          setFilteredBooks(books); 
        setUserData(jsonRes.userData);
        setIsFetchingStoreData(false)
        sethasError(false);
        dispatch(updateStore(username,books,jsonRes.userData))
        }
        else
        {
          setIsFetchingStoreData(false)
          sethasError(true)
        }
     

      })
      .catch(err=>{
        console.log(err)
        setIsFetchingStoreData(false)
        sethasError(true)

      });
     
      // Initialize filteredBooks with all books
    } catch (error) {
      setIsFetchingStoreData(false)
      sethasError(true)

      console.log('Failed to fetch books', error);
    }
  };
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Clear previous timeout if any
    clearTimeout(debounceTimeout.current);

    if (value) {
      setIsSearching(true);
      debounceTimeout.current = setTimeout(() => {
        // Check locally first
        const localFilteredBooks = books.filter((book) =>
          book.title.toLowerCase().includes(value.toLowerCase())
        );

        if (localFilteredBooks.length > 0) {
          setFilteredBooks(localFilteredBooks);
          setNoResults(false);
        } else {
          // If no local results, fetch from the server'  setNoResults(data.books.length === 0);
          setNoResults(true);
        }
      }, 500); // 500ms delay
    } else {
      // If search input is cleared, reset to original books list
      setFilteredBooks(books);
      setNoResults(false);
      
    }
  };
  useEffect(() => {
    // Filter books based on the search term
    if (searchTerm === '') {
      setFilteredBooks(books); // Show all books if search is cleared
    } else {
      const lowercasedTerm = searchTerm.toLowerCase();
      setFilteredBooks(
        books.filter(book =>
          book.title.toLowerCase().includes(lowercasedTerm)
        )
      );
    }
  }, [searchTerm, books]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', zIndex: 100, width: '100%', maxWidth: '500px', margin: '0px auto' }}>
       {isFetchingStoreData?<Spinner />:hasError? <div style={styles.errorContainer}>
        <h3>Something went wrong. Please try again.</h3>
        <button style={styles.retryButton}  disabled={isFetchingStoreData} onClick={()=>{fetchBooks()}}>
          Retry
        </button>
      </div>:<div style={{ display: 'flex', flexDirection: 'column', zIndex: 100, width: '100%', maxWidth: '500px', margin: '0px auto' }}>
{isSearching ? (
  <div style={styles.searchContainer}>
    <input
      ref={searchRef}
      type="text"
      placeholder="Search Items In This Store!"
      value={searchTerm}
      onChange={handleSearch}
     
      style={styles.searchInput}
    />
    <button style={styles.cancelButton} onClick={() => { setIsSearching(false); setSearchTerm(''); setFilteredBooks(books) ;setNoResults(false);}}>
      ✕
    </button>
  </div>
) : (
  <Headerstore userData={userData} />
)}

<div style={styles.container}>
  <div style={styles.gridContainer}>
    {noResults ? (
      <p style={styles.noResultsText}>No products found</p>
    ) : (
      filteredBooks.map((book) => (
        <div key={book._id} style={styles.bookCard}>
          <img src={book.images[0]} alt={book.title} style={styles.bookImage} />
          <div style={styles.bookInfo}>
            <Link to={`/product/${book._id}`} state={{ book ,bussinessData:userData}} style={styles.bookTitle}>
              {book.title}
            </Link>
            <p style={styles.bookAuthor}>{book.author}</p>
            <p style={styles.bookPrice}>Rs.{book.price}</p>
            <p style={styles.bookGenre}>Genre: {book.genre}</p>
          </div>
          <div style={styles.buyButtonContainer}>
            <Link to={`/purchase/${book._id}`} state={{ book ,bussinessData:userData}}>
              <button style={styles.buyButton}>Buy Now</button>
            </Link>
          </div>
        </div>
      ))
    )}
  </div>
</div>

{/* Search Icon Button */}
<button style={styles.searchButton} onClick={() => { setIsSearching(true); setTimeout(()=>{searchRef?.current?.focus()},200) }}>
  <FontAwesomeIcon icon={faSearch} color='black' />
</button>
</div>}
<BottomTab />
      </div>
  );
};

const styles = {
  retryButton: {
    disselectPost:'flex',
    justifyContent:'center',
    alignItems:'center',
    width: '30%',
    height: 40,
    padding: 10,
    backgroundColor: '#fe4040',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '500px',
    alignSelf: 'center',
    width: '100%',
    padding: 5,
    position: 'fixed',
    top: 0,
    backgroundColor:'white'
  },
  searchInput: {
    width: '70%',
    padding: '10px 40px 10px 10px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  cancelButton: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
    color: '#999',
  },
  container: {
    maxWidth: '500px',
    width: '100%',
    marginTop: 60,
    textAlign: 'center',
    marginBottom:50,
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '15px',
  },
  bookCard: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'auto',
  },
  bookImage: {
    width: '100%',
    minHeight: 200,
    height: 'auto',
    borderRadius: '8px',
    marginBottom: '15px',
  },
  bookInfo: {
    marginBottom: '15px',
  },
  bookTitle: {
    fontSize: '0.95rem',
    color: 'black',
    marginBottom: '5px',
    textDecoration: 'underline',
  },
  bookAuthor: {
    fontSize: '0.9rem',
    color: '#666',
    marginBottom: '5px',
  },
  bookPrice: {
    fontSize: '1rem',
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  bookGenre: {
    fontSize: '0.9rem',
    color: '#777',
  },
  buyButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
  },
  buyButton: {
    padding: '10px 15px',
    backgroundColor: '#fe4040',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
    transition: 'background-color 0.3s',
  },
  searchButton: {
    position: 'fixed',
    bottom: '60px',
    right: '20px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    color: 'white',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
};

export default Store;
