import React, { useEffect, useState } from 'react'
import PrivateRoutes from './PrivateRoutes';
import { Profile } from './component/Profile';
import { Login } from './component/Login';
import Event from './component/Event';
import ChooseUsername from './component/ChooseUsername';
import Password from './component/Password';
import ForgetPassword from './component/ForgetPassword';
import VerifyEmail from './component/VerifyEmail';
import VerifyCode from './component/VerifyCode';
import Changepassword from './component/Changepassword';
import Participate from './component/Participate';
import Vote from './component/Vote';
import Userprofile from './component/Userprofile';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './component/Home';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from './Redux/actions/Auth';
import UnprotectedRoute from './UnprotectedRoute';
import EditProfile from './component/EditProfile';
import { fetchUrl } from './Contsant';
import { setError, setUserData } from './Redux/actions/UserData';
import Eventdetail from './component/Eventdetail';
import Spinner from './component/Spinner';
import Verification from './component/Verification';
import Interests from './component/Interests';
import EventandBrands from './component/EventandBrands';
import EventInfluence from './component/EventInfluence';
import Contactus from './component/Contactus';
import CreatorUpdate from './component/CreatorUpdate';
import HowToVote from './component/HowToVote';

import PrivacyPolicy from './component/privacypolicy'
import TermsCondition from './component/termscondition'
import CommunityGuideline from './component/communityGuideline';
import Winner from './component/Winner';
import SendEmailToInfluencer from './component/SendEmailToInfluencer';
import SendEmailToSponsor from './component/SendEmailToSponsor';
import AddProduct from './component/AddProduct';
import Store from './component/Store';
import BuyoutPage from './component/Purchase';
import Product from './component/Product';
import StoreBetaNotice from './component/StoreBetaNotice';
import Chat from './component/Chat';
import OrdersPage from './component/Orders';
import BusinessChat from './component/BusinessChat';
import { equalTo, getDatabase, onValue, orderByChild, query, ref } from 'firebase/database';
import { createOrder } from './Redux/actions/Orders';
import { createChat } from './Redux/actions/BusinessChat';
import { messaging } from './firebase';
import { getToken } from 'firebase/messaging';
import { setUnseenMessagesCount } from './Redux/actions/Notification';
import { orderCouuntTotal } from './Redux/actions/Ordercount';
import InvoiceGenerator from './component/Invoice';
const AppWrapper = () => {
    const dispatch=useDispatch()
    const [isReady,setIsReady]=useState(false)
    const orderedFromUserId=useSelector(state=>state.UserData)
    const unseenMessage=useSelector(state=>state.Notification)
    const useenOrders=useSelector(state=>state.Ordercount)


    useEffect(()=>{
       checkStorage()
    },[])
  useEffect(()=>{
      console.log(useenOrders)  
  },[useenOrders])
   
    const checkStorage=async()=>{
        const accessToken=await localStorage.getItem('accessToken')
        if(accessToken)
            {
              
              dispatch(userLogin(accessToken))
              setIsReady(true)
              fetch(`${fetchUrl}webfn/getUserData`, {
                method: 'get',
                headers: {
                'Content-Type': 'application/json',
                'authorization': accessToken
                },
              
                })
                .then(async(res)=>{
                  const jsonRes = await res.json();
                  switch (jsonRes.statusCode) {
                    case 211:
                     dispatch(setUserData(JSON.parse(jsonRes.userData)))
                    default:
                  dispatch(setError())

                  }
                }).catch(err=>{
                  dispatch(setError())
                })
            }
            else
            {
              setIsReady(true)
            }
    }
    useEffect(() => {
      //console.log(orderedFromUserId?.data)
      if(orderedFromUserId?.data.length)
      {
        const db = getDatabase();
        const ordersRef = ref(db, 'orders');
         
        // Query orders where `orderedFrom.userId` matches `orderedFromUserId`
        const ordersQuery = query(ordersRef, orderByChild('orderdFrom/userId'), equalTo(orderedFromUserId?.data[0]._id));
    
        // Observe changes
        const unsubscribe = onValue(ordersQuery, (snapshot) => {
          if (snapshot.exists()) {
            const fetchedOrders = Object.values(snapshot.val());
            //console.log(fetchedOrders)
            dispatch(createOrder(fetchedOrders))
            let unseenCount = 0;
            fetchedOrders.forEach(order => {
              const messages = order.message;
              if (messages && messages.length > 0) {
                const lastMessage = messages[messages.length - 1];
              
                // Increment unseenCount only if lastMessage is unseen and sent to the user
                if(messages.length==2)
                {
                  if (!lastMessage.isSeen )
                  {
                    unseenCount += 1;
                  }
                }
                else
                {
                  if (!lastMessage.isSeen && lastMessage.to === orderedFromUserId?.data[0]._id && unseenCount>=0) {
                    unseenCount += 1;
                  }
                }
               
              }
            });
          dispatch(orderCouuntTotal(unseenCount))
          } else {
            console.log("Something went wrong!!")
          }
        });
        return () => unsubscribe();
      }
    
  
      // Clean up listener on unmount
      
    }, [orderedFromUserId]);

    useEffect(() => {
      //console.log(orderedFromUserId?.data)
      if(orderedFromUserId?.data.length)
      {
        const db = getDatabase();
        const ordersRef = ref(db, 'orders');
         
        // Query orders where `orderedFrom.userId` matches `orderedFromUserId`
        const ordersQuery = query(ordersRef, orderByChild('orderrdBy/userId'), equalTo(orderedFromUserId?.data[0]._id));
    
        // Observe changes
        const unsubscribe = onValue(ordersQuery, (snapshot) => {
          if (snapshot.exists()) {
            const fetchedOrders = Object.values(snapshot.val());
            //Here i want to count total unseen message
          
            dispatch(createChat(fetchedOrders))
            let unseenCount = 0;
            fetchedOrders.forEach(order => {
              const messages = order.message;
              if (messages && messages.length > 0) {
                const lastMessage = messages[messages.length - 1];
                // Increment unseenCount only if lastMessage is unseen and sent to the user
                if (!lastMessage.isSeen && lastMessage.to === orderedFromUserId?.data[0]._id && unseenCount>=0) {
                  unseenCount += 1;
                }
                
              }
            });
            console.log(unseenCount)
          dispatch(setUnseenMessagesCount(unseenCount))
          } else {
            console.log("Something went wrong!!")
          }
        });
        return () => unsubscribe();
      }
    
  
      // Clean up listener on unmount
      
    }, [orderedFromUserId]);

  return (
    <div className="App" >
     {isReady? <Routes>
      <Route element={<PrivateRoutes />}>
         
          <Route path="/profile" element={<Profile/>}  />
          <Route path="/editprofile" element={<EditProfile/>}  />

      <Route exact path="/participate/:eventId" element={<Participate/>}  />
         
      <Route path="/verification" element={<Verification/>}  />
      <Route path="/interest" element={<Interests/>}  />
      <Route path="/109876112343sendEmailSponsor" element={<SendEmailToSponsor />}></Route>
      <Route path="/109876112343sendEmailInfluencer" element={<SendEmailToInfluencer />}></Route>
      <Route path="/addProduct" element={<AddProduct />}></Route>



         
      </Route>
      <Route element={<UnprotectedRoute />}>
         
      <Route path="/login" element={<Login/>}/>
      <Route path="/chooseUsername" element={<ChooseUsername/>}  />
      <Route path="/password" element={<Password/>}  />
      <Route path="/forgetpassword" element={<ForgetPassword/>}  />
      <Route path="/verifyemail" element={<VerifyEmail/>}  />
      <Route path="/verifycode" element={<VerifyCode/>}  />
      <Route path="/changepassword" element={<Changepassword/>}  />



         
      </Route>
      <Route exact path="/vote/:eventId" element={<Vote/>}  />
      <Route exact path="/winner/:eventId" element={<Winner/>}  />


      <Route path="/eventdetail" element={<Eventdetail/>}  />
      <Route path="/contactus" element={<Contactus/>}  />
      <Route path="/terms&condition" element={<TermsCondition />}></Route>
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>

      <Route path="/eventandbrands" element={<EventandBrands />}  />
      <Route path="/eventandinfluencer" element={<EventInfluence />}  />
      <Route path="/communityguideline" element={<CommunityGuideline />}></Route>

      <Route path="/" element={<Home/>}  />
      <Route path="/event" element={<Event/>}  />
      <Route path="/chat/:id" element={<Chat />}  />
      <Route path="/order" element={<OrdersPage />}  />
      <Route path="/businesschat" element={<BusinessChat />}  />



      <Route exact path="/UserProfile/:userId" element={<Userprofile/>}  />
      <Route path="/109876112343verifyit" element={<CreatorUpdate />}></Route>
      <Route path="/howtovote" element={<HowToVote />}></Route>
      <Route path="/store/:username" element={<Store />}></Route>
      <Route path="/purchase/:bookId" element={<BuyoutPage />}></Route>
      <Route path="/product/:bookId" element={<Product />}></Route>
      <Route path="/store" element={<StoreBetaNotice />}></Route>
      <Route path="/invoice" element={<InvoiceGenerator />}></Route>




     







    </Routes>:<Spinner />}
   
    
</div>
  )
}

export default AppWrapper