import React, { useEffect, useRef, useState } from 'react'
import '../css/globalstyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faCheck, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUrl } from '../Contsant'
import Spinner from './Spinner';
import Comment from './Comment';
import { emptyPostComment, setPostComment } from '../Redux/actions/Comment';
const CommentSection = (props) => {
  const [comment, setComment] = useState('');
  const commentTone=useSelector(state=>state.Comment);
  const [commentsList, setCommentsList] = useState([])
  const inputRef = useRef(null);
  const dispatch=useDispatch()
 const [isFetchingComment,setIsFetchingComment]=useState(false)
  const accessToken=useSelector(state=>state.Auth)
  const [isAddingComment,setIsAddingComment]=useState(false)
  useEffect(()=>{
    setIsFetchingComment(true)
    fetch(`${fetchUrl}post/getComment/${props.eventId.eventId}`, { 
      headers: {
      'Content-Type': 'application/json',
      
      }, 
      })
      .then(async res => { 
      try {
      const jsonRes = await res.json();
      dispatch(setPostComment(jsonRes))
     setIsFetchingComment(false)
      } catch (err) {
     setIsFetchingComment(false)

      };    
      })
      .catch(err => {
      console.log(err)
     setIsFetchingComment(false)

      }); 
      
      
  },[])
  // Add a new comment to the list
  const addComment = () => {
     setIsAddingComment(true)
    if(comment=="")
      {
     setIsAddingComment(false)
        return
      }
      else
      {
        const payload={eventId:props.eventId.eventId,comment}
        fetch(`${fetchUrl}webfn/postComment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authorization': accessToken.accessToken
          },
          body:JSON.stringify(payload),
          })
          .then(async res=>{
              const jsonRes=await res.json();
              if(jsonRes.statusCode==211)
              {
                setCommentsList([JSON.parse(jsonRes.data),...commentsList,])
                setIsAddingComment(false)
                setComment("")

              }
            else
            {
              setIsAddingComment(false)

            }
          }).catch(err=>{
            setIsAddingComment(false)

            console.log(err)
          })
      }
  };
  useEffect(()=>{
      if(props.event)
      {
        inputRef.current.focus();
        setComment(`@${props.event} `)

      }
  },[props])
  useEffect(() => {
    setCommentsList(commentTone.comment)
  }, [commentTone])

  
  return (
    <div style={{
      display: 'flex',
      width: '90%',
      maxWidth: '500px',
      height: 'auto',
      maxHeight:'60vh',
      alignSelf: 'center',
      overflowY: 'scroll',
      position: 'fixed',
      backgroundColor: '#f9f9f9',
      bottom: 0,
      zIndex: 1100,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      padding: '20px',
      flexDirection: 'column',
    }}>
      {/* Header with close button */}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingBottom: '10px',
        borderBottom: '1px solid #ddd',
      }}>
        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Comments</p>
        <FontAwesomeIcon icon={faTimes} style={{ fontSize: 26, color: '#666',marginTop:10 }}   onClick={props.updateComment}/>
      </div>
    
      {/* Event Details */}
    

      {/* Comments List */}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px',
        flex: 1,
        minHeight: '160px', // Adjust height as needed
        overflowY: 'auto',
      }}>
        {isFetchingComment?<Spinner />:
        commentsList.length ? (
          commentsList.map((item, index) => (
           <Comment comment={item} updateTap={(mention)=>{props.closeAndScroll(mention)}} key={item._id}/>
          ))
        ) : (
          <p>No comments yet. Be the first to comment!</p>
        )}
      </div>

      {/* Input and Send Button */}
      <div style={{
        display: 'flex',
        marginTop: '10px',
        alignItems: 'center',
        overflow:'hidden',
      width:'100%',
      backgroundColor:'white',
      
      maxWidth:'500px',
      margin:'0px',
     
      justifyItems:'center'
      }}>
        <input
          type="text"
          ref={inputRef}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Write a comment..."
          style={{
            flex: 2,
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            marginRight: '10px',
            fontSize:12,
            width:'70%',outline:'none'
          
          }}

        />
        <button
          onClick={addComment}
          style={{
            padding: '10px 20px',
            backgroundColor: '#4db6ac',
            color: 'white',
            borderRadius: '5px',
            cursor: 'pointer',
            border: 'none',
          }}
        
        >
          {isAddingComment?<Spinner width={6}/>:'Send'}
          
        </button>
      </div>
    </div>
  );
};



export default CommentSection;