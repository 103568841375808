import React from 'react';
import Header from './Header';
import IMG1 from '../image/eventdetail.jpg';

const Eventdetail = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#f9f9f9' }}>
      <Header />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        marginTop: '64px',
      }}>
        {/* Event Image */}
        <div style={{
          width: '100%',
          maxWidth: '500px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          overflow: 'hidden',
        }}>
          <img src={IMG1} alt="Event" style={{ width: '100%', display: 'block' }} />
        </div>

        {/* Event Title */}
        <h2 style={{ color: '#66003a', margin: '20px 0 10px', fontSize: '24px', fontWeight: 'bold' }}>
          JustScroll Events
        </h2>

        {/* Event Description */}
        <p style={{ maxWidth: '500px', textAlign: 'justify', fontSize: '16px', color: '#333', lineHeight: '1.6' }}>
          Dive into the excitement of JustScroll Events, where creativity meets competition. Our platform hosts exclusive,
          limited-entry digital competitions across various niches such as photography, art, music, and memes.
          Each event, except for that of memes, is curated and hosted by a prominent social media influencer, ensuring high
          engagement and visibility.
        </p>

        {/* Section Header */}
        <h3 style={{ color: '#66003a', marginTop: '20px', fontSize: '20px', fontWeight: '600' }}>
          How Does It Work?
        </h3>
        
        {/* How It Works List */}
        <ul style={{ textAlign: 'left', maxWidth: '500px', fontSize: '16px', lineHeight: '1.6', color: '#333' }}>
          <li style={{ marginBottom: '10px' }}>Limited Entry: Only a select number of creators are chosen to compete, ensuring top-quality content and an exciting competition.</li>
          <li style={{ marginBottom: '10px' }}>Public Voting: Audiences actively vote for their favorite entries. Every vote matters, making the competition dynamic and engaging.</li>
          <li>Influencer Involvement: Influencers host the event and hold 30% voting rights, balancing public opinion with expert judgment.</li>
        </ul>

        {/* Section Header */}
        <h3 style={{ color: '#66003a', marginTop: '20px', fontSize: '20px', fontWeight: '600' }}>
          Benefits for Creators
        </h3>
        
        {/* Benefits List */}
        <ul style={{ textAlign: 'left', maxWidth: '500px', fontSize: '16px', lineHeight: '1.6', color: '#333' }}>
          <li style={{ marginBottom: '10px' }}>Increased Exposure: Showcasing your work to a larger, engaged audience helps gain new followers and fans.</li>
          <li style={{ marginBottom: '10px' }}>Monetization Opportunities: Win prizes and gain recognition, opening doors to sponsorships, collaborations, and increased revenue.</li>
          <li style={{ marginBottom: '10px' }}>Community Engagement: Join a community that values creativity and originality, offering a supportive environment for growth.</li>
          <li>Professional Growth: Receive valuable feedback from the public and industry experts to refine your skills and elevate your content.</li>
        </ul>
      </div>
    </div>
  );
};

export default Eventdetail;
