// reducer.js


const initialState = {
    orders: [], // Array to hold all orders
  };
  
  const BusinessChat = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_CHAT':
        return {
          ...state,
          orders: [...state.orders, action.payload],
        };
        case 'CREATE_CHAT':
          return{
              ...state,
              orders: action.payload,
            
          };
      case 'UPDATE_CHAT':
        return {
          ...state,
          orders: state.orders.map(order => 
            order.orderId === action.payload.orderId 
              ? { ...order, ...action.payload.updatedData } 
              : order
          ),
        };
      
      default:
        return state;
    }
  };
  
  export default BusinessChat;
  