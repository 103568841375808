import React, { useState } from 'react'
import Header from './Header'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import Spinner from './Spinner'
import {fetchUrl} from '../Contsant/index'

const ForgetPassword = () => {
  const[username,setUsername]=useState();
  const[isLoading,setIsloading]=useState(false)
  const[haserror,setHasError]=useState(false)
  const[error,setError]=useState("")
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const retrivePasswordfnc=async()=>{
    const mainDeviceId='webLogin-no'
  
      setIsloading(true)
      setHasError(true)
      setError("") 
    fetch(`${fetchUrl}auth/retrivePassword/`+JSON.stringify({username,mainDeviceId}), {
      method: 'get',
   })
  .then(async res => { 
    try {
      const jsonRes = await res.json();
      if(jsonRes.statusCode==200)
        {
          navigate("/verifyemail",{state:{username:username}})
          setIsloading(false)
          setError("")
          setHasError(false)
        }
        else
        {
          setError(jsonRes.message)
          setHasError(true)
          setIsloading(false)
        }
  } catch (err) {
      console.log(err);
      setError("Something Went Wrong Try Again Later!!")
      setHasError(true)
      setIsloading(false)
  };
  })
  .catch(error=>{
    console.log(error)
    setError("Something Went Wrong Try Again Later!!")
    setHasError(true)
    setIsloading(false)})
    }

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
    <Header />
    <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 30, 
      maxWidth: 500, width: '100%', margin: '50px auto', boxSizing: 'border-box', 
      backgroundColor: '#f7f7f7', borderRadius: 10, boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      minHeight: '100vh'
    }}>
      <p style={{
        color: '#333', fontFamily: 'Poppins', fontSize: 32, fontWeight: 'bold',
        textAlign: 'center', margin: '20px 0',
      }}>Retrieve Password</p>
  
      <p style={{
        fontFamily: 'Poppins, sans-serif', textAlign: 'center', color: '#333', fontSize: 18, 
        fontWeight: '500', margin: '10px 0'
      }}>Enter your Username to retrieve your password!</p>
  
      {haserror && (
        <p style={{
          color: 'white', backgroundColor: '#d9534f', padding: 10, margin: '10px 0',
          textAlign: 'center', borderRadius: 10
        }}>{error}</p>
      )}
  
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <div style={{
          display: 'flex', border: '1px solid #ccc', borderRadius: 10, padding: 10, 
          alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        }}>
          <input type='email' placeholder='Enter your username' style={{
            width: '100%', border: 'none', outline: 'none', color: '#333', 
            padding: 12, fontSize: 16, height: 20
          }} onChange={(e) => setUsername(e.target.value)} />
        </div>
      </div>
  
      <button onClick={() => retrivePasswordfnc()} disabled={isLoading} style={{
        padding: 12, backgroundColor: '#4db6ac', color: 'white', borderRadius: 10,
        border: 'none', width: '50%', margin: '20px auto', cursor: 'pointer',
        maxWidth: 200, textAlign: 'center', fontSize: 16, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.3s ease-in-out'
      }}>
        {isLoading ? <Spinner /> : 'Get Code'}
      </button>
    </div>
  </div>
  )
}

export default ForgetPassword