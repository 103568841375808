import React from 'react'
import '../css/header.css';
import '../css/globalstyle.css';
import IMG from'../image/logo-trans.png'


import { useState, useTransition } from 'react';
import { NavLink } from "react-router-dom";
import { faBars, faClose, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
const Header = () => {
  const [isOpne,setIsOpen]=useState("none")
  const [modalVisible, setModalVisible] = useState(false);
  const accessToken=useSelector(state=>state.Auth)
  const notification=useSelector(state=>state.Notification)

  const [showList,setshowList]=useState("none")
  const transitions = useTransition(modalVisible, null, {
    from: { opacity: 0, transform: "translateY(-40px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(-40px)" }
  }); 
  const showListCon=()=>{
    if(showList=="none")
    {
      setshowList("flex")
    }
    else
    {
      setshowList("none")
    }
  }
return (
 

  <div className='appHeader1' style={{paddingTop:'10px',zIndex:1100}}>
    <NavLink to="/" style={{ textDecoration: 'none', color: '#fe4040' }}>
      <img src={IMG} className='applogoother' style={{ height: 40 }} />
    </NavLink>
    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
      {accessToken.accessToken?<div style={{display:'flex',flexDirection:'row'}}>
    
  
  {notification.notificationCount != 0?
    <NavLink
    style={{ display: 'flex', flexDirection: 'column', marginRight: 10, textDecoration: 'none', color: 'black', position: 'relative' }}
    to={'/businesschat'}
  >
    <FontAwesomeIcon icon={faMessage}  color='red'/>
    <span style={{ fontSize: 10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',color:'red' }}>Business Chat</span> 
    <span
      style={{
        position: 'absolute',
        top: -10,
        right: 12,
       
        color: 'red',
      
        fontSize: '18px',
        fontWeight: 'bold',
      }}
    >
      {notification.notificationCount}
    </span>
</NavLink>
: <NavLink
style={{ display: 'flex', flexDirection: 'column', marginRight: 10, textDecoration: 'none', color: 'black', position: 'relative' }}
to={'/businesschat'}
>
<FontAwesomeIcon icon={faMessage}  />
<span style={{ fontSize: 10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Business Chat</span> 

</NavLink>
  }

      
     
      </div>
      :""}
    
    {
      isOpne === "none" ?
        <FontAwesomeIcon icon={faBars} style={{
          fontSize: 26, color: '#333', cursor: 'pointer',marginRight:10,
        }} onClick={() => {
          setIsOpen("block");
          setModalVisible(true);
        }} /> :
        <FontAwesomeIcon icon={faClose} style={{
          fontSize: 26, color: '#333', cursor: 'pointer',marginRight:10,
        }} onClick={() => {
          setIsOpen("none");
          setModalVisible(false);
        }} />
    }
    
    </div>
   
  
    <div className="sideNavigation" style={{
      display: isOpne, position: 'absolute', top: '58x', left: 0, width: '100%', backgroundColor: 'white',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)', zIndex: 999, transition: '0.4s ease', padding: '20px 0px',height:'100vh',
    }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {[
           { label: "Partner Creator Program", link: "/verification" },
           { label: "Community Guidelines", link: "/communityguideline" },

           { label: "Events For Advertiser", link: "/eventandbrands" },
           { label: "Events For Influencer", link: "/eventandinfluencer" },
           { label: "Terms & Condition", link: "/terms&condition" },
           { label: "Privacy Policy", link: "/PrivacyPolicy" },

           { label: "Contact Us", link: "/contactus" },
         
         
         
         
        
        
        ].map((item, index) => (
          <NavLink key={index} to={item.link} style={{
            textDecoration: 'none', color: '#333', fontSize: 16, fontWeight: 'bold',
            padding: '10px 0', borderBottom: '1px solid #ddd'
          }}>
            <span style={{ marginLeft:15,}}>{item.label}</span>
          </NavLink>
        ))}
      </div>
    </div>
  </div>
  
       
         
        
    
  


);
}
export default Header
