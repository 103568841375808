// actions.js


// Action to add a new order
export const createChat = (order) => {
    return {
      type: 'CREATE_CHAT',
      payload: order,
    };
  };
export const addChat = (order) => {
  return {
    type: 'ADD_CHAT',
    payload: order,
  };
};

// Action to update an existing order by orderId
export const updateChat = (orderId, updatedData) => {
  return {
    type: 'UPDATE_CHAT',
    payload: { orderId, updatedData },
  };
};
