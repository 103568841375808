import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import IMG from '../image/logo-trans.png';
import '../css/header.css';
import '../css/globalstyle.css';

const ChatHeader = ({ broughtFrom, orderStatus, updateOrderStatus, fullfilledFromBusiness }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [trackingUrl, setTrackingUrl] = useState('');
  const [trackingId, setTrackingId] = useState('');
  const invoiceRef = useRef();
  const userData = useSelector(state => state.UserData);
  const isSeller = userData.data[0]?._id === broughtFrom.orderdFrom.userId;

  const toggleSideNav = () => setIsOpen(prev => !prev);

  const handleStatusUpdate = (status) => {
    toggleSideNav(); // Close side nav after selecting an option

    if (status === 'DISPATCHED' && isSeller) {
      setShowTrackingModal(true); // Show tracking modal if dispatching
    } 
    else if (status === 'INVOICE' && isSeller) {
      printInvoice()
    } 
    else {
      updateOrderStatus(status);
    }
  };

  const renderStatusOptions = () => {
    const options = {
      PLACED: isSeller 
        ? [{ label: 'ACCEPT', link: 'ACCEPTED' }, { label: 'CANCEL', link: 'CANCELED' }] 
        : [{ label: 'CANCEL', link: 'CANCELED' }],
      ACCEPTED: isSeller 
        ? [{ label: 'INVOICE', link: 'INVOICE' }, { label: 'DISPATCH', link: 'DISPATCHED' }] 
        : [{ label: 'CANCEL', link: 'CANCELED' }],
      DISPATCHED: isSeller && fullfilledFromBusiness 
        ? [] 
        : [{ label: 'RETURN', link: 'RETURN' }, { label: 'FULLFILLED', link: 'FULLFILLED' }],
      RETURN: [{ label: 'FULLFILLED', link: 'FULLFILLED' }],
      FULLFILLED: [],
      CANCELED: []
    };
    return options[orderStatus] || [];
  };

  const handleDispatch = () => {
    if (trackingUrl !== '' && trackingId !== '') {
      setShowTrackingModal(false); // Close modal
      updateOrderStatus('DISPATCHED', trackingUrl, trackingId ); // Pass tracking data
    }
  };
  const printInvoice = () => {
    const printContent = invoiceRef.current.innerHTML;
    const windowPrint = window.open('', '', 'width=800,height=900');
    windowPrint.document.write(`
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
              background-color: #f5f5f5;
              font-size: 16px; /* Increased font size */
            }
            .invoice-container {
              background: white;
              padding: 40px;
              border-radius: 10px;
              box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
              max-width: 800px;
              margin: auto;
            }
            .invoice-header {
              text-align: center;
              margin-bottom: 30px;
            }
            .invoice-header img {
              max-width: 150px; /* Increased image size */
            }
            .invoice-header h1 {
              margin: 0;
              font-size: 36px; /* Increased heading size */
              color: #333;
            }
            .invoice-details {
              display: flex;
              justify-content: space-between;
              margin-bottom: 30px;
            }
            .invoice-details .address, .invoice-details .billing {
              width: 45%;
              padding: 20px;
              border: 1px solid #ddd;
              border-radius: 8px;
              background: #f9f9f9;
              font-size: 18px; /* Increased font size for address and billing info */
            }
            .table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 30px;
            }
            .table th, .table td {
              padding: 15px; /* Increased padding */
              border: 1px solid #ddd;
              text-align: left;
              font-size: 18px; /* Increased font size for table content */
            }
            .table th {
              background-color: #f4f4f4;
            }
            .total {
              font-size: 22px; /* Larger total font size */
              font-weight: bold;
              text-align: right;
              margin-top: 30px;
            }
            @media print {
              body {
                margin: 0;
                padding: 0;
              }
              .invoice-container {
                box-shadow: none;
                margin: 0;
                padding: 0;
              }
            }
          </style>
        </head>
        <body>
          <div class="invoice-container">
            <div class="invoice-header">
              <h1>Invoice</h1>
                <span>${broughtFrom.orderId}</span>
            </div>
            <div class="invoice-details">
              <div class="address">
                <strong>Business Information:</strong>
                <p>${broughtFrom.orderdFrom.username}</p>
                <p>${broughtFrom.shippingAddress.shipping_address}</p>
                <p>${broughtFrom.shippingAddress.contact_number}</p>
                <p>${broughtFrom.shippingAddress.email}</p>
              </div>
              <div class="billing">
                <strong>Customer Information:</strong>
                <p>${broughtFrom.orderrdBy.username}</p>
                <p>${broughtFrom.shippingAddress.shipping_address}</p>
                <p>${broughtFrom.shippingAddress.contact_number}</p>
                <p>${broughtFrom.shippingAddress.email}</p>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${broughtFrom.message[0].productDetail.title}</td>
                  <td>1</td>
                  <td>₹${broughtFrom.message[0].productDetail.price}</td>
                  <td>₹${broughtFrom.message[0].productDetail.price}</td>
                </tr>
              </tbody>
            </table>
            <div class="total">
              <strong>Total: ₹${broughtFrom.message[0].productDetail.price}</strong>
            </div>
          </div>
        </body>
      </html>
    `);
    windowPrint.document.close();
    windowPrint.print();
  };
  const renderInvoice = () => {
    if (orderStatus === 'ACCEPTED' && isSeller) {
      return (
        <div
          style={{
            padding: '20px',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            border: '1px solid #ddd',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            marginBottom: '20px',
          display:'none'
          }}
          ref={invoiceRef}
        >
          {/* Invoice Header */}
          <h4 style={{ marginBottom: '15px', fontSize: '18px', fontWeight: 'bold' }}>
            Order ID: {broughtFrom.orderId}
          </h4>
      
          {/* Product Details Section */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              marginBottom: '20px',
              borderBottom: '1px solid #ddd',
              paddingBottom: '15px',
            }}
          >
            <img
              src={broughtFrom.message[0].productDetail.image}
              alt="Product"
              style={{ width: '80px', height: '120px', borderRadius: '5px' }}
            />
            <div style={{ flex: 1, marginLeft: '15px' }}>
              <p style={{ fontSize: '16px', margin: '5px 0' }}>
                <strong>Product:</strong> {broughtFrom.message[0].productDetail.title}
              </p>
              <p style={{ fontSize: '16px', margin: '5px 0' }}>
                <strong>Price:</strong> ₹{broughtFrom.message[0].productDetail.price}
              </p>
              <p style={{ fontSize: '16px', margin: '5px 0' }}>
                <strong>Quantity:</strong> 1
              </p>
              <p style={{ fontSize: '16px', margin: '5px 0', fontWeight: 'bold' }}>
                <strong>Total:</strong> ₹{broughtFrom.message[0].productDetail.price}
              </p>
            </div>
          </div>
      
          {/* Shipping Details Section */}
          <div
            style={{
              backgroundColor: '#f8f8f8',
              padding: '20px',
              borderRadius: '5px',
              border: '1px solid #ddd',
              marginBottom: '20px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
            }}
          >
            <h5 style={{ fontSize: '18px', marginBottom: '15px' }}>Shipping Details</h5>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ fontSize: '16px', margin: '5px 0' }}>
                <strong>Full Name:</strong> {broughtFrom.shippingAddress.full_name}
              </p>
              <p style={{ fontSize: '16px', margin: '5px 0' }}>
                <strong>Shipping Address:</strong> {broughtFrom.shippingAddress.shipping_address}
              </p>
              <p style={{ fontSize: '16px', margin: '5px 0' }}>
                <strong>Email:</strong> {broughtFrom.shippingAddress.email}
              </p>
              <p style={{ fontSize: '16px', margin: '5px 0' }}>
                <strong>Contact Number:</strong> {broughtFrom.shippingAddress.contact_number}
              </p>
              <p style={{ fontSize: '16px', margin: '5px 0' }}>
                <strong>Pincode:</strong> {broughtFrom.shippingAddress.pincode}
              </p>
              <p style={{ fontSize: '16px', margin: '5px 0' }}>
                <strong>Payment Method:</strong> {broughtFrom.shippingAddress.paymentMethod}
              </p>
            </div>
          </div>
      
          {/* Dispatch Button */}
          <button
            onClick={() =>{printInvoice()}}
            style={{
              backgroundColor: '#fe4040',
              color: 'white',
              border: 'none',
              padding: '12px 20px',
              fontSize: '16px',
              borderRadius: '8px',
              cursor: 'pointer',
              width: '100%',
              transition: 'background-color 0.3s ease',
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = '#e63e3e')}
            onMouseOut={(e) => (e.target.style.backgroundColor = '#fe4040')}
          >
            Download 
          </button>
        </div>
      );
    }      

    }

  return (
    <div className="appHeader1" style={{ paddingTop: '10px', zIndex: 1100 }}>
      <NavLink to="/" style={{ textDecoration: 'none', color: '#fe4040' }}>
        <img src={IMG} className="applogoother" alt="Logo" style={{ height: 40 }} />
      </NavLink>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon 
          icon={isOpen ? faClose : faBars} 
          style={{ fontSize: 26, color: '#333', cursor: 'pointer', marginRight: 10 }} 
          onClick={toggleSideNav} 
        />
      </div>

      {isOpen && (
        <div className="sideNavigation" style={{
          position: 'absolute', top: '58px', left: 0, width: '100%', backgroundColor: 'white',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)', zIndex: 999, transition: '0.4s ease',
          padding: '20px 0px'
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {renderStatusOptions().map((item, index) => (
              <div 
                key={index} 
                style={{ textDecoration: 'none', color: '#333', fontSize: 16, fontWeight: 'bold',
                         padding: '10px 15px', borderBottom: '1px solid #ddd', cursor: 'pointer' }}
                onClick={() => { handleStatusUpdate(item.link); }}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Tracking Modal */}
      {showTrackingModal && (
        <div className="trackingModal" style={{
          position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
          <div style={{
            backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px', width: '100%',
            boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
          }}>
            <h3>Enter Tracking Details</h3>
            <label>
              Tracking URL:
              <input 
                type="url" 
                value={trackingUrl} 
                onChange={(e) => setTrackingUrl(e.target.value)} 
                placeholder="Enter tracking URL"
                style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '4px' }}
              />
            </label>
            <label>
              Tracking ID:
              <input 
                type="text" 
                value={trackingId} 
                onChange={(e) => setTrackingId(e.target.value)} 
                placeholder="Enter tracking ID"
                style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '4px' }}
              />
            </label>
            <button onClick={handleDispatch} style={{
              padding: '10px 20px', backgroundColor: '#fe4040', color: 'white', border: 'none',
              borderRadius: '4px', cursor: 'pointer'
            }}>
              Dispatch Order
            </button><br></br>
            <button onClick={()=>{setShowTrackingModal(false)}} style={{
              padding: '10px 20px', backgroundColor: '#fe4040', color: 'white', border: 'none',
              borderRadius: '4px', cursor: 'pointer',marginTop:20
            }}>
             Cancel Dispatch
            </button>
          </div>
        </div>
      )}

      {/* Invoice Display */}
      {renderInvoice()}
    </div>
  );
};

export default ChatHeader;
