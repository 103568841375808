import React ,{useEffect, useState}from 'react'
import Header from './Header'
import '../css/from.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { faArrowDown, faBars, faCancel, faClose, faCoffee, faPagelines,faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux';

const Verification=()=> {
const userData=useSelector(state=>state.UserData)

const navigate = useNavigate();
const { state } =useLocation();
const [email,setEmail]=useState("")
const [hasError,setHasError]=useState(false)
const [errorMsg,setErrormsg]=useState()
const [isReady,setISReady]=useState(false)
const [disbled,setIsDisabled]=useState(false)
const [usernamenew,setUsernamenew]=useState()
const [instausername,setInstausername]=useState("")
const [interest,setInterest]=useState([]);
const [isValidEmail,setValidEmail]=useState(false)
const [isEnteringEmail,setEnterinEmail]=useState(false)
useEffect(()=>{
if(userData.data.length) setEmail(userData.data[0].username)
else
{
navigate('/profile')
}

},[userData])
const submitVerifcation=()=>{
setIsDisabled(false)
setHasError(false)
const payload={
interest:interest,
email:email,
instagramUsername:instausername
}
if(email=="" || instausername=="" || interest.length==0) 
{
alert("All Field Required")
}
else
{
fetch(`https://justscroll.in/user/submitVerification`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',  
},
body:JSON.stringify(payload),
})
.then(async res => { 
setIsDisabled(false)
const jsonRes=await res.json()
if(jsonRes.status==200){
setHasError(true)
setErrormsg(`Your  Request Was submitted successfully. Please Text @justscroll_customer_support On Instagram From this account ${instausername}\n  Refrence no:\n`+jsonRes.data._id)
}
else if(jsonRes.status==411)
{
setHasError(true)
setErrormsg("No User Found with this username, please try again with valid username!")
}
else if(jsonRes.status==409)
{
setHasError(true)
setErrormsg("There is Already a pending request Please wait!")
}
else if(jsonRes.status==302)
{
setHasError(true)
setErrormsg("Your profile isn't update!")
}
else
{
setHasError(true)
setErrormsg("Something Went Wrong !! Please try again later")
}
})
.catch(err=>
{
setIsDisabled(false)
setHasError(true)
setErrormsg("Something Went Wrong !! Please try again later")
})

}

}

useEffect(()=>{
if(state?.email)
{
setISReady(true)

//Fill in everything
setEmail(state.email)
setInstausername(state.instagramUsername)

setInterest(state.interest)
const element = document.getElementById("cardForm");
element.scrollIntoView();
}

},[])
return (
<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
  <Header />
  
  {userData.data[0]?.isCreator ? (
    <div style={{ marginTop: 65, textAlign: 'center' }}>
      <FontAwesomeIcon icon={faCheckCircle} fontSize={100} color='purple' />
      <p style={{ fontSize: 20, fontWeight: 500, fontFamily: 'Poppins' }}>You are a verified content creator</p>
    </div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 65 }}>
      
      <p style={{
        fontSize: 30, fontWeight: 600, fontFamily: 'Poppins', color: '#272D4E', textAlign: 'center', marginBottom: 20,
      }}>
        Justscroll Creator Verification Portal
      </p>
      
      <div className="card" style={{
        marginTop: 10, borderRadius: 20,textAlign: 'center', 
        backgroundColor: '#f7f7f7', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
      }}>
        <span style={{
          color: 'purple', fontSize: 18, display: 'flex', alignItems: 'center', justifyContent: 'center',
          fontFamily: 'Montserrat'
        }}>
          <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 10, fontSize: 20, color: 'purple' }} />
          Grab Your Purple Tick Today
        </span>
        <img src="creator_1.png" alt="Avatar" style={{ width: '100%', borderRadius: 10, marginTop: 10 }} />
      </div>

      {/* Step 1 */}
      <div className="card" style={{
        marginTop: 20, borderRadius: 20, textAlign: 'center', 
        backgroundColor: '#f7f7f7', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
      }}>
        <h3 style={{ color: '#272D4E', fontFamily: 'Roboto' }}>Step 1</h3>
        <p style={{
          fontSize: 22, fontWeight: 600, fontFamily: 'Montserrat', color: 'black',
          marginBottom: 15
        }}>
          You should have an Instagram account with the following:
        </p>
        <p style={{
          fontSize: 16, fontWeight: 400, fontFamily: 'Poppins', color: '#272D4E',
          marginBottom: 10
        }}>Account at least 1 Month Old.</p>
        <p style={{
          fontSize: 16, fontWeight: 400, fontFamily: 'Poppins', color: '#272D4E',
          marginBottom: 10
        }}>With a Minimum of 5 Reels & 50 Followers.</p>
      </div>

      {/* Step 2 */}
      <div className="card" style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30, borderRadius: 20, 
        textAlign: 'center', backgroundColor: '#f7f7f7', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
      }} id='cardForm'>
        <h3 style={{ color: '#272D4E', fontFamily: 'Roboto' }}>Step 2</h3>
        <p style={{
          fontSize: 22, fontWeight: 600, fontFamily: 'Montserrat', color: 'black',
          marginBottom: 15
        }}>
          Fill This Creator Verification Form
        </p>

        {hasError && (
          <p style={{ color: 'blue', fontSize: 16, padding: 10, fontWeight: 600, fontFamily: 'Montserrat' }}>
            {errorMsg}
          </p>
        )}

        <input
          type="text" placeholder='Enter Your Justscroll Username' style={{
            maxWidth: 500, padding: 10, marginBottom: 10, borderRadius: 10, 
            border: '1px solid #ccc', fontSize: 16, outline: 'none'
          }}
          className='formText formField' value={email}
          onChange={(e) => {
            setEnterinEmail(true);
            setEmail(e.target.value);
          }}
          id='email'
        />

        <input
          type="text" placeholder='Enter Your Instagram Username' style={{
            maxWidth: 500, padding: 10, marginBottom: 10, borderRadius: 10,
            border: '1px solid #ccc', fontSize: 16, outline: 'none'
          }}
          className='formText formField' value={instausername}
          onChange={(e) => setInstausername(e.target.value)}
          id='instagramUsername'
        />

        <p style={{
          fontSize: 12, fontWeight: 'bolder', color: '#272D4E', marginBottom: 10,
          textAlign: 'center', fontFamily: 'Poppins'
        }}>
          Please Only Write Down Your Username! @ Or Link won't be accepted.
        </p>

        <button style={{
          backgroundColor: 'orange', color: 'white', padding: '10px', width: '80%',
          borderRadius: 10, border: 'none', marginBottom: 20, cursor: 'pointer'
        }}
        onClick={() => {
          const email = document.getElementById('email').value;
          const instagramUsername = document.getElementById('instagramUsername').value;
          navigate('/Interest', { state: { email, instagramUsername } });
        }}>
          Select Content Category
        </button>

        <p style={{
          fontSize: 12, fontWeight: 600, fontFamily: 'Montserrat', color: '#272D4E',
          marginBottom: 20, textAlign: 'center'
        }}>
          Please Only Select Category That You Actually Create Content In. Your Request Will Be Rejected If Your Profile Fails To Match The Category!
        </p>

        <button
          disabled={disbled}
          className='button' style={{
            backgroundColor: '#A91B60', color: 'white', padding: '10px', width: '80%',
            borderRadius: 10, border: 'none', cursor: 'pointer'
          }}
          onClick={() => submitVerifcation()}
        >
          Submit
        </button>
      </div>

      {/* Step 3 */}
      <div className="card" style={{
        marginTop: 30, borderRadius: 20, textAlign: 'center', 
        backgroundColor: '#f7f7f7', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
      }}>
        <h3 style={{ color: '#272D4E', fontFamily: 'Roboto' }}>Step 3</h3>
        <span style={{
          fontSize: 22, fontWeight: 600, fontFamily: 'Montserrat', color: 'black', marginBottom: 10,
        }}>
          Verify That It's You.
        </span>

        <p style={{
          fontSize: 16, fontWeight: 400, fontFamily: 'Poppins', color: '#272D4E',
          marginBottom: 10
        }}>
          To validate that the Instagram account belongs to you, text us on Instagram with the reference number that you received above.
        </p>

        <a href='https://www.instagram.com/justscroll_customer_support' style={{ color: 'red', fontSize: 16 }}>
          @justscroll_customer_support
        </a>
      </div>
    </div>
  )}
</div>

)
}

export default Verification