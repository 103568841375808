import React from 'react'
import Header from './Header'
import IMG1 from'../image/voteselect.png'
import IMG2 from'../image/votecast.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faClose, faComment, faShare, faTag, faVoteYea } from '@fortawesome/free-solid-svg-icons'


const HowToVote = (props) => {
  return (
    <div style={{
      display: 'flex',
      width: '90%',
      maxWidth: '500px',
      height: 'auto',
      maxHeight: '60vh',
      alignSelf: 'center',
      overflowY: 'scroll',
      position: 'fixed',
      backgroundColor: '#f9f9f9',
      bottom: 0,
      zIndex: 1100,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      padding: '20px',
      flexDirection: 'column',
    
    }}>
      <div style={{
        display: 'flex',
        alignSelf: 'flex-end',
        padding: '10px',
        backgroundColor: '#f8f8f8',
        borderRadius: '8px',
        position:'fixed',
      
      }}>
        <FontAwesomeIcon icon={faClose} style={{ fontSize: 26, color: '#333' }}  onClick={()=>{
          props.closeHowTo()
        }}/>
      </div>
    
      <div style={{
        backgroundColor: '#f8f2f2',
        maxWidth: '500px',
        margin: '0px auto',
        padding: 10,
        textAlign: 'center',
        marginBottom: 20
      }}>
        <h3 style={{ fontSize: 22 }}>How to Vote</h3>
        <p style={{ marginBottom: 10 }}>Learn what each icon means and how to interact with the voting options.</p>
    
        {/* Icon Descriptions */}
        <div style={{ textAlign: 'left', fontWeight: 500 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
            <FontAwesomeIcon icon={faVoteYea} style={{ marginRight: 10, color: 'black' }} />
            <span>Select: Click to select a post for voting.</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
            <FontAwesomeIcon icon={faCancel} style={{ marginRight: 10, color: '#fe4040' }} />
            <span>Disselect: Click to remove a selected post from your vote list.</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
            <div style={{
              width: 24, height: 6, backgroundColor: '#eee', marginRight: 10,
              overflow: 'hidden', position: 'relative', top: -2
            }}>
              <div style={{
                width: `75%`,
                height: `100%`,
                backgroundColor: 'green'
              }} />
            </div>
            <span>Winning Probability: Shows the chance of the post winning based on votes.</span>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
            <span style={{ fontWeight: 800, fontSize: 14, marginRight: 10, color: 'black' }}>123</span>
            <span>Votes: Displays the current number of votes this post has received.</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faShare} style={{ marginRight: 10, color: 'black' }} />
            <span>Share: Click to share this post with others via social media. Everytime you share a post via link that particular post will be displayed on top when user visits the page from that link.</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
          <FontAwesomeIcon icon={faTag} style={{fontSize:16,color:'black',marginRight:10}}/>
          <span >Tag content : Click To tag a content in comment user when click on tag,
             the page will scroll down to, where the content is. </span>
          </div>
        </div>
        <div
        style={{
          backgroundColor: '#f5f5f5',
          borderRadius: '10px',
          padding: '15px',
          marginBottom: '20px',
        }}
      >
        {/* Voting Instructions */}
        <h3 style={{ fontSize: '22px', color: '#333', marginBottom: '10px' }}>How to Vote</h3>
        <img src={IMG1} alt="How to select" style={{ maxWidth: '100%', borderRadius: '10px', marginBottom: '15px' }} />
        <p style={{ fontSize: '16px', color: '#555', lineHeight: '1.5', marginBottom: '15px' }}>
          Click on "Select" as shown in the image above. Once you have selected 3 entries, the "Cast Vote" button will
          appear at the bottom of the screen.
        </p>
        <img src={IMG2} alt="Cast Vote button" style={{ maxWidth: '100%', borderRadius: '10px', marginBottom: '15px' }} />
        <h4 style={{ fontSize: '20px', color: '#333' }}>Click on the "Cast Vote" button as shown above.</h4>

        {/* Voting Rules */}
        <h3 style={{ fontSize: '22px', color: '#333', marginTop: '20px', marginBottom: '10px' }}>Voting Rules</h3>
        <ol style={{ paddingLeft: '20px', fontSize: '16px', color: '#555', lineHeight: '1.6' }}>
          <li style={{ marginBottom: '10px' }}>
            Every user has 3 votes per event and must use all 3 votes for their submission to count.
          </li>
          <li style={{ marginBottom: '10px' }}>
            An influencer serves as the chief judge for each event and holds 30% of the voting power.
            <p style={{ color: '#fe4040', fontSize: '15px', marginTop: '5px' }}>
              For instance, in an event with 10 votes, the influencer's vote will count as 3 votes.
            </p>
          </li>
          <li style={{ marginBottom: '10px' }}>
            The top 3 entries with the most votes in any event will be announced as winners.
          </li>
        </ol>
      </div>
      </div>
    </div>
    
  )
}

export default HowToVote

