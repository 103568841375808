import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import IMG from'../image/logo-trans.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Spinner from './Spinner'
import { fetchUrl } from '../Contsant'
import IMGLOGO from'../image/logo-trans.png'
import LazyImage from './LazyImage'
import { faGavel, faMedal, faVoteYea } from '@fortawesome/free-solid-svg-icons'

const Winner = () => {
    const location=useLocation()
    const [winnerannounced,setWinnerannounced]=useState(false)
    const [isFetchingdata,setisFetchingdata]=useState(true)
    const [currentEventdata,setcurrentEventData]=useState([])
    const [showInfluencerPick,setShowInfluencerPick]=useState(false)
const eventId=useParams()

    useEffect(()=>{
        if(location.state==null)
        {
            
            fetch(`${fetchUrl}webfn/getAllWinner/${eventId.eventId}`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                },
               
            })
            .then(async res=>{
                const jsonRes=await res.json()
                if(jsonRes.statusCode==211)
                {
                    setWinnerannounced(true)
                    setcurrentEventData(JSON.parse(jsonRes.data))
                    setisFetchingdata(false)
                }
                else
                {
                    setisFetchingdata(false)

                }
            })
            .catch(err=>{

            })
        }
        else
        {
            if(location.state.winner.length)
            {
               setWinnerannounced(true)
               fetch(`${fetchUrl}webfn/getAllWinner/${location.state._id}`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                },
               
            })
            .then(async res=>{
                const jsonRes=await res.json()
                if(jsonRes.statusCode==211)
                {
                    setcurrentEventData(JSON.parse(jsonRes.data))
                    setisFetchingdata(false)
                }
                else
                {
                    setisFetchingdata(false)

                }
            })
            .catch(err=>{

            })
            }
            
        }
    },[])
  return (
    <div style={{ display: 'flex', flexDirection: 'column', zIndex: 100, width: '100%', height: '100vh' }}>
    <div className='appHeader1' style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f7f7f7', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
      <NavLink style={{ textDecoration: 'none', color: '#fe4040' }} to="/">
        <img src={IMG} className='applogoother' alt="App Logo" style={{ height: 40, marginRight: 20 }} />
      </NavLink>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {showInfluencerPick ? (
          <div style={{
            padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', cursor: 'pointer'
          }} onClick={() => { setShowInfluencerPick(false) }}>
            <FontAwesomeIcon icon={faVoteYea} style={{ fontSize: 20, color: '#333' }} />
            <span style={{ fontWeight: 500, fontSize: 14 }}>Public Vote</span>
          </div>
        ) : (
          <div style={{
            padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', cursor: 'pointer'
          }} onClick={() => { setShowInfluencerPick(true) }}>
            <FontAwesomeIcon icon={faGavel} style={{ fontSize: 20, color: '#333' }} />
            <span style={{ fontWeight: 500, fontSize: 14 }}>Influencer Choice</span>
          </div>
        )}
      </div>
    </div>
  
    <div style={{
      display: 'flex', marginTop:60, alignItems: 'center', padding: 20, flexDirection: 'column', minHeight: '80vh'
    }}>
      {winnerannounced ? (
        isFetchingdata ? <Spinner /> : (
          <div style={{
            display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '600px', alignItems: 'center', paddingBottom: 40
          }}>
            {showInfluencerPick ? (
              currentEventdata.map((item) => item?.isVoted?.length ? (
                <div className="card" style={{
                  marginBottom: 30, borderRadius: 10, boxShadow: '0 4px 8px rgba(0,0,0,0.1)', padding: 20
                }} key={item._id}>
                  <div style={{
                    display: 'flex', alignItems: 'center', marginBottom: 10, width: '100%'
                  }}>
                    {item.creatorData[0].profilePic ? (
                      <img
                        style={{ width: 36, height: 36, borderRadius: '50%' }}
                        src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${item.creatorData[0].profilePic}`}
                        alt="Creator Profile"
                      />
                    ) : (
                      <img
                        style={{ width: 36, height: 36, borderRadius: '50%' }}
                        src={IMGLOGO}
                        alt="Default Logo"
                      />
                    )}
                    <span style={{
                      fontSize: 14, marginLeft: 10, fontWeight: 600
                    }}>{item.creatorData[0].username}</span>
                  </div>
  
                  <LazyImage src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${item.fileUrl}`} aspectRatio={1} />
  
                  <div style={{
                    display: 'flex', justifyContent: 'space-between', width: '100%', paddingTop: 10
                  }}>
                    {/* Rank and Medal Icons */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {item.position === 1 && <FontAwesomeIcon icon={faMedal} style={{ color: '#FFD700', marginRight: 5 }} />} {/* Gold Medal */}
                      {item.position === 2 && <FontAwesomeIcon icon={faMedal} style={{ color: '#C0C0C0', marginRight: 5 }} />} {/* Silver Medal */}
                      {item.position === 3 && <FontAwesomeIcon icon={faMedal} style={{ color: '#cd7f32', marginRight: 5 }} />} {/* Bronze Medal */}
                      <p style={{ fontSize: 14, fontWeight: 600 }}>Rank: {item.position}</p>
                      {/* Hurray for Rank 1 */}
                      {item.position === 1 && <span style={{ fontSize: 16, marginLeft: 10, color: '#fe4040', fontWeight: 'bold' }}>Hurray!</span>}
                    </div>
                    <p style={{ fontSize: 14, fontWeight: 600 }}>Votes: {item.totalVote}</p>
                  </div>
                </div>
              ) : null)
            ) : (
              currentEventdata.map((item) => item?.contentType === "ADVERT" ? (
                <a href={item.actionLink} target='_blank' rel='noopener noreferrer' className="card" style={{
                  marginBottom: 30, borderRadius: 10, boxShadow: '0 4px 8px rgba(0,0,0,0.1)', textDecoration: 'none'
                }} key={item._id}>
                  <LazyImage src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/advertisement/${item.banner}`} aspectRatio={'16/9'} />
                  <div style={{
                    display: 'flex', alignItems: 'center', paddingTop: 10, paddingLeft: 10
                  }}>
                    {item.sponsorData[0].profilePic ? (
                      <img
                        style={{ width: 36, height: 36, borderRadius: '50%' }}
                        src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${item.sponsorData[0].profilePic}`}
                        alt="Sponsor Profile"
                      />
                    ) : (
                      <img
                        style={{ width: 36, height: 36, borderRadius: '50%' }}
                        src={IMGLOGO}
                        alt="Default Logo"
                      />
                    )}
                    <div style={{ marginLeft: 10 }}>
                      <span style={{ fontWeight: 600, fontSize: 14 }}>Sponsored by {item.sponsorData[0].username}</span>
                      <p style={{ fontSize: 14, color: '#666' }}>{item.title}</p>
                      <p style={{ fontSize: 14, color: '#999' }}>{item.discription}</p>
                    </div>
                  </div>
                </a>
              ) : (
                <div className="card" style={{
                  marginBottom: 30, borderRadius: 10, boxShadow: '0 4px 8px rgba(0,0,0,0.1)', padding: 20
                }} key={item._id}>
                  <div style={{
                    display: 'flex', alignItems: 'center', marginBottom: 10, width: '100%'
                  }}>
                    {item.creatorData[0].profilePic ? (
                      <img
                        style={{ width: 36, height: 36, borderRadius: '50%' }}
                        src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${item.creatorData[0].profilePic}`}
                        alt="Creator Profile"
                      />
                    ) : (
                      <img
                        style={{ width: 36, height: 36, borderRadius: '50%' }}
                        src={IMGLOGO}
                        alt="Default Logo"
                      />
                    )}
                    <span style={{
                      fontSize: 14, marginLeft: 10, fontWeight: 600
                    }}>{item.creatorData[0].username}</span>
                  </div>
  
                  <LazyImage src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/eventposts/${item.fileUrl}`} aspectRatio={1} />
  
                  <div style={{
                    display: 'flex', justifyContent: 'space-between', width: '100%', paddingTop: 10
                  }}>
                    {/* Rank and Medal Icons */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {item.position === 1 && <FontAwesomeIcon icon={faMedal} style={{ color: '#FFD700', marginRight: 5 }} />} {/* Gold Medal */}
                      {item.position === 2 && <FontAwesomeIcon icon={faMedal} style={{ color: '#C0C0C0', marginRight: 5 }} />} {/* Silver Medal */}
                      {item.position === 3 && <FontAwesomeIcon icon={faMedal} style={{ color: '#cd7f32', marginRight: 5 }} />} {/* Bronze Medal */}
                      <p style={{ fontSize: 14, fontWeight: 600 }}>Rank: {item.position}</p>
                      {/* Hurray for Rank 1 */}
                      {item.position === 1 && <span style={{ fontSize: 16, marginLeft: 10, color: '#fe4040', fontWeight: 'bold' }}>Hurray!</span>}
                    </div>
                    <p style={{ fontSize: 14, fontWeight: 600 }}>Votes: {item.totalVote}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        )
      ) : (
        <p style={{ fontSize: 18, fontWeight: 600 }}>Winners for this event have not yet been announced!</p>
      )}
    </div>
  </div>
  
  
  )
}

export default Winner