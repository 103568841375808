import React, { useState } from 'react'
import Header from './Header'
import { NavLink, useNavigate, useNavigation } from 'react-router-dom'
import {fetchUrl} from '../Contsant/index'
import { isDisabled } from '@testing-library/user-event/dist/utils'
import Spinner from './Spinner'

const ChooseUsername = () => {
    const [username,setUsername]=useState("")
  const [clickable,setClickable]=useState(false);
  const [haserror,setHasError]=useState({error:false,errormsg:''})
 const navigate=useNavigate()
    const checkUsername=()=>{
      setHasError({error:false,errormsg:''})
      if(username.length<5)
        {
        
         alert("Username: Add Atleast 5 Alphabets.")
        }
        else
        {
         if(isUserNameValid(username))
         {
          setClickable(true)
          fetch(`${fetchUrl}auth/fetchusername?username=`+username, {
            method: 'get',
         })
         .then(async res => { 
          setClickable(false)
          const jsonRes = await res.json();
          if(jsonRes.statusCode==200)
            {
             
              navigate("/password",{state:{username:username}})
              
            }
            else if(jsonRes.statusCode==409)
              {
                
              
               setHasError({error:true,errormsg:"Username Not Available"})
               setClickable(false)
        
              }
              else
              {
               
                setHasError({error:true,errormsg:"Something Went Wrong On The Server !"})
                
                setClickable(false)
              }
         })
         .catch(err => {
          setHasError({error:true,errormsg:"Something Went Wrong Please Try Again!"})
          
           setClickable(false)
           
        });
         }
         else
         {
          setHasError({error:true,errormsg:"Usernames can only have:Letters (a-z) Numbers (0-9) Dots (.) Underscores (_)"})

     setClickable(false)
         }
        }
    }
    function isUserNameValid(username) {
      /* 
        Usernames can only have: Lowercase Letters (a-z) Numbers (0-9) Dots (.) Underscores (_)
      */
      const res = /^[a-zA-Z0-9_\.]+$/.exec(username);
      const valid = !!res;
      console.log(valid)
      return valid;
    }
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', minHeight: '100vh' }}>
    <Header />
    <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', 
      padding: 30, maxWidth: 500, width: '100%', margin: '50px auto', boxSizing: 'border-box', 
      backgroundColor: '#f7f7f7', borderRadius: 10, boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    }}>
      <h2 style={{ color: '#66003a', fontFamily: 'Poppins', textAlign: 'center' }}>Choose Username</h2>
      <p style={{ fontFamily: 'Poppins', textAlign: 'center', color: '#333' }}>You can always change it later!</p>
  
      {haserror?.error && (
        <p style={{
          color: 'white', backgroundColor: '#6e0c71', padding: 10, 
          margin: '10px 0', textAlign: 'center', borderRadius: 10
        }}>{haserror.errormsg}</p>
      )}
  
      <div style={{
        display: 'flex', border: '1px solid #ccc', borderRadius: 10, padding: 10, 
        alignItems: 'center', marginBottom: 20
      }}>
        <input type='text' placeholder='Enter Username' style={{
          width: '100%', border: 'none', outline: 'none', color: '#333', 
          padding: 12, fontSize: 16, height: 20
        }} onChange={(e) => setUsername(e.target.value)} />
      </div>
  
      <button onClick={checkUsername} disabled={clickable} style={{
        padding: 12, backgroundColor: '#4db6ac', color: 'white', borderRadius: 10,
        border: 'none', width: 140, margin: '20px auto', cursor: 'pointer',
        maxWidth: 200, textAlign: 'center', fontSize: 16,
      }}>
        {clickable ? <Spinner /> : 'Next'}
      </button>
  
      <NavLink to="/login" style={{ 
        padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', 
        marginTop: 10, textDecoration: 'none',
      }}>
        <span style={{
          backgroundColor: '#fe4040', padding: 10, color: 'white', fontSize: 16, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          borderRadius: 10,width:120
        }}>Login?</span>
      </NavLink>
    </div>
  </div>
  
  )
}

export default ChooseUsername