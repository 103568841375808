import React from 'react'
import Header from './Header'
import IMG1 from'../image/brands.png'
import { NavLink } from 'react-router-dom'

const EventandBrands = () => {
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
    <Header />
    <div style={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40, maxWidth: '700px', 
      margin: '40px auto', padding: '20px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)', borderRadius: '10px', backgroundColor: '#fff'
    }}>
      <img src={IMG1} alt="Avatar" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} />
  
      <h3 style={{
        padding: '10px', fontSize: '24px', fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: '#333', textAlign: 'center'
      }}>
        How JustScroll Events Can Drive Impactful Brand Exposure
      </h3>
  
      <p style={{
        padding: '10px', textAlign: 'left', fontWeight: 500, fontFamily: 'Poppins, sans-serif', color: '#555', marginTop: '-10px'
      }}>
        In the fast-paced world of digital marketing, brands are always searching for innovative ways 
        to connect with their audience. JustScroll offers an exciting new avenue through JustScroll Events—publicly 
        voted competitions hosted by influencers across various niches like photography, music, art, and more. But how can these events benefit your brand? Let’s explore.
      </p>
  
      <ol style={{ textAlign: 'left', padding: '10px', marginLeft: '0', color: '#555' }}>
        <li style={{ fontWeight: 800, fontSize: '20px', marginBottom: '10px' }}>Exclusive Advertising Slots for Maximum Exposure</li>
        <p style={{ marginBottom: '20px' }}>
          At JustScroll, we believe in giving brands the spotlight they deserve. Each JustScroll event comes with five dedicated advertising slots, 
          offering your brand complete ownership of the advertising space. From custom banners to integrated brand messaging, your ads will be 
          prominently featured throughout the event, ensuring your message reaches a highly engaged audience.
        </p>
  
        <li style={{ fontWeight: 800, fontSize: '20px', marginBottom: '10px' }}>Targeted Audience Engagement</li>
        <p style={{ marginBottom: '20px' }}>
          JustScroll Events are niche-specific, meaning your brand will be directly engaging with an audience passionate about the content. 
          Whether you’re promoting products that cater to artists, musicians, or photographers, your ads will be seen by people who care about your industry. 
          By aligning your brand with influencers and content creators who speak to your target demographic, 
          you ensure higher engagement rates and deeper connections with potential customers.
        </p>
  
        <li style={{ fontWeight: 800, fontSize: '20px', marginBottom: '10px' }}>Trusted Endorsement from Influencers</li>
        <p style={{ marginBottom: '20px' }}>
          Influencers are key to the success of JustScroll Events, and their influence can amplify your brand message. When an influencer hosts an event 
          and supports your product, their followers trust their recommendations, creating a sense of authenticity and credibility around your brand.
        </p>
  
        <li style={{ fontWeight: 800, fontSize: '20px', marginBottom: '10px' }}>Extended Reach Through Cross-Promotion</li>
        <p style={{ marginBottom: '20px' }}>
          Influencers hosting JustScroll events often promote their involvement across multiple platforms, including YouTube, Instagram, and Twitter. 
          This cross-promotion means that your brand gets exposure not only on the JustScroll platform but also across various social media channels, 
          extending your brand’s reach far beyond the event itself.
        </p>
  
        <li style={{ fontWeight: 800, fontSize: '20px', marginBottom: '10px' }}>Measurable Results and Data Insights</li>
        <p style={{ marginBottom: '20px' }}>
          With JustScroll, you don’t just sponsor an event and hope for the best—you get measurable results. 
          Our platform provides detailed analytics on engagement, voting patterns, and ad performance, allowing you to track the effectiveness of your campaigns.
        </p>
  
        <li style={{ fontWeight: 800, fontSize: '20px', marginBottom: '10px' }}>Position Your Brand as a Supporter of Creativity</li>
        <p>
          JustScroll Events are all about celebrating creativity, competition, and community. By sponsoring these events, 
          your brand positions itself as a champion of creativity and a supporter of rising talent. 
          This association with a positive, creative platform can enhance your brand image and build loyalty among customers who value innovation and artistic expression.
        </p>
      </ol>
  
      <h2 style={{ padding: '10px', fontSize: '22px', fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', color: '#333' }}>
        Sponsor An Event With JustScroll?
      </h2>
  
      <NavLink to="/contactus" style={{
        fontSize: '20px', fontWeight: 'bold', color: 'white', backgroundColor: '#fe4040', padding: '12px 24px',
        borderRadius: '10px', textDecoration: 'none', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)', textAlign: 'center'
      }}>
        Contact Us!
      </NavLink>
    </div>
  </div>
  
  )
}

export default EventandBrands