import React, { useEffect, useState } from 'react'
import Header from './Header'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { fetchUrl } from '../Contsant'
import Spinner from './Spinner'

const VerifyEmail = () => {

const location=useLocation()
const [username,setusername]=useState(null)
const navigate=useNavigate()
const [code,setCode]=useState("")
const[isLoading,setIsloading]=useState(false)
const[hasError,setHasError]=useState({status:false,message:""})
useEffect(()=>{
if(location.state==null)
{
navigate("/profile")
}
else
{

setusername(location.state?.username)


}
},[])
const verifyEmailCode=()=>{


setIsloading(true)
setHasError({status:false})
const payload = {
username,
code
};
fetch(`${fetchUrl}auth/verifyCodePassword`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',
},
body: JSON.stringify(payload),
})
.then(async res => { 
try {
const jsonRes = await res.json();

if (jsonRes.statusCode !== 200) {
//Show Error
setIsloading(false)
setHasError({status:true,message:jsonRes.message}) 

} else {
setIsloading(false)
navigate("/changepassword",{state:{username:username,code:code}})
}
} catch (err) {
console.log(err);
setHasError({status:true,message:"Something Went Wrong try again later"}) 
setIsloading(false)
};
})
.catch(err => {
console.log(err);
setHasError({status:true,message:"Something Went Wrong try again later"}) 
setIsloading(false)

});
}
return (
<div style={{display:'flex',flex:1,flexDirection:'column',}}>
<Header />
<div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 30, 
      maxWidth: 500, width: '100%', margin: '50px auto', boxSizing: 'border-box', 
      backgroundColor: '#f7f7f7', borderRadius: 10, boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      minHeight: '100vh'
    }}>

<p>Enter 6 Digit Code Sent To Email!</p>
{hasError.status && (
        <p style={{
          color: 'white', backgroundColor: '#d9534f', padding: 10, margin: '10px 0',
          textAlign: 'center', borderRadius: 10
        }}>{hasError.message}</p>
      )}

<div style={{display:'flex',marginBottom:20,border:'1px solid #333333',borderRadius:12,padding:8}}>
<input type='number' placeholder='Enter code ' style={{
            width: '100%', border: 'none', outline: 'none', color: '#333', 
            padding: 12, fontSize: 16, height: 20
          }} 
onChange={(e)=>{setCode(e.target.value)}} maxLength={6}/>
</div>
<div style={{display:'flex',padding:10,flexDirection:'column',alignItems:'center',marginBottom:20}}>
<button onClick={()=>{verifyEmailCode()}} disabled={isLoading}
style={{
    padding: 12, backgroundColor: '#4db6ac', color: 'white', borderRadius: 10,
    border: 'none', width: '50%', margin: '20px auto', cursor: 'pointer',
    maxWidth: 200, textAlign: 'center', fontSize: 16, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease-in-out'
  }}>
{isLoading?<Spinner />:'Verify'}</button>

</div>
</div>
</div>
) 
}

export default VerifyEmail