import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { equalTo, get, getDatabase, orderByChild, query, ref } from 'firebase/database';
import { auth } from '../firebase';
import BottomTab from './BottomTab';
import { Link } from 'react-router-dom';
import { createOrder } from '../Redux/actions/Orders';
import IMG from'../image/logo-trans.png'

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userdata = useSelector((state) => state.UserData);
  const businessId = userdata?.data[0]?._id;
  const token = localStorage.getItem('googleToken');
  const order = useSelector(state => state.Orders);
  const dispatch=useDispatch()
  const userId = userdata.data[0]?._id; // Current user ID
  useEffect(() => {
    setError(null);
    if (!token || !businessId) {
      setError('User is not authenticated');
      setLoading(false);
      return;
    }

    const fetchTokenAndOrders = async () => {
      try {
        const idTokenResult = await auth.currentUser?.getIdTokenResult(token);
        if (idTokenResult) {
          await fetchOrders();
        } else {
          setError('Authentication failed');
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setError('Error verifying token');
        setLoading(false);
      }
    };

    fetchTokenAndOrders();
  }, [businessId, token]);
  useEffect(()=>{
    setOrders(order.orders)
  },[order])

  const fetchOrders = async () => {
    setError(null);
    if(order.orders.length)
    {
      
      setLoading(false);
      setOrders(order.orders);
    }
    else
    {
      setLoading(true);
      try {
        const db = getDatabase();
        const ordersRef = ref(db, 'orders');
        const ordersQuery = query(ordersRef, orderByChild('orderdFrom/userId'), equalTo(businessId));
        const snapshot = await get(ordersQuery);
  
        if (snapshot.exists()) {
          const fetchedOrders = Object.values(snapshot.val());
          dispatch(createOrder(fetchedOrders))
          setOrders(fetchedOrders);
        } else {
          console.log('No orders found');
        }
      } catch (err) {
        console.log(err);
        setError('Failed to fetch orders');
      } finally {
        setLoading(false);
      }
    }

  };

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', maxWidth: '500px', margin: '0px auto', width: '100%' }}>
      {loading ? (
        <p>Loading orders...</p>
      ) : error ? (
        <p>{error}</p>
      ) : orders.length === 0 ? (
        <p>No orders found.</p>
      ) : (
        <div style={styles.scrollContainer}>
          {orders.map((order, index) => {
            const lastMessage = order.message[order.message.length - 1];
            const isUnseen = lastMessage?.to === userId && !lastMessage?.isSeen;
            return (
              <Link
                key={index}
                style={{
                  ...styles.orderContainer,
                  backgroundColor: isUnseen ? '#f9f9ff' : '#fff', // Highlight background for unseen messages
                }}
                to={`/chat/${order.orderrdBy.userId}`}
                state={{ createOrder: order }}
              >
                <div style={styles.orderHeader}>
                  {order.orderrdBy.profile_pic==""?<img
                    src={IMG}
                    alt={order.orderrdBy.username}
                    style={styles.profileImage}
                  />: <img
                  src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${order.orderrdBy.profile_pic}`}
                  alt={order.orderrdBy.username}
                  style={styles.profileImage}
                />}
                 
                  <div style={styles.usernameContainer}>
                    <span style={styles.username}>{order.orderrdBy.username}</span>
                    <span style={{
                      ...styles.lastMessage,
                      fontWeight: isUnseen ? 'bold' : 'normal', // Bold text for unseen messages
                      color: isUnseen ? '#333' : '#555'          // Darker color for unseen messages
                    }}>
                      {order.message.length > 2
                        ? order.message[order.message.length - 1]?.text
                        : 'New Order Received!'}
                    </span>
                  </div>
                </div>
              </Link>
            )
          }
          
          )}
        </div>
      )}
      <BottomTab />
    </div>
  );
};

const styles = {
 
  scrollContainer: {
    flex: 1,
    overflowY: 'auto',
    marginBottom: '60px',
  },
  orderContainer: {
    borderBottom: '1px solid #ddd',
    padding: '10px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    width: '100%',
    boxSizing: 'border-box',
    textDecoration: 'none',
    color: 'black',
  },
  orderHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  profileImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: '10px',
  
  },
  usernameContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  username: {
    fontWeight: '500',
    fontSize: '1rem',
  },
  lastMessage: {
    fontSize: '0.8rem',
    width: '80%',
    maxWidth: '300px',
    color: '#555',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default OrdersPage;
