import React, { useState } from 'react'
import '../css/globalstyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faCheck, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
const Details = (props) => {
     const [showPayout,setShowPayout]=useState(false)
    const convertDate=(date)=>{
      const date1=new Date(date).toLocaleString()
      return date1;
    }
  return (
    <div style={{
      display: 'flex',
      width: '90%',
      maxWidth: '500px',
      height: 'auto',
      alignSelf: 'center',
      overflowY: 'scroll',
      position: 'fixed',
      backgroundColor: '#f9f9f9',
      bottom: 0,
      zIndex: 1100,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      padding: '20px',
      flexDirection: 'column',
    }}>
      {/* Header with close button */}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingBottom: '10px',
        borderBottom: '1px solid #ddd',
      }}>
        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Event Details</p>
        <FontAwesomeIcon icon={faTimes} style={{ fontSize: 26, color: '#666' }} onClick={props.updatedetails} />
      </div>
    
      {/* Event Details */}
      <div style={{ marginTop: '20px', textAlign: 'left' }}>
        <p style={{ marginBottom: '10px' }}>{props.event.description}</p>
        <p style={{ marginBottom: '10px' }}>Judged By: <strong>{props.event.influncerData[0]?.name}</strong></p>
        <p style={{ marginBottom: '10px' }}>Sponsored By: <strong>{props.event.sponsorData[0].username}</strong></p>
        <p style={{ marginBottom: '10px' }}>Category: <strong>{props.event.category}</strong></p>
    
        {/* Payout Information with Expandable Details */}
        <p style={{ marginBottom: '10px' }}>
          Payout: <strong>Rs.{props.event.payout}</strong>
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setShowPayout(!showPayout)} />
        </p>
        {showPayout && (
          <p style={{ marginLeft: '20px', fontSize: '14px', fontStyle: 'italic' }}>
            Unless  already mentioned,the winning amount will be distributed: 50% to Rank1, 30% to Rank2, 20% to Rank3.
          </p>
        )}
    
        {/* Other Event Information */}
        <p style={{ marginBottom: '10px' }}>Entry Fee: <strong>{props.event.entryfee ? props.event.entryfee : "FREE"}</strong></p>
        <p style={{ marginBottom: '10px' }}>Participants Allowed: <strong>{props.event.participentnum}</strong></p>
        <p style={{ marginBottom: '10px' }}>Voting Starts: <strong>{convertDate(props.event.startdate)}</strong></p>
        <p style={{ marginBottom: '10px' }}>Voting Ends: <strong>{convertDate(props.event.enddate)}</strong></p>
      </div>
    </div>
    
  )
}

export default Details