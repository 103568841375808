import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Header from './Header'
import BottomTab from './BottomTab'
import badge from '../image/playbadge.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import IMG from '../image/insta.jpeg'
import IMG1 from '../image/youtube.png'
import Spinner from './Spinner'
import { fetchUrl } from '../Contsant'
import IMG3 from'../image/logo-trans.png'


const Userprofile = (props) => {
let {userId}=useParams()
const location=useLocation()
const [userData,setuserData]=useState(null)
const [isLoading,setIsLoading]=useState(true)
function myFunc(){
  setTimeout(function () { console.log("They Are Bcck!!") }, 25);
window.location = `justscroll://app/${window.location.href.split("/")[3]}/${window.location.href.split("/")[4]}`;
}
useEffect(()=>{
  myFunc()
if(location.state==null)
{
     getAllUserData()
}
else
{
setuserData(location.state)
}
},[])
const getAllUserData=async()=>{
  try {
    fetch(`${fetchUrl}webfn/getAlluserData/${userId}`, {
       headers: { 
          'Content-Type': 'application/json',
         
      },
         })
         .then(async(res)=>{

          const jsonRes=await res.json()
          const userDatahere=JSON.parse(jsonRes?.userData)
          setuserData(userDatahere[0])
          setIsLoading(false)
         })
         .catch(err=>{
          setIsLoading(false)
       
         })
 } catch (error) {
    console.log(error)
    setIsLoading(false)

 } 
}
return (

<div style={{ display: 'flex', flex: 1, flexDirection: 'column',}}>
  {userData == null ? (
    isLoading ? (
      <Spinner />
    ) : (
      <div style={styles.errorContainer}>
        <h3>Something Went Wrong Please Try Again</h3>
        <button style={styles.retryButton} onClick={() => getAllUserData()} disabled={isLoading}>
          Retry
        </button>
      </div>
    )
  ) : (
    <div style={styles.profileWrapper}>
      {/* Cover Picture */}
      {userData.coverPic == undefined ? (
        <div style={styles.coverPlaceholder}></div>
      ) : (
        <div style={styles.coverImageWrapper}>
          <img
            src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/coverImage/${userData.coverPic}`}
            style={styles.coverImage}
          />
        </div>
      )}

      {/* Profile Picture */}
      <div style={styles.profileImageWrapper}>
        {userData.profilePic == undefined ? (
          <img src={IMG3} style={styles.profileImage} />
        ) : (
          <img
            src={`https://justscroll-s3-amazon.s3.ap-south-1.amazonaws.com/profilePicture/${userData.profilePic}`}
            style={styles.profileImage}
          />
        )}
      </div>

      {/* Username and Icons */}
      <div style={styles.userNameContainer}>
        <span style={styles.username}>@{userData.username}</span>
        {userData.isInfluencer ? (
          <FontAwesomeIcon icon={faCheckSquare} style={styles.influencerIcon} />
        ) : userData.isCreator ? (
          <FontAwesomeIcon icon={faCheckSquare} style={styles.creatorIcon} />
        ) : (
          ""
        )}
      </div>

      {/* Social Links */}
      <div style={styles.socialLinks}>
        {userData.instagram ? (
          <a href={userData.instagram} target="_blank" rel="noopener noreferrer">
            <img src={IMG} style={styles.socialIcon} alt="Instagram" />
          </a>
        ) : (
          ""
        )}
        {userData.youtube ? (
          <a href={userData.youtube} target="_blank" rel="noopener noreferrer">
            <img src={IMG1} style={styles.socialIcon} alt="YouTube" />
          </a>
        ) : (
          ""
        )}
      </div>

      {/* Name and Bio */}
      <div style={styles.nameBioContainer}>
        <span style={styles.name}>{userData.name}</span>
        <span style={styles.bio}>{userData.bio}</span>
      </div>

      {/* Stats Section */}
      <div style={styles.statsContainer}>
        <div style={styles.statItem}>
          <span style={styles.statValue}>{userData.scrollCount}</span>
          <span style={styles.statLabel}>Posts</span>
        </div>
        <div style={styles.statItem}>
          <span style={styles.statValue}>{userData.followerCount}</span>
          <span style={styles.statLabel}>Followers</span>
        </div>
        <div style={styles.statItem}>
          <span style={styles.statValue}>{userData.followingCount}</span>
          <span style={styles.statLabel}>Following</span>
        </div>
      </div>

      {/* App Promotion */}
      <div style={styles.appPromotion}>
        <p style={styles.appText}>You can Access Their Content Through Justscroll App</p>
        <a href="https://play.google.com/store/apps/details?id=com.justscroll" target="_blank" rel="noopener noreferrer">
          <img src={badge} style={styles.appBadge} alt="Google Play Store" />
        </a>
      </div>
    </div>
  )}
  <BottomTab />
</div>
)
}
const styles = {
  errorContainer: {
    display: 'flex',
    marginTop: 60,
    maxWidth: '500px',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 60,
  },
  retryButton: {
    width: '30%',
    height: 30,
    padding: 15,
    backgroundColor: '#fe4040',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  profileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '500px',
    margin:'0px auto',
    textAlign: 'center',
    marginBottom: 50,
  },
  coverImageWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '500px',
    height: 150,
    textAlign: 'center',
  },
  coverImage: {
    aspectRatio: '16/9',
    width: '100%',
  },
  coverPlaceholder: {
    width: '100%',
    maxWidth: '500px',
    height: 150,
    backgroundColor: '#fe4040',
  },
  profileImageWrapper: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: '#fe4040',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -40,
    marginLeft: 5,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  profileImage: {
    width: '100%',
    borderRadius: '50%',
    aspectRatio: 1,
  },
  userNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    marginTop: 10,
  },
  username: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  influencerIcon: {
    color: 'orange',
    fontSize: 22,
    marginLeft: 5,
  },
  creatorIcon: {
    color: 'violet',
    fontSize: 22,
    marginLeft: 5,
  },
  socialLinks: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  socialIcon: {
    width: 20,
    height: 20,
    marginLeft: 10,
  },
  nameBioContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  name: {
    fontWeight: 500,
  },
  bio: {
    marginLeft: 10,
    fontSize: 14,
    color: '#666',
    fontFamily:'Nunito',
    fontWeight:700
  },
  statsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    borderTop: '1px solid #333',
    borderBottom: '1px solid #333',
    marginTop: 20,
    paddingTop: 5,
    paddingBottom: 5,
  },
  statItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  statValue: {
    fontWeight: 500,
  },
  statLabel: {
    fontSize: 14,
  },
  appPromotion: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 30,
  },
  appText: {
    color: '#fe4040',
  },
  appBadge: {
    width: '70%',
  },
};
export default Userprofile