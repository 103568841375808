import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../css/buyout.css';
import { fetchUrl } from '../Contsant';
import Spinner from './Spinner';
import BottomTab from './BottomTab';
import { useSelector } from 'react-redux';
import { getDatabase, ref , set, push } from 'firebase/database';
import Header from './Header'

import { database } from "../firebase";
import { LoginUiPurchase } from './LoginUiPurchase';
import LoginUI from './LoginUI';

const BuyoutPage = () => {
  const location = useLocation();
  const [showLoginUi, setLoginUi] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const history = useNavigate();
  const params = useParams();
  const userData = useSelector(state => state.UserData);
  const accessToken = useSelector(state => state.Auth);
  const storedShippingInfo = localStorage.getItem('shippingInformation');
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    contact_number: '',
    shipping_address: '',
    Pincode: '',
    paymentMethod: 'COD',
  });

  const [book, setBook] = useState(location.state?.book); 
  const [bussinessData, setBusinessData] = useState(location.state?.bussinessData);
  const [hasState, setHasState] = useState(true); 
  const [hasError, sethasError] = useState(false);

  useEffect(() => {
    console.log(storedShippingInfo)
    if (storedShippingInfo) {
      setFormData(JSON.parse(storedShippingInfo));
    }
  }, []);

  const getBooks = async () => {
    setHasState(false);
    sethasError(false);
    fetch(`${fetchUrl}webfn/getproductById/${params.bookId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (res) => {
        const jsonRes = await res.json();
        if (jsonRes.statusCode === 211) {
          setBook(jsonRes.product);
          setBusinessData(jsonRes.getBusiness);
          sethasError(false);
        } else {
          sethasError(true);
        }
      })
      .catch((err) => {
        console.error('Error fetching product:', err);
        sethasError(true);
      });
  };

  useEffect(() => {
    if (!book) {
      getBooks();
    }
  }, [book, params.bookId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if( formData.full_name== '' ||
      formData.email =='' ||
      formData.contact_number== '' ||
      formData.shipping_address== '' ||
      formData.Pincode=='' 
    )
    {
      alert("Please ADD Shipping Address!!")
      return;
    }
    const thismoment = Date.now();
    const createOrder = {
      orderId: `${userData.data[0].username}${thismoment}`,
      orderedOn: thismoment,
      orderStatus: 'PLACED',
      orderrdBy: {
        username: userData.data[0].username,
        profile_pic: userData.data[0].profilePic || "",
        userId: userData.data[0]._id,
      },
      orderdFrom: {
        username: bussinessData.username,
        profile_pic: bussinessData.profilePic || '',
        userId: bussinessData._id,
      },
      shippingAddress: formData,
      transactionBtw: [userData.data[0]._id, bussinessData._id],
      fullfilledFromBusiness:false,
      message: [
        {
          from: userData.data[0]._id,
          to: bussinessData._id,
          type: 'PRODUCT',
          text: '',
          productDetail: {
            image: book.images[0],
            title: book.title,
            price: book.price,
          },
          sentOn: thismoment,
          isSeen:false
        },
      ],
    };

    try {
      const orderRef = ref(database, `orders/${createOrder.orderId}`);
      await set(orderRef, createOrder);
      
      if (rememberMe) {
        localStorage.setItem('shippingInformation', JSON.stringify(formData));
      }

      history(`/chat/${bussinessData._id}`, { state: { createOrder } });
    } catch (error) {
      console.error("Error submitting order to Firebase:", error);
    }
  };

  const toggleRememberMe = () => setRememberMe(!rememberMe);

  return (
    <div style={{ display: 'flex', maxWidth: '500px', width: '100%', margin: '0px auto' }}>
      <div>
        <Header />
      </div>
      <div className="app-container">
        {hasError ? (
          <div style={styles.errorContainer}>
            <h3>Something went wrong. Please try again.</h3>
            <button style={styles.retryButton} onClick={() => getBooks()}>Retry</button>
          </div>
        ) : !book ? (
          <Spinner />
        ) : (
          <div className="row" style={{ marginBottom: '60px' }}>
            <div className="col">
              <Item name={book.title} price={`Rs.${book.price}`} img={book.images[0]} bussinessData={bussinessData._id} 
              handleStore={()=>{history( `/store/${bussinessData.username}`)}}/>
            </div>
            {accessToken?.accessToken ? (
              <div className="col no-gutters">
                <Checkout formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} rememberMe={rememberMe} 
                toggleRememberMe={toggleRememberMe} />
              </div>
            ) : (
              <button className="checkout-btn" type="button" onClick={() => { setLoginUi(!showLoginUi) }}>
                LOGIN
              </button>
            )}
          </div>
        )}
        <BottomTab />
        {showLoginUi && (
          <LoginUiPurchase serach={null} eventId={"Hello world this is a new thought"} updateLoginUi={() => { setLoginUi(!showLoginUi) }} />
        )}
      </div>
    </div>
  );
};

const Item = (props) => (
  <div className="item-container">
    <div className="item-image">
      <img src={props.img} alt={props.name} />
      <div className="item-details" style={{ padding: 10 }}>
        <h3 className="item-name"> {props.name} </h3>
        <h2 className="item-price"> {props.price} </h2>
        <button className="more-from-store-btn" onClick={() => {props.handleStore()}}>
        More from this store
      </button>
      </div>
     
    </div>
  </div>
);

const Checkout = ({ formData, handleChange, handleSubmit, rememberMe, toggleRememberMe }) => (
  <div className="checkout">
    <div className="checkout-container">
      <h3 className="heading-3">Shipping Information</h3>
    
      <Input label="Full Name" type="text" name="full_name" value={formData.full_name} onChange={handleChange} placeholder={formData.full_name}/>
      <Input label="Email Address" type="email" name="email" value={formData.email} onChange={handleChange}  placeholder={formData.email}/>
      <Input label="Contact Number" type="tel" name="contact_number" value={formData.contact_number} onChange={handleChange}  placeholder={formData.contact_number}/>
      <Input label="Ship To Address" type="text" name="shipping_address" value={formData.shipping_address} onChange={handleChange}  
      placeholder={formData.shipping_address}/>
      <Input label="Pincode" type="number" name="Pincode" value={formData.Pincode} onChange={handleChange}  placeholder={formData.Pincode}/>

      <div className="input">
        <label>Payment Option</label>
        <div className="input-field" style={{ marginTop: 10 }}>
          <input type="text" name="payment_option" value="Cash on Delivery" readOnly />
        </div>
      </div>

      {!localStorage.getItem('shippingInformation') && (
        <div className="remember-me">
          <input type="checkbox" checked={rememberMe} onChange={toggleRememberMe} />
          <label>Remember Me</label>
        </div>
      )}

      <button className="checkout-btn" type="button" onClick={handleSubmit}>
        PLACE ORDER
      </button>
    </div>
  </div>
);

const Input = ({ label, type, name, value, onChange, placeholder }) => (
  <div className="input">
    <label>{label}</label>
    <div className="input-field" style={{ marginTop: '10px', marginBottom: 10 }}>
      <input type={type} name={name} value={value} onChange={onChange} />
    </div>
  </div>
);

const styles = {
  retryButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
    color: 'white',
    fontWeight: 'bolder',
    width: '100px',
    height: '50px',
    borderRadius: '10px',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export default BuyoutPage;
