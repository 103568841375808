// reducer.js


const initialState = {
  orders: [], // Array to hold all orders
};

const Orders = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ORDER':
      return {
        ...state,
        orders: [...state.orders, action.payload],
      };
      case 'CREATE_ORDER':
        return{
            ...state,
            orders: action.payload,
          
        };
    case 'UPDATE_ORDER':
      return {
        ...state,
        orders: state.orders.map(order => 
          order.orderId === action.payload.orderId 
            ? { ...order, ...action.payload.updatedData } 
            : order
        ),
      };
    
    default:
      return state;
  }
};

export default Orders;
