import { combineReducers } from "redux";
import { Auth } from "./Auth";
import { Event } from "./Event";
import { UserData } from "./UserData";
import { Join } from "./Join";
import { EventPost } from "./EventPost";
import { Forward } from "./Forward";
import { Comment } from "./Comment";
import { Mute } from "./Mute";
import { Store } from "./Store";
import Orders from "./Orders";
import BusinessChat from "./BusinessChat";
import { Notification } from "./Notification";
import { Ordercount } from "./Ordercount";





const Reducers = combineReducers({
  Auth,
  Event,
  UserData,
  Join,
  EventPost,
  Forward,
  Comment,
  Mute,
  Store,
  Orders,
  BusinessChat,
  Notification,
  Ordercount
  
})

export default Reducers;